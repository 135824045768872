import React, { useContext, useEffect, useRef } from 'react';
import { Vector as OlVectorLayer } from 'ol/layer';
import { Vector as OlVectorSource } from 'ol/source';
import OlGeoJSON from 'ol/format/GeoJSON';
import { Circle, Fill, Style, Icon } from 'ol/style';
import Feature, { FeatureLike } from 'ol/Feature';
import { Geometry  } from "ol/geom";

import { env } from '../Components/Env'; 
import MapContext from "../Map/MapContext";
import {useSettings} from '../Components/SettingsContext'; 

import { getAllPG } from '../Postgres/pg';

const name = 'Strassenschaden';
const label = 'strassenschaden';
const type = 'Point';

interface StrassenschadenLayerProps {
	setLoading: React.Dispatch<React.SetStateAction<number | null>>;
	removeFeature: Feature | null;
}

const StrassenschadenLayer: React.FC<StrassenschadenLayerProps> = (props) => {
	const setlog = false;
    setlog && console.log("StrassenschadenLayer:React.FC");

	const map = useContext(MapContext);
	const {settings} = useSettings();
	const vectorLayerRef = useRef<OlVectorLayer<Feature<Geometry>> | null>(null);
    const vectorSourceRef = useRef<OlVectorSource | null>(null);
	const vectorStyleRef = useRef<((feature: FeatureLike, resolution: number) => Style[]) | null>(null);


    useEffect(() => {
		setlog && console.log("StrassenschadenLayer:React.FC useEffect[]");

		const vectorSource = new OlVectorSource({
			format: new OlGeoJSON(),
			attributions: env.attributions		
		});
		
        const vectorLayer = new OlVectorLayer({
            source: vectorSource,
            style: (feature: FeatureLike, resolution: number) => vectorStyleRef.current ? vectorStyleRef.current(feature,resolution) : new Style(),
        });
		
		vectorLayer.set('name', name);
		vectorLayer.set('label', label);
		vectorLayer.set('type', type);
		vectorLayer.set('digi', false);
		vectorLayer.set('allow_digi', true);
		vectorLayer.set('select', true);
		vectorLayer.set('allow_select', true);		

		getAllPG(env.strassenschaden!.url,env.strassenschaden!.database,env.strassenschaden!.schema, env.strassenschaden!.tabelle, env.strassenschaden!.attribute, vectorSource,() => {props.setLoading((l) => l ? l - 1 : 1)});
        map?.addLayer(vectorLayer);

		vectorLayerRef.current = vectorLayer;
        vectorSourceRef.current = vectorSource;		

        return () => {
            map?.removeLayer(vectorLayer);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	useEffect(() => {
        setlog && console.log("NMobLayer:React.FC useEffect.[settings.strassenschadenplan] "+ settings.strassenschadenplan);

		vectorStyleRef.current = (feature: FeatureLike, resolution: number): Style[] =>{    
			//var typ = feature.get('typ');
			const properties = feature.getProperties();
			const select = properties['select'];
			const zkl = properties['zkl'];
			const behoben_am = properties['behoben_am'];

			var style: Style;
			var style2: Style;
			var color: string;

			const scale = 0.05/resolution;

			if ( behoben_am && settings.strassenschadenplan === 1){
				if ( select ){ 
					style2 = new Style({
						image: new Circle({
							radius: scale * 60,
							fill: new Fill({
								color: 'rgba(255,0,0,0.3)',
							}),
						})
					})
					return [style2];	
				}
				else {
					return [];
				}
			}

			switch (zkl) {       
				case 1:
					color = '#00ff00';
				break;            
				case 2:
					color = '#0000ff';
				break;
				case 3:
					color = '#00ffff';
				break;
				case 4:
					color = '#ff00ff';
				break;
				case 5:
					color = '#aaff00';
				break;
				case 6:
					color = '#ffa96a';
				break;															
				default:
					color = '#aaaaaa';
			}

			style = new Style({
				image: new Icon({
					src: 'strassenschaden.svg',
					//opacity: 1,
					color: color,
		    		scale: scale,
				}),
			});
			
			if ( select ){ 
				style2 = new Style({
					image: new Circle({
						radius: scale * 60,
						fill: new Fill({
							color: 'rgba(255,0,0,0.3)',
						}),
					})
				})
				return [style2,style];			
			}
			else {			
				return [style];
			}
		};	

		if ( vectorLayerRef.current && vectorStyleRef.current ){
        	vectorLayerRef.current.setStyle(vectorStyleRef.current);
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.strassenschadenplan]); 	
	
	useEffect(() => {
        setlog && console.log("StrassenschadenLayer:React.FC useEffect.[props.removefeature] "+ props.removeFeature);
		if ( props.removeFeature){
			if ( props.removeFeature.get('label') === label) { 
				if (vectorSourceRef.current) {
					vectorSourceRef.current.removeFeature(props.removeFeature);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
    } , [props.removeFeature]);

    return null;
};

export default StrassenschadenLayer;
