import OlVectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import OlFeature from 'ol/Feature';
import WKT from 'ol/format/WKT';

import { envProps, pgAttribut } from '../Components/EnvProps';
import { env , getAttribute } from '../Components/Env';

//import type { RcFile } from 'antd/es/upload/interface';

//import { GetTokenSilentlyOptions, GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
// das mit dem any ist nicht schön!!!
//getTokenSilently(options: GetTokenSilentlyOptions & {detailedResponse: true;}): Promise<GetTokenSilentlyVerboseResponse>

import {
    notification
} from 'antd';
import Feature, { FeatureLike } from 'ol/Feature';

const setlog = false;

/*
const getAllPG = async (server:string, database:string, schema:string, tabelle:string, attribute:string, vector:OlVectorSource) => {
    setlog && console.log('Postgres getAllPG');

    let headers = new Headers();   
    
    headers.append('Content-Type','application/json');
    headers.append('Access-Control-Allow-Origin', '*');		

    attribute = 'index,' + attribute;
    setlog && console.log('Postgres getAllPG', attribute);
    
    fetch( server + `/` + database + `/` + schema + `/getAllPG`, {
        headers: headers,
        method: 'POST',
        credentials: 'omit', // vorher include
        body: JSON.stringify({tabelle, attribute}),		  
    })
   .then(response => response.json())
   .then(data => {
        if ( data.success ){			   
            if ( data.data.length > 0){
                const f:FeatureLike[] = new GeoJSON().readFeatures(data.data[0].row_to_json);
                vector.addFeatures(f as Feature[]); // cast wegen ol@8.2.0 
                setlog && console.log("Geladen:", schema);
            }
            else{
                notification.open({
                    message: 'Info',
                    description: 'Die Tabelle ist leer!',
                    placement: 'topLeft',
                    type: 'info',
                });				   
            }
        }
        else{
            notification.open({
                message: 'Fehler',
                description: 'Beim Abruf der Tabelle ist ein Fehler aufgetreten!',
                placement: 'topLeft',
                type: 'error',
            });				   			   
            console.log(data);
        }
   })
  .catch(error => {
   });
};
*/

const getAllPG = async (server:string, database:string, schema:string, tabelle:string, attributeListe:Array<pgAttribut>, vector:OlVectorSource, readyFunc:() => any) => {
    setlog && console.log('Postgres getAllPG');

    let headers = new Headers();   
    
    headers.append('Content-Type','application/json');
    headers.append('Access-Control-Allow-Origin', '*');		

    let attribute:string = '';               
    attributeListe.map((a) => attribute ? attribute += ',' + a.k :  attribute = a.k);

    //setlog && console.log('Postgres getAllPG', attribute);

    fetch( server + `/` + database + `/` + schema + `/getAllPG`, {
        headers: headers,
        method: 'POST',
        credentials: 'omit', // vorher include
        body: JSON.stringify({tabelle, attribute}),		  
    })
   .then(response => response.json())
   .then(data => {
        if ( data.success ){			   
            if ( data.data.length > 0){
                const f:FeatureLike[] = new GeoJSON().readFeatures(data.data[0].row_to_json);
                vector.addFeatures(f as Feature[]); // cast wegen ol@8.2.0 
                setlog && console.log("Geladen:", schema);
                readyFunc();
            }
            else{
                notification.open({
                    message: 'Info',
                    description: 'Die Tabelle ist leer!',
                    placement: 'topLeft',
                    type: 'info',
                });				   
            }
        }
        else{
            notification.open({
                message: 'Fehler',
                description: 'Beim Abruf der Tabelle ist ein Fehler aufgetreten!',
                placement: 'topLeft',
                type: 'error',
            });				   			   
            console.log(data);
        }
   })
  .catch(error => {
   });
};

const savePG = async (feature:OlFeature, getAccessTokenSilently:() => any) => {
    setlog && console.log('Postgres savePG ', feature);

    const tabelle:string = feature?.get('label');
    
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres savePG',featureEnv);

                let headers = new Headers();

                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = tabelle;
                body['index'] = feature?.get('index');

                const attributeListe:Array<pgAttribut> = featureEnv?.attribute;
  
                attributeListe.map((a) => a.u && (body[a.k] = feature?.get(a.k)));


                setlog && console.log('Postgres savePG',body);
                           
                fetch( server + `/` + database + `/` + schema + `/updatePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres savePG OK',data);                               
                    }
                    else{
                        notification.open({
                            message: 'Beim Speichern des Objekts ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        setlog && console.log('Postgres savePG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in savePG');
    }
}

const geometriePG = async (feature:OlFeature, getAccessTokenSilently:() => any) => {
    setlog && console.log('Postgres geometriePG ', feature);

    const tabelle:string = feature?.get('label');
    
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres geometriePG',featureEnv);

                let headers = new Headers();

                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		

                let body: { [t: string]: string } = {};

                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = tabelle;
                body['index'] = feature?.get('index');

                var format = new WKT();
                var geometry = feature.getGeometry();
                var geometryString = format.writeGeometry(geometry!);

                body['geometrie']=geometryString;

                setlog && console.log('Postgres geometriePG',body);
                           
                fetch( server + `/` + database + `/` + schema + `/geometriePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres savePG OK',data);                               
                    }
                    else{
                        notification.open({
                            message: 'Beim Speichern des Objekts ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        setlog && console.log('Postgres geometriePG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in geometriePG');
    }
}

const deletePG = async (feature:OlFeature, getAccessTokenSilently:() => any, removeFeature:(feature: OlFeature, tabelle: string) => void) => {
    setlog && console.log('Postgres deletePG ', feature);

    const tabelle:string = feature?.get('label');
    
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres deletePG',featureEnv);

                let headers = new Headers();
                            
                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = tabelle;
                body['index'] = feature?.get('index');

                           
                fetch( server + `/` + database + `/` + schema + `/deletePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres deletePG OK');
                        removeFeature(feature!,tabelle);
                    }
                    else{
                        notification.open({
                            message: 'Beim Löschen des Objekts ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        setlog && console.log('Postgres deletePG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in deletePG');
    }
}

const newPG = async (feature:OlFeature, getAccessTokenSilently:() => any) => {
    setlog && console.log('Postgres newPG',feature);

    const tabelle:string = feature?.get('label');
    
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                //setlog && console.log('Postgres newPG',featureEnv);

                let headers = new Headers();
                            
                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = tabelle;

                var format = new WKT();
                var geometry = feature.getGeometry();
                var geometryString = format.writeGeometry(geometry!);

                body['geometrie']=geometryString;
                
                //setlog && console.log('Postgres newPG ',geometry);
                //setlog && console.log('Postgres newPG ',geometryString);

                fetch( server + `/` + database + `/` + schema + `/insertPG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres newPG OK',data);
                        feature.set('index', data.data.index);                               
                    }
                    else{
                        notification.open({
                            message: 'Beim Erstellen des Objekts ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        setlog && console.log('Postgres newPG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in newPG');
    }
};

const uploadPublicDokumentePG = async (feature:OlFeature, getAccessTokenSilently:() => any,file:any,insertDokument:(index:number, datei:string) => any) => {
    setlog && console.log('Postgres uploadPublicDokumentePG',feature);

    const tabelle:string = feature?.get('label');
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                if ( featureEnv.dokumente ){
                
                    /*
                    if (file instanceof Blob) {
                        console.log('file ist ein Blob:', file);
                    } else {
                        console.log('file ist kein Blob:', file);
                    }
                    */

                    setlog && console.log('Postgres uploadPublicDokumentePG',file as Blob);

                    let headers = new Headers();
                                
                    const token = await getAccessTokenSilently();
                    headers.append('authorization', 'Bearer ' + token);	
                                                    
                    const server = featureEnv.url;
                    const database = featureEnv.database;
                    const schema = featureEnv.schema;
                    
                    var formData = new FormData();


                    const heute = new Date().toISOString().slice(0, 19).replace(/-/g, '').replace(/:/g, '').replace(/T/g, '_');

                    const dateiname = feature?.get('index').toString() + '_' + heute + '.jpg';
                    setlog && console.log('Postgres uploadPublicDokumentePG',dateiname);

                    let newfile = new File([file], dateiname, { type: file.type });
                    
                    formData.append('type', 'public');
                    formData.append('tabelle', tabelle);
                    formData.append('index', feature?.get('index'));
                    formData.append('filename', newfile.name);
                    formData.append('file', newfile as Blob);
                    


                    fetch( server + `/` + database + `/` + schema + `/uploadPG`, {
                        headers: headers,
                        method: 'POST',
                        body: formData,		  
                    })
                    .then(response => response.json())
                    .then(data => {
                        if ( data.success ){
                            setlog && console.log('Postgres uploadPublicDokumentePG OK',data.data.index);
                            notification.open({
                                message: 'Der Eintrag wurde in die Tabelle eingefügt!',
                                description: data.info,
                                placement: 'topLeft',
                                type: 'info',
                            });				   			                           				                                                        
                            insertDokument(data.data.index,file.name);                               
                        }
                        else{
                            notification.open({
                                message: 'Beim Einfügen in die Tabelle ist ein Fehler aufgetreten!',
                                description: data.error,
                                placement: 'topLeft',
                                type: 'error',
                            });				   			     
                            setlog && console.log('Postgres uploadPublicDokumentePG Fehler',data);
                        }
                    })
                    .catch(error => {
                    });
                }
                else{
                    setlog && console.log('Postgres Fehler in uploadPublicDokumentePG - keine Dokumente');
                }
            }
        }
    }       
    else {
        setlog && console.log('Postgres Fehler in uploadPublicDokumentePG');
    }
};

const downloadDokumentePG = async (feature:OlFeature,updateDokumente:(data:any) => any) => {
    setlog && console.log('Postgres downloadDokumentePG');

    const tabelle:string = feature?.get('label');

    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres downloadDokumentePG',featureEnv);

                let headers = new Headers();
                            
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = tabelle;
                body['index'] = feature?.get('index');
                body['attribute'] = 'index,datei';
    
                fetch( server + `/` + database + `/` + schema + `/getDokumentePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){			   
                        if ( data.data.length > 0){
                            updateDokumente( data.data );
                            //console.log("Geladen:", data.data);
                        }
                        else{
                            /*
                            notification.open({
                                message: 'Info',
                                description: 'Die Tabelle ist leer!',
                                placement: 'topLeft',
                                type: 'info',
                            });
                            */
                        }
                    }
                    else{
                        notification.open({
                            message: 'Fehler',
                            description: 'Beim Abruf der Tabelle ist ein Fehler aufgetreten!',
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        console.log(data);
                    }
                })
                .catch(error => {
                });
            }
        }
    }
};

const deleteDokumentePG = async (feature:OlFeature, getAccessTokenSilently:() => any, index:number) => {
    setlog && console.log('Postgres deleteDokumentePG ', feature);

    const tabelle:string = feature?.get('label');
    
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres deleteDokumentePG',featureEnv);

                let headers = new Headers();
                            
                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = tabelle+'_dokumente';
                body['index'] = String(index);

                           
                fetch( server + `/` + database + `/` + schema + `/deletePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        notification.open({
                            message: 'Der Eintrag wurde in der Tabelle gelöscht!',
                            description: data.info,
                            placement: 'topLeft',
                            type: 'info',
                        });				   			                           				   			                           
                        setlog && console.log('Postgres deleteDokumentePG OK');
                        
                    }
                    else{
                        notification.open({
                            message: 'Beim Löschen ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			                           				   			   
                        setlog && console.log('Postgres deletePG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in deleteDokumentePG');
    }
}

const downloadAllAttributePG = async (from:string, tabelle:string, attribute:string,getAccessTokenSilently:() => any,updateFunc:(data:any) => any) => {
    setlog && console.log('Postgres downloadAllAttributePG');

    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                //setlog && console.log('Postgres downloadAttributePG',featureEnv);

                let headers = new Headers();
                            
                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');
                headers.append('Access-Control-Allow-Origin', '*');
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = from;
                body['attribute'] = attribute;
    
                //setlog && console.log('Postgres downloadAttributePG', body);

                fetch( server + `/` + database + `/` + schema + `/getAllAttributePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){			   
                        if ( data.data.length > 0){
                            updateFunc( data.data );
                            //console.log("Geladen:", data.data);
                        }
                        else{
                            updateFunc( [] );
                        }
                    }
                    else{
                        notification.open({
                            message: 'Beim Abruf der Tabelle ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			                           				   			   
                        console.log(data);
                    }
                })
                .catch(error => {
                });
            }
        }
    }
};

const downloadAttributePG = async (from:string, tabelle:string, fkey:string, attribute:string, index:number,getAccessTokenSilently:() => any,updateFunc:(data:any) => any) => {
    setlog && console.log('Postgres downloadAttributePG');

    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                //setlog && console.log('Postgres downloadAttributePG',featureEnv);

                let headers = new Headers();
                            
                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');	
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = from;
                body['fkey'] = fkey;
                body['index'] = index.toString();
                body['attribute'] = attribute;
    
                //setlog && console.log('Postgres downloadAttributePG', body);

                fetch( server + `/` + database + `/` + schema + `/getAttributePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){			   
                        if ( data.data.length > 0){
                            updateFunc( data.data );
                            //console.log("Geladen:", data.data);
                        }
                        else{
                            updateFunc( [] );
                        }
                    }
                    else{
                        notification.open({
                            message: 'Fehler',
                            description: 'Beim Abruf der Tabelle ist ein Fehler aufgetreten!',
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        console.log(data);
                    }
                })
                .catch(error => {
                });
            }
        }
    }
};

const getbyFkeyPG = async (from:string, tabelle:string, fkey:string, attributeListe:Array<pgAttribut>, index:number, order:string, getAccessTokenSilently:() => any,updateFunc:(data:any) => any, showInfo:boolean=true) => {
    setlog && console.log('Postgres getbyFkeyPG');

    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                //setlog && console.log('Postgres getbyFkeyPG',featureEnv);

                let headers = new Headers();
                            
                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');	
                
                let body: { [t: string]: string|number } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = from;
                body['fkey'] = fkey;
                body['index'] = index;
                body['order'] = order;

                let ret:string = '';               
                attributeListe.map((a) => ret ? ret += ',' + a.k :  ret = a.k);
                body['attribute'] = ret;

                fetch( server + `/` + database + `/` + schema + `/getAttributePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){			   
                        if ( data.data.length > 0){
                            //setlog && console.log('Postgres getbyFkeyPG ',data.data);
                            updateFunc( data.data );
                        }
                        else{ 
                            showInfo && notification.open({
                                message: 'Info',
                                description: 'Es sind keine Daten vorhanden!',
                                placement: 'topLeft',
                                type: 'info',
                            });	                            
                            updateFunc( [] );
                        }
                    }
                    else{
                        notification.open({
                            message: 'Beim Abruf der Tabelle ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			                           				   			   
                        setlog && console.log('Postgres getbyFkeyPG',data);
                    }
                })
                .catch(error => {
                });
            }
        }
    }
};

const newAttributePG = async (from:string, tabelle:string, indexname:string, index:number, attributeListe:string,attribute:{[key: string]: any},getAccessTokenSilently:() => any,newFunc:(index:number) => any) => {
    setlog && console.log('Postgres newAttributePG ');

    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres newAttributePG',featureEnv);

                let headers = new Headers();

                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string | number } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = from;
                body[indexname] = index;

                const aL = getAttribute(attributeListe);
  
                aL.forEach((a) => {
                    body[a] = attribute[a];
                });

                setlog && console.log('Postgres newAttributePG',body);
                           
                fetch( server + `/` + database + `/` + schema + `/addAttributePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres newAttributePG OK',data.data.index);                               
                        newFunc(data.data.index);
                    }
                    else{
                        notification.open({
                            message: 'Beim Erstellen des Objekts ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        setlog && console.log('Postgres newAttributePG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in newAttributePG');
    }     
}  

const newwithFKeyPG = async (from:string, tabelle:string, indexname:string, index:number, attributeListe:Array<pgAttribut>, attribute:{[key: string]: any}, getAccessTokenSilently:() => any, newFunc:(index:number) => any) => {
    setlog && console.log('Postgres newwithFKeyPG ');

    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres newwithFKeyPG',featureEnv);

                let headers = new Headers();

                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string | number | boolean } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = from;
                body[indexname] = index;
  
                attributeListe.map((a) => a.u && (body[a.k] = attribute[a.k]));
                           
                setlog && console.log('Postgres newwithFKeyPG',body);
                           
                fetch( server + `/` + database + `/` + schema + `/addAttributePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres newwithFKeyPG OK',data.data.index);                               
                        newFunc(data.data.index);
                    }
                    else{
                        notification.open({
                            message: 'Beim Erstellen des Objekts ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        //setlog && console.log('Postgres newwithFKeyPG Fehler',data);
                        setlog && console.log('Postgres newwithFKeyPG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in newwithFKeyPG');
    }     
}  

const newwith2FKeyPG = async (from:string, tabelle:string, indexname:string, index:number, indexname2:string, index2:number, attributeListe:Array<pgAttribut>,attribute:{[key: string]: any},getAccessTokenSilently:() => any,newFunc:(index:number) => any) => {
    setlog && console.log('Postgres newbye2FKeyPG ');

    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres newbye2FKeyPG',featureEnv);

                let headers = new Headers();

                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string | number | boolean } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = from;
                body[indexname] = index;
                body[indexname2] = index2;
  
                attributeListe.map((a) => a.u && (body[a.k] = attribute[a.k]));
                           
                setlog && console.log('Postgres newbye2FKeyPG',body);
                           
                fetch( server + `/` + database + `/` + schema + `/addAttributePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres newbye2FKeyPG OK',data.data.index);                               
                        newFunc(data.data.index);
                    }
                    else{
                        notification.open({
                            message: 'Beim Erstellen des Objekts ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        setlog && console.log('Postgres newbye2FKeyPG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in newbyeFKeyPG');
    }     
}  

const saveAttributePG = async (from:string, tabelle:string, index:number, attributeListe:string,attribute:{[key: string]: any},getAccessTokenSilently:() => any) => {
    setlog && console.log('Postgres saveAttributePG ');
    
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres saveAttributePG',featureEnv);

                let headers = new Headers();

                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = from;
                body['index'] = index.toString();

                const aL = getAttribute(attributeListe);
  
                aL.forEach((a) => {
                    body[a] = attribute[a];
                });
                           
                fetch( server + `/` + database + `/` + schema + `/updateAttributePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres saveAttributePG OK',data);                               
                    }
                    else{
                        notification.open({
                            message: 'Beim Speichern des Objekts ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        setlog && console.log('Postgres saveAttributePG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in saveAttributePG');
    }    
};

const updatebyPkeyPG = async (from:string, tabelle:string, index:number, attributeListe:Array<pgAttribut>,attribute:{[key: string]: any},getAccessTokenSilently:() => any,updateFunc:(index:number) => any) => {
    setlog && console.log('Postgres updatebyPkeyPG ');
    
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres updatebyPkeyPG',featureEnv);

                let headers = new Headers();

                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string | number | boolean } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = from;
                body['index'] = index;
                //body['user'] = user;
                                
                attributeListe.map((a) => a.u && attribute[a.k] !== undefined && (body[a.k] = attribute[a.k]));

                setlog && console.log('Postgres updatebyPkeyPG',body);
                           
                fetch( server + `/` + database + `/` + schema + `/updateAttributePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres updatebyPkeyPG OK',data); 
                        updateFunc(index);                              
                    }
                    else{
                        notification.open({
                            message: 'Beim Speichern des Objekts ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        setlog && console.log('Postgres updatebyPkeyPG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in updatebyPkeyPG');
    }    
};

const deleteAttributePG = async (from:string, tabelle:string, index:number,getAccessTokenSilently:() => any, deleteFunc:() => any) => {
    setlog && console.log('Postgres deleteAttributePG');
    
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres deleteAttributePG',featureEnv);

                let headers = new Headers();
                            
                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = from;
                body['index'] = index.toString();
                         
                fetch( server + `/` + database + `/` + schema + `/deletePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres deleteAttributePG OK');
                        deleteFunc();
                        
                    }
                    else{
                        notification.open({
                            message: 'Beim Löschen des Objekts ist ein Fehler aufgetreten!',
                            description: data.error,
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        setlog && console.log('Postgres deleteAttributePG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in deleteAttributePG');
    }
}

const getWFSwithFilter = async (server: string, schema: string, layer: string, find: string, findFeatures:(featuresFound:Feature[]) => any) => {
    //fetch( `/geoserver/maintal/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=maintal:flurstueck&maxFeatures=50&outputFormat=application/json&cql_filter=zaehler=23 and nenner=0`)

    let url:string;
    url = `${server}?service=WFS&version=1.0.0&request=GetFeature&typeName=${schema}&maxFeatures=50&outputFormat=application/json&cql_filter=${find}`;
    setlog && console.log('Postgres getWFSwithFilter', url);

    fetch( url)
    .then(response => response.json())
    .then(data => {     
        //console.log(data);    			   
        const f:FeatureLike[] = new GeoJSON().readFeatures(data);
        if ( f.length > 0){
            //console.log(f);

            f.forEach( (fl) => {
                const feature = fl as Feature;
                feature.set('label', layer);    
            });

            findFeatures(f as Feature[]);
        }
        else{
            notification.open({
                message: 'Info',
                description: 'Es konnte kein Objekt gefunden werden!',
                placement: 'topLeft',
                type: 'info',
            });				   			   
            console.log(data);
        }        
    })
   .catch(error => {
     console.log(error);
    });	
};

const uploadDokumentAndMail = async (feature:OlFeature, getAccessTokenSilently:() => any, name:string, file:any, mailto:string, href:string) => {
    setlog && console.log('Postgres uploadDokumentAndMail',feature);

    const tabelle:string = feature?.get('label');
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                if ( featureEnv.dokumente ){
                
                    /*
                    if (file instanceof Blob) {
                        console.log('file ist ein Blob:', file);
                    } else {
                        console.log('file ist kein Blob:', file);
                    }
                    */

                    setlog && console.log('Postgres uploadDokumentAndMail',file);

                    let headers = new Headers();
                                
                    const token = await getAccessTokenSilently();
                    headers.append('authorization', 'Bearer ' + token);	
                                                    
                    const server = featureEnv.url;
                    const database = featureEnv.database;
                    const schema = featureEnv.schema;
                    
                    var formData = new FormData();
                    
                    formData.append('tabelle', tabelle);
                    formData.append('index', feature?.get('index'));                    
                    formData.append('mailto', mailto);
                    formData.append('href', href);
                    formData.append('filename', name);
                    formData.append('file', file as Blob, name);


                    fetch( server + `/` + database + `/` + schema + `/uploadMail`, {
                        headers: headers,
                        method: 'POST',
                        body: formData,		  
                    })
                    .then(response => response.json())
                    .then(data => {
                        if ( data.success ){
                            setlog && console.log('Postgres uploadDokumentAndMail OK',data.data.index);                                                           
                        }
                        else{
                            alert("Beim Hochladen der Datei ist ein Fehler aufgetreten");
                            setlog && console.log('Postgres uploadDokumentAndMail Fehler',data);
                        }
                    })
                    .catch(error => {
                    });
                }
                else{
                    setlog && console.log('Postgres Fehler in uploadDokumentAndMail - keine Dokumente');
                }
            }
        }
    }       
    else {
        setlog && console.log('Postgres Fehler in uploadDokumentAndMail');
    }
};

const downloadDokument = async (from:string, tabelle:string, filename: string, getAccessTokenSilently:() => any, newFunc:() => any) => {
    setlog && console.log('Postgres downloadDokument', from);

    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){

                let headers = new Headers();
                            
                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);
                headers.append('Content-Type','application/json');	
                                                
                let body: { [t: string]: string | number | boolean } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = from;

                try {
                    const response = await fetch( server + `/` + database + `/` + schema + `/download/` + filename, {
                        headers: headers,
                        method: 'POST',
                        body: JSON.stringify(body),			  
                    });

                    if (!response.ok) {
                        alert('File download failed');
                        return;
                    }
                  
                    const blob = await response.blob();
                    setlog && console.log('Postgres downloadDokument', blob);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);                                        
                }
                catch (error) {
                    console.error('Error downloading file:', error);
                    alert('An error occurred while downloading the file');
                }                
            }
        }
    }       
    else {
        setlog && console.log('Postgres Fehler in downloadDokument');
    }
};

const uploadAuthDokumentePG = async (from:string, tabelle:string, index:number, getAccessTokenSilently:() => any,file:any,insertDokument:(index:number, datei:string) => any) => {
    setlog && console.log('Postgres uploadAuthDokumentePG');

    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                if ( featureEnv.dokumente ){
                
                    /*
                    if (file instanceof Blob) {
                        console.log('file ist ein Blob:', file);
                    } else {
                        console.log('file ist kein Blob:', file);
                    }
                    */

                    setlog && console.log('Postgres uploadAuthDokumentePG',file);

                    let headers = new Headers();
                                
                    const token = await getAccessTokenSilently();
                    headers.append('authorization', 'Bearer ' + token);	
                                                    
                    const server = featureEnv.url;
                    const database = featureEnv.database;
                    const schema = featureEnv.schema;
                    
                    var formData = new FormData();
                    
                    formData.append('type', 'auth');
                    formData.append('tabelle', from);
                    formData.append('indexname', 'baumid' );
                    formData.append('index', index.toString() );
                    formData.append('filename', file.name);
                    formData.append('file', file);

                    fetch( server + `/` + database + `/` + schema + `/uploadPG`, {
                        headers: headers,
                        method: 'POST',
                        body: formData,		  
                    })
                    .then(response => response.json())
                    .then(data => {
                        if ( data.success ){
                            setlog && console.log('Postgres uploadAuthDokumentePG OK',data.data.index);
                            insertDokument(data.data.index,file.name);                               
                        }
                        else{
                            notification.open({
                                message: 'Fehler',
                                description: 'Beim Hinzufügen zur Tabelle ist ein Fehler aufgetreten!',
                                placement: 'topLeft',
                                type: 'error',
                            });				   			   
                            setlog && console.log('Postgres uploadAuthDokumentePG Fehler',data);
                        }
                    })
                    .catch(error => {
                        setlog && console.log('Postgres uploadAuthDokumentePG Fehler',error);
                    });
                }
                else{
                    setlog && console.log('Postgres Fehler in uploadAuthDokumentePG - keine Dokumente');
                }
            }
        }
    }       
    else {
        setlog && console.log('Postgres Fehler in uploadAuthDokumentePG');
    }
};


export {getWFSwithFilter,getAllPG,savePG,geometriePG,deletePG,newPG,uploadDokumentAndMail,uploadPublicDokumentePG,uploadAuthDokumentePG,downloadDokumentePG,deleteDokumentePG,downloadAttributePG,getbyFkeyPG,updatebyPkeyPG,saveAttributePG,newwithFKeyPG,newwith2FKeyPG,newAttributePG,deleteAttributePG,downloadAllAttributePG,downloadDokument};