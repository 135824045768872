import React, {useEffect, useState, ChangeEvent } from "react";
import 'antd/dist/reset.css';

import {
    //App,
    Button,
    Checkbox,
    Col,
	DatePicker,
    Form,
    Input,
	InputNumber,
//    message,
    Modal,
    Popconfirm,
    Row,
	Select,
//    Space,
//	Table, 
//	Tag,
	Drawer 
} from 'antd';

//import type { SelectProps } from 'antd';
import locale from 'antd/es/date-picker/locale/de_DE';
import 'dayjs/locale/de';
import dayjs, { type Dayjs } from 'dayjs';
//import 'moment/locale/de';

//import type { ColumnsType } from 'antd/es/table';

import {
	DeleteOutlined, 
	//PlayCircleOutlined,
    QuestionCircleOutlined,
	SaveOutlined
} from '@ant-design/icons';

import { array2Schema, MapSchema} from '../EnvProps';
import { env } from '../Env'; 

//import MapContext from "../Map/MapContext";
//import {useSettings} from '../Components/SettingsContext';
import Feature from 'ol/Feature';
//import { Geometry } from "ol/geom";
  
import { useAuth0 } from '@auth0/auth0-react';

import { updatebyPkeyPG, newwith2FKeyPG, deleteAttributePG, downloadAllAttributePG} from '../../Postgres/pg';
//import { Geometry } from "ol/geom";


const schadenSchemaArray = env.baum ? env.baum.schadenSchema : [];
const schadenSchema = array2Schema(schadenSchemaArray);
type SchadenSchemaTyp = typeof schadenSchema;
type Schaden = MapSchema<SchadenSchemaTyp>;
const isKeySchaden = (k: string): k is keyof SchadenSchemaTyp => k in schadenSchema;


/*
const schadenSchemaArray = Object.entries(schadenSchema).map(([key, value]) => ({
	 attribut: key, typ: value.t, sichtbar: value.s , updatebar: value.u,
}));*/


interface baumSchadenDrawerProps {
    closeSchadenDrawer: () => void;
	setSchaeden: React.Dispatch<React.SetStateAction<Array<Schaden>>>;
	feature: Feature | null;
    schaeden: Array<Schaden>;
	select: number;
	updateKontrolle: boolean;
}  

const BaumSchadenDrawer:React.FC<baumSchadenDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("baumSchadenDrawer:React.FC");

    //const {settings} = useSettings();
        
    const {
        isAuthenticated,
        getAccessTokenSilently
    } = useAuth0();
	const [modal, contextHolder] = Modal.useModal();

	const schadenSchemaArray = env.baum!.schadenSchema;
	const schadenSchema = array2Schema(schadenSchemaArray);
	type SchadenSchemaTyp = typeof schadenSchema;
	type Schaden = MapSchema<SchadenSchemaTyp>;	

	const [changed, setChanged] = useState(false);
	const [close, setClose] = useState(false);
	const [count, setCount] = useState({index: -1, pos: -1});
	const [attribute, setAttribute] = useState<{[key: string]: any}>({});
	const [behoben, setBehoben] = useState<Dayjs | null>(null);

	const [schaeden, setSchaeden] = useState<{ lage:string, nummer:number, beschreibung:string, massnahme:string }[]>([]);

    useEffect(() => {
		setlog && console.log("baumSchadenDrawer:React.FC useEffect.[props.schaeden]");

		if ( props.select !== -1){
			if (props.schaeden) {
				if (props.schaeden.length > 0) {
					setlog && console.log("baumSchadenDrawer:React.FC useEffect.[props.schaeden,props.select]", props.schaeden.length);

		
					const s:Schaden = props.schaeden[props.select];
					setlog && console.log("baumSchadenDrawer:React.FC useEffect.[props.schaeden,props.select]", s);

					//const index: keyof typeof schadenSchema = 'index';
					//const i: Schaden[keyof Schaden] = z[index];

					setCount(c => ({index: s['index'] as number, pos: props.select}));
				}
			}
		}	

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.schaeden,props.select]);
	

	useEffect(() => {
        if (props.feature) {
            if (props.feature.get('label') !== 'baum') {
                setlog && console.log("baumSchadenDrawer:React.FC useEffect.[props.feature]", props.feature);
				closeSchadenDrawer();
            }
        }
		else {
			closeSchadenDrawer();
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.feature]);
	
	useEffect(() => {
		setlog && console.log("baumSchadenDrawer:React.FC useEffect.[count]");
		count.pos>=0  && updateSchaden(count.pos);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

	const updateSchaden = (pos:number) =>{
        setlog && console.log("baumSchadenDrawer:React.FC updateSchaden ", pos);
		if (props.schaeden) {
            if (props.schaeden.length > 0) {
				
				const s:Schaden = props.schaeden[pos];

				s['index'] ===-1 ? setChanged(true): setChanged(false);
			
				schadenSchemaArray.forEach((a) => {
					//setlog && console.log("baumZustandDrawer:React.FC updateSchaden", a.k, z[a.k]);
					if ( isKeySchaden(a.k) ){
						const value: keyof SchadenSchemaTyp = a.k;						
						//setlog && console.log("baumZustandDrawer:React.FC updateSchaden", a.k);
						setAttribute( prevAttribute => { return {...prevAttribute, [value]:s[value]}});
					}
				});

				if ( s.behoben){
					setlog && console.log("baumSchadenDrawer:React.FC updateSchaden", s.behoben);
					const b = s.behoben;
					if ( typeof b === 'string' ){
						//setBehoben(dayjs(b.slice(6,8)+'.'+b.slice(4,6)+'.'+b.slice(0,4),'DD.MM.YYYY'));
						setBehoben(dayjs(b,'YYYYMMDD'));
					}
				}
				else{
					setBehoben(null);
				}				
            }
        }
    };

	const newAttributeIndex = (index:number) =>{
        setlog && console.log("baumSchadenDrawer:React.FC newAttributeIndex", index);
		setCount(c => ({index: index, pos: 0}));
		props.setSchaeden(prevschaeden => {
			return prevschaeden.map(schaden => {
				if (schaden.index === -1) {
					let updatedAttributes: Partial<any> = {};

					updatedAttributes = Object.assign(updatedAttributes, {'index':index})
					schadenSchemaArray.forEach((a) => {
						if ( a.u && isKeySchaden(a.k)  ){						
							updatedAttributes = Object.assign(updatedAttributes, {[a.k]:attribute[a.k]})
						}
					});
					return { ...schaden, ...updatedAttributes};
				}
				return schaden;
			});
		});
		setClose(true);
    };

	useEffect(() => {
		setlog && console.log("baumSchadenDrawer:React.FC useEffect.[close]");
		// DAS IST EINE NOTLÖSUNG !!!!
		close && closeSchadenDrawerReally();
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close]);


	const updateAttributeIndex = (index:number) =>{
        setlog && console.log("baumSchadenDrawer:React.FC updateAttributeIndex", index);
		
		props.setSchaeden(prevschaeden => {
			return prevschaeden.map(schaden => {
			  if (schaden.index === index) {
				let updatedAttributes: Partial<any> = {};

				schadenSchemaArray.forEach((a) => {
					if ( a.u && isKeySchaden(a.k)  ){						
						updatedAttributes = Object.assign(updatedAttributes, {[a.k]:attribute[a.k]})
					}
				});
				return { ...schaden, ...updatedAttributes};
			  }
			  return schaden;
			});
		});
		setClose(true);
    };

	const deleteAttributeIndex = () =>{
        setlog && console.log("baumSchadenDrawer:React.FC deleteAttributeIndex");
		if (props.schaeden) {
			props.setSchaeden(props.schaeden.filter(item => item.index !== count.index));
			closeSchadenDrawerReally();
		}
    };

    const dboSave = () =>{
        setlog && console.log("baumSchadenDrawer:React.FC dboSave ", count.index);
		
		//schadenSchemaArray.forEach((a) => {
			//setlog && console.log("baumSchadenDrawer:React.FC dboSave ", a.k ,attribute[a.k]);
		//});
		
		if ( count.index===-1 ){
			newwith2FKeyPG('baumschaden', 'baum', 'baumid', props.feature!.get('index') as number, 'zustandid', attribute.zustandid as number, schadenSchemaArray, attribute, getAccessTokenSilently, newAttributeIndex);
		}
		else {
			updatebyPkeyPG('baumschaden','baum', count.index, schadenSchemaArray,attribute,getAccessTokenSilently,updateAttributeIndex);
		}
		
		setlog && setlog && console.log("baumSchadenDrawer:React.FC dboSave dringlichkeit", attribute['dringlichkeit'] , props.feature!.get('dringlichkeit'));

		if ( attribute['dringlichkeit'] !== undefined){
			if ( props.updateKontrolle ){
				let dringlichkeit:number | null = null;

				if ( behoben === null ){
					dringlichkeit = attribute['dringlichkeit'];
				}
				console.log("baumSchadenDrawer:React.FC dboSave dringlichkeit", dringlichkeit, attribute['behoben']);

				props.schaeden.forEach((s) => {
					//setlog && console.log("baumSchadenDrawer:React.FC dboSave dringlichkeit", dringlichkeit, s['dringlichkeit'], s['behoben']);
					if (s['index'] !== count.index) {
						if ( s['dringlichkeit'] !== null && s['behoben'] === ''){
							if ( dringlichkeit === null ) {
								dringlichkeit = s['dringlichkeit'] as number;
							}
							else if ( s['dringlichkeit'] < dringlichkeit ){
								dringlichkeit = s['dringlichkeit'] as number;
							}
						}	
					}			
				});
				//setlog && console.log("baumSchadenDrawer:React.FC dboSave dringlichkeit", dringlichkeit);
				const baumSchemaArray = env.baum!.attribute;
				props.feature?.set('dringlichkeit',dringlichkeit);
				updatebyPkeyPG('baum','baum', props.feature?.get('index'), baumSchemaArray,{'dringlichkeit': dringlichkeit},getAccessTokenSilently,() => {});
			}			
		}
    };

    const closeSchadenDrawer = () =>{
        setlog && console.log("baumSchadenDrawer:React.FC closeSchadenDrawer");
		changed ? 
			modal.confirm({
				title: 'Die veränderten Daten wurden noch nicht gespeichert!',
				content: 'Möchten Sie den Dialog wirklich schließen?',
				okText: "...go!",
				okType: "danger",
				cancelText: "Nein",                
				onOk: () => closeSchadenDrawerReally(),
				onCancel: () => {},
			}) : closeSchadenDrawerReally();
		};

	const closeSchadenDrawerReally = () =>{
		setlog && console.log("baumSchadenDrawer:React.FC dboDeleteReally");
		setChanged(false);
		setClose(false);
		props.closeSchadenDrawer();
	};
	
    const dboDeleteReally = () =>{
        setlog && console.log("baumSchadenDrawer:React.FC dboDeleteReally");
		if ( count.index===-1 ){
			closeSchadenDrawerReally();
		}
		else {
			deleteAttributePG('baumschaden','baum', count.index, getAccessTokenSilently, deleteAttributeIndex);
		}
    };

	const changeString = (key:string, value:string) =>{
        setlog && console.log("baumSchadenDrawer:React.FC changeString", value);
		setChanged(true);
        setAttribute( currentState => { return {...currentState, [key]:value}});
    };

	const changeNumber = (key:string, value:number| null) =>{
        setlog && console.log("baumSchadenDrawer:React.FC changeString", value);
		setChanged(true);
        setAttribute( currentState => { return {...currentState, [key]:value}});
    };	
    
    const changeBoolean = (key:string, value:boolean) =>{
        //setlog && console.log("baumSchadenDrawer:React.FC changeString", value);
		setChanged(true);
        setAttribute( currentState => { return {...currentState, [key]:value}});
    };	

	const changeBehoben = (dateString: string)  =>{
        setlog && console.log("baumSchadenDrawer:React.FC changeBehoben", dateString);
		setChanged(true);

		if ( dateString ){
			const newDate = dateString.slice(6,10)+dateString.slice(3,5)+dateString.slice(0,2);		
			setAttribute( currentState => { return {...currentState, behoben:newDate}});		
			setBehoben(dayjs(newDate,'YYYYMMDD'));
		}
		else{
			setAttribute( currentState => { return {...currentState, behoben:''}});
			setBehoben(null);
		}
    };

	const changeSchaeden = (value: string, option:any) => {
		setlog && console.log("baumDrawer:React.FC changeSchaeden", option);  
        setChanged(true);
		setAttribute( currentState => { return {...currentState, 'schaden':value}});
		if ( option.massnahme ){
			setAttribute( currentState => { return {...currentState, 'massnahme':option.massnahme}});
		}
	};		
	
    const updateSchaeden = (data:any) =>{
        setlog && console.log("baumSchadenDrawer:React.FC updateSchaeden");
        
        const o: { lage:string, nummer:number, beschreibung:string, massnahme:string }[] = [];
        if (data.length > 0) {
            const p:number = data.length-1;
            for (let i = 0; i <= p; i++) {
                o.push({
                    lage: data[i].lage,
                    nummer: data[i].nummer,
					beschreibung: data[i].beschreibung,
					massnahme: data[i].massnahme,
                  });

            };				
        }
        setSchaeden(o);         
    };

    const getSchaeden = () =>{
        setlog && console.log("baumSchadenDrawer:React.FC getSchaeden");
        isAuthenticated && schaeden.length === 0 && downloadAllAttributePG('schaden','baum','lage,nummer,beschreibung,massnahme',getAccessTokenSilently,updateSchaeden); 
    };

	return (
		<div>{contextHolder} 
			<Drawer
				title={count.index===-1 ? "Baumschaden - NEU!" : "Baumschaden " + count.index}
				className="ggw-baum-schaden"
				placement="right"
				open={props.select!==-1}
				onClose={closeSchadenDrawer}
				mask={false}
				footer={
					isAuthenticated && (
						<Row gutter={[8,8]} >
							<Col span={12}>
								<Button icon={<SaveOutlined />} onClick={dboSave} disabled={!changed} type="primary" block>Speichern</Button>		
							</Col>
							<Col span={12}>
								<Popconfirm 
									onConfirm={dboDeleteReally}
									title={count.index===-1 ? "Der Schaden ist noch nicht gespeichert!" : "Wollen Sie den Schaden wirklich löschen?"} 
									icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
									okText="...go!"
									okType="danger"
									cancelText={count.index===-1 ? "Zurück" : "Nein"}
								>
									<Button icon={<DeleteOutlined />} type="primary" block>{count.index===-1 ? "Abbrechen" : "Löschen"}</Button>
								</Popconfirm>						
							</Col>
						</Row>
					)
				}                                
			>
				<Form layout="vertical">
					<Row gutter={[8,8]} >					
						<Col span={24}>						
							{
								schadenSchemaArray.map((a,i) => (
									(a.s && (
										(a.t === 'boolean' &&
											<Checkbox key={'input'+i} name={a.k} checked={attribute[a.k]} onChange={e => changeBoolean(a.k,e.target.checked)} style={{ marginTop: 6, marginBottom: 4 }}>
												{a.k.charAt(0).toUpperCase() + a.k.slice(1)}
											</Checkbox>
										) || (a.t === "number" &&
											<Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.k.charAt(0).toUpperCase() + a.k.slice(1)}>
												<InputNumber key={'input'+i} name={a.k} value={attribute[a.k]} style={{ width: '100%' }} onChange={(value: number | null) => changeNumber(a.k,value)} />
											</Form.Item>										
										) || (
											( a.k === 'schaden' ?
												<Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.k.charAt(0).toUpperCase() + a.k.slice(1)}>
													<Select
														showSearch
														style={{ width: '100%' }}
														placeholder={'Bitte wählen Sie ein Schaden aus'}
														value={attribute['schaden']}
														optionFilterProp="children"
														onFocus={getSchaeden}
														onChange={changeSchaeden}
														filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
														filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
														options={schaeden.filter(({ lage }) => (attribute['umfeld'] && lage === 'V')
															|| (attribute['wurzel'] && lage === 'Wu')
															|| (attribute['stammfuß'] && lage === 'W')
															|| (attribute['stamm'] && lage === 'S')
															|| (attribute['krone'] && lage === 'K')  )
															.reduce((acc,  obj ) => {
																const existingItem = acc.find(item => Object.values(item)[0] === obj.beschreibung);
																if (!existingItem) {
																	const newObj = { label: obj.beschreibung, value: obj.beschreibung, massnahme: obj.massnahme };
																	acc.push(newObj as never);
																}									
							
																return acc;
															}, [])
														}
													/>						
												</Form.Item>
											:
												<Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.k.charAt(0).toUpperCase() + a.k.slice(1)}>
													<Input key={'input'+i} name={a.k} value={attribute[a.k]} onChange={(e: ChangeEvent<HTMLInputElement>) => changeString(a.k,e.currentTarget.value)} />
												</Form.Item>
											)
										)
									))
								))
							}	

							<Form.Item key={'behoben'} style={{ marginBottom: 4 }} label='Behoben'>
								<DatePicker value={behoben ? behoben : null} format={['DD.MM.YYYY']} onChange={(_, dateString) => changeBehoben(dateString as string /*!API*/)} locale={locale} style={{ width: '100%' }} />					
							</Form.Item>

						</Col>
					</Row>
				</Form>
			</Drawer>
		</div>
	);
};

export { BaumSchadenDrawer };
export type { Schaden };
//export const MemoizedbaumSchadenDrawer = React.memo(baumSchadenDrawer);