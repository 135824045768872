import React, {useContext, useEffect, useRef, useState, ChangeEvent } from "react";
import 'antd/dist/reset.css';

import MapContext from "../../Map/MapContext";
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

import Feature from 'ol/Feature';
import { buffer } from 'ol/extent';

import {
    //App,
    Button,
    Card,
    Carousel,
    Checkbox,
    Col,
    Form,
    Image,
    Input,
    InputNumber,
    message,
    Modal,
    Pagination,
    Popconfirm,
    Radio,
    Row,
    Select,
    Space,
    Upload, 
	Drawer
} from 'antd';
import type { RadioChangeEvent } from 'antd';

import {
    AuditOutlined, 
    BgColorsOutlined,
    DeleteOutlined,
    DownOutlined,
    EyeOutlined,
    QuestionCircleOutlined,
    SaveOutlined,
    UpOutlined,
    UploadOutlined 
} from '@ant-design/icons';

import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile, UploadProps, UploadChangeParam } from 'antd/es/upload/interface';
import type { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';

import { array2Schema /*, MapSchema*/} from '../EnvProps';
import { env } from '../Env'; 

import {useSettings} from '../SettingsContext';
//import { Geometry } from "ol/geom";
  
import { useAuth0 } from '@auth0/auth0-react';

import { uploadPublicDokumentePG, downloadDokumentePG, deleteDokumentePG , downloadAllAttributePG , getbyFkeyPG, updatebyPkeyPG,newwithFKeyPG,uploadAuthDokumentePG} from '../../Postgres/pg';
//import { Geometry } from "ol/geom";

import { BaumZustandDrawer,Zustand } from './BaumZustand';
import { BaumBewaesserungDrawer,Bewaesserung } from './BaumBewaesserung';
import { BaumBeurteilungenDrawer,Beurteilungen } from './BaumBeurteilungen';

const baumSchemaArray = env.baum ? env.baum.attribute : [];
const baumSchema = array2Schema(baumSchemaArray);
type BaumSchemaTyp = typeof baumSchema;
//type Baum = MapSchema<BaumSchemaTyp>;
const isKeyBaum = (k: string): k is keyof BaumSchemaTyp => k in baumSchema;

const zustandSchemaArray = env.baum ? env.baum.zustandSchema : [];
const bewaesserungSchemaArray = env.baum ? env.baum.bewaesserungSchema : [];
const beurteilungenSchemaArray = env.baum ? env.baum.beurteilungenSchema : [];

interface baumDrawerProps {
    url: string;
    closeDrawer: (unclick:Boolean,action:string) => void;
    setClick: React.Dispatch<React.SetStateAction<boolean>>;
    featuresPos: number;
    featuresCount: number;
    setFeaturesPos: React.Dispatch<React.SetStateAction<number>>;
    feature: Feature | null;
}  

const BaumDrawer:React.FC<baumDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("baumDrawer:React.FC");
    const map = useContext(MapContext);

    const {settings} = useSettings();
    const [disabled, setDisabled] = useState(true);
    const [expandBaumart, setExpandBaumart] = useState(false);
    const [touched, setTouched] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null); 
  
    const [changed, setChanged] = useState(false);
    const [attribute, setAttribute] = useState<{[key: string]: any}>({});
    const [dokumente, setDocumente] = useState<Array<{index:number, datei:string}>>([]);

    const [zustaende, setZustaende] = useState<Array<Zustand>>([]);
    const [bewaesserung, setBewaesserung] = useState<Array<Bewaesserung>>([]);
    const [beurteilungen, setBeurteilungen] = useState<Array<Beurteilungen>>([]);
    const [indexDokument, setIndexDokument] = useState(-1);
    const [carousel, setCarousel] = useState(true);
    const [bewaessert, setBewaessert] = useState(false);
    const [isBewaesserungOpen, setIsBewaesserungOpen] = useState(false);
    const [wassermenge, setWassermenge] = useState(200);
    const [bewBem, setBewBem] = useState('');

    const [baumarten, setBaumarten] = useState<{ name:string, lat:string, typ: number, value:number }[]>([]);
    const [strassen, setStrassen] = useState<{ name:string, ort:string, kuerzel:string, value:number }[]>([]);
        
    const {
        isAuthenticated,
        getAccessTokenSilently,
        user
    } = useAuth0();
    const [modal, contextHolder] = Modal.useModal();

    useEffect(() => {
        if (props.feature) {
            if (props.feature.get('label') === 'baum') {
                setlog && console.log("baumDrawer:React.FC useEffect.[props.feature]", props.feature);
                
                setChanged(false);
                setBewaessert(false);
                setDocumente([]);
                setIndexDokument(-1);
        
                downloadDokumentePG(props.feature!,updateDokumente);

                baumSchemaArray.forEach((a) => {
                    if ( a.u && isKeyBaum(a.k) ){
                        const value: keyof BaumSchemaTyp = a.k; 
                        //setlog && console.log("baumDrawer:React.FC useEffect.[props.feature]", value,props.feature?.get(value));
                        setAttribute( prevAttribute => { return {...prevAttribute, [value]:props.feature?.get(value)}});
                    }
                });

                if ( baumarten.length <= 1 ){ 
                    const o: { name:string, lat:string, typ: number, value:number }[] = [];
                    if ( props.feature?.get('art_de') ){
                        o.push({
                            name: props.feature?.get('art_de'),
                            typ: 1,
                            lat: props.feature?.get('art_l'),
                            value: props.feature?.get('art')
                        });
                    }
                    setBaumarten(o); 
                }
                
                const geometry = props.feature.getGeometry();
                const extent = geometry!.getExtent();
                const extentB = buffer(extent, 50);
                map?.getView().fit(extentB, {
                    duration: 2000
                });                                          

                if (isAuthenticated){
                    if (zustaende.length > 0) {
                        getKontrollen();
                    }
                    if (bewaesserung.length > 0) {
                        getBewaesserungen();
                    }                    
                }            
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.feature]); 
    
    useEffect(() => {
        setlog && console.log("baumDrawer:React.FC useEffect.[changed] "+ changed);
        props.setClick(() => (!changed));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changed]);    
    
    const insertDokument = (index:number, datei:string) =>{
        setlog && console.log("baumDrawer:React.FC insertDokument", index);
        message.success('Das Bild wurde erfolgreich geladen!');
        setDocumente([...dokumente,{index:index, datei:datei}]);
    };

    const uploadDokument = async ( options: RcCustomRequestOptions): Promise<void> => {
        setlog && console.log("baumDrawer:React.FC uploadDokument", options);
        uploadPublicDokumentePG(props.feature!,getAccessTokenSilently,options.file,insertDokument);
    };

    const setDokument = (current:number) =>{
        setlog && console.log("baumDrawer:React.FC setDokument", current);
        setIndexDokument(current);
    };

    const deleteDokument = () =>{
        setlog && console.log("baumDrawer:React.FC deleteDokument",indexDokument);
        if ( indexDokument !== -1){
            const index = dokumente[indexDokument].index;
            deleteDokumentePG(props.feature!,getAccessTokenSilently,index);
            setDocumente(dokumente.filter(item => item.index !== index));
        }
    };

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj as RcFile);
            reader.onload = () => resolve(reader.result as string);
          });
        }
        //const image = new (window as any).Image();
        const image = new window.Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };    

    const onChange = (info: UploadChangeParam) => {
        setlog && console.log("baumDrawer:React.FC onChange",info);
    };

    const propsUpload: UploadProps = {
        showUploadList: false,
        customRequest: uploadDokument,
        onPreview: onPreview,
        listType: 'picture',
        onChange: onChange,
    };

    const propsImgCrop = {
        modalTitle: 'Bild bearbeiten',
        modalOk: '...go!',
        modalCancel: 'Abbrechen',
        resetText: 'Zurücksetzen',
        showGrid: true,
        rotationSlider: true,
        aspectSlider: true,
        showReset: true,
    };

    const updateDokumente = (data:any) =>{
        setlog && console.log("baumDrawer:React.FC updateDokumente", data);
        setIndexDokument(0);
        setDocumente(data);
    };

    const updateZustand = (data:any) =>{
        setlog && console.log("baumDrawer:React.FC updateZustand", data);
        setZustaende(data);
    };

    const updateBeurteilungen = (data:any) =>{
        setlog && console.log("baumDrawer:React.FC updateBeurteilungen", data);
        setBeurteilungen(data);
    };

    const updateBewaesserung = (data:any) =>{
        setlog && console.log("baumDrawer:React.FC updateBewaesserung", data);
        setBewaesserung(data);
    };

    const addBewaesserung = (data:any) =>{
        setlog && console.log("baumDrawer:React.FC addBewaesserung", data);
        if ( bewaesserung.length > 0){
            getbyFkeyPG('baumbewaesserung','baum','baumid',env.baum!.bewaesserungSchema,props.feature?.get('index'),'ORDER BY datum DESC',getAccessTokenSilently,updateBewaesserung);
        }
    };

    const closeZustandDrawer = () =>{
        setlog && console.log("baumDrawer:React.FC closeZustandDrawer");
        setZustaende([]);
    };    

    const closeBewaesserungDrawer = () =>{
        setlog && console.log("baumDrawer:React.FC closeBewaesserungDrawer");
        setBewaesserung([]);
    };        

    const closeBeurteilungenDrawer = () =>{
        setlog && console.log("baumDrawer:React.FC closeBeurteilungenDrawer");
        setBeurteilungen([]);
    };        


    const newZustand = (data:any) =>{
        setlog && console.log("baumDrawer:React.FC newZustand", data);
        const heute = new Date().toISOString().slice(0, 10).replace(/-/g, '');

        if ( data.length > 0) {
            setlog && console.log("baumDrawer:React.FC newZustand da ist was");

            let lastKontrolle = data[data.length-1];
            lastKontrolle.index = -lastKontrolle.index;
            lastKontrolle.kontrolle = heute;
        
            setZustaende([lastKontrolle]);        
        }
        else {
            setlog && console.log("baumDrawer:React.FC newZustand leider nix");
    
            const newKontrolle:Zustand = {index: 0, kontrolle: heute};
            zustandSchemaArray.forEach((a) => {
                if ( a.u && a.hasOwnProperty('d')){						
                    newKontrolle[a.k] = a.d!; 
                }
            });

            setZustaende([newKontrolle]);
        }
    };

    const getKontrollen = () => {
        setlog && console.log("baumDrawer:React.FC getKontrollen");
        getbyFkeyPG('baumzustand','baum','baumid',env.baum!.zustandSchema,props.feature?.get('index'),'ORDER BY kontrolle ASC',getAccessTokenSilently,updateZustand);
    };

    const newKontrolle = () => {
        setlog && console.log("baumDrawer:React.FC newKontrolle");
        getbyFkeyPG('baumzustand','baum','baumid',env.baum!.zustandSchema,props.feature?.get('index'),'ORDER BY kontrolle ASC',getAccessTokenSilently,newZustand,false);
    };

    const getBeurteilungen = () => {
        setlog && console.log("baumDrawer:React.FC getBeurteilungen");
        getbyFkeyPG('baumbeurteilungen','baum','baumid',beurteilungenSchemaArray,props.feature?.get('index'),'ORDER BY index ASC',getAccessTokenSilently,updateBeurteilungen);
    };

    const getBewaesserungen = () => {
        setlog && console.log("baumDrawer:React.FC getBewaesserungen");
        getbyFkeyPG('baumbewaesserung','baum','baumid',env.baum!.bewaesserungSchema,props.feature?.get('index'),'ORDER BY datum DESC',getAccessTokenSilently,updateBewaesserung);
    };

    const bewaessertHeute = () => {
        setIsBewaesserungOpen(true);
    };

    const dboSave = () =>{
        setlog && console.log("baumDrawer:React.FC dboSave");

        if ( isAuthenticated ){
            if ( attribute.typ === null ) {
                setlog && console.log("baumDrawer:React.FC dboSave", attribute.typ);
                Modal.warning({
                    title: 'Baumart fehlt',
                    content: 'Bitte wählen Sie eine Baumart aus!',
                });
            }
            else {
                baumSchemaArray.forEach((a) => {
                    if ( a.u && isKeyBaum(a.k) ){
                        const value: keyof BaumSchemaTyp = a.k; 
                        props.feature?.set(value,attribute[value]);
                    }
                });

                updatebyPkeyPG('baum','baum', props.feature?.get('index'), baumSchemaArray,attribute,getAccessTokenSilently,() => {});
                setChanged(false);
            }
        }
        else {
            cleanUp();
            props.closeDrawer(true,'');
        }
    };

    const closeDrawer = () =>{
        setlog && console.log("baumDrawer:React.FC closeDrawer");

        changed ? 
            modal.confirm({
                title: 'Die veränderten Daten wurden noch nicht gespeichert!',
                content: 'Möchten Sie den Dialog wirklich schließen?',
                okText: "...go!",
                okType: "danger",
                cancelText: "Nein",                
                onOk: () => closeDrawerReally(),
                onCancel: () => {},
            }) : closeDrawerReally();
    };

    const closeDrawerReally = () =>{
        setlog && console.log("baumDrawer:React.FC closeDrawer");
        cleanUp();                
        props.closeDrawer(true,'');
    };    

    const cleanUp = () =>{
        setlog && console.log("baumDrawer:React.FC cleanUp");
        setChanged(false);
        setDocumente([]);
        setIndexDokument(-1);               
    };
    
    const dboDeleteReally = () =>{
        setlog && console.log("baumDrawer:React.FC dboDeleteReally");
        if ( isAuthenticated ){
            setlog && console.log("baumDrawer:React.FC dboDeleteReally",attribute);
            cleanUp();
            props.closeDrawer(true,'delete');
        }
        else {
            cleanUp();
            props.closeDrawer(true,'');
        }            
    };

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        //setlog && console.log("baumDrawer:React.FC omStart");
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };    

    const changeString = (key:string, value:string) =>{
        //setlog && console.log("baumDrawer:React.FC changeString", value);
        if ( isAuthenticated ){
            setChanged(true);
            setAttribute( currentState => { return {...currentState, [key]:value}});
        }
    };
    
    const changeNumber = (key:string, value:number| null) =>{
        setlog && console.log("baumDrawer:React.FC changeString", value);
        setAttribute( currentState => { return {...currentState, [key]:value}});
    };	

    const changeBoolean = (key:string, value:boolean) =>{
        //setlog && console.log("baumDrawer:React.FC changeString", value);
        if ( isAuthenticated ){        
            setChanged(true);
            setAttribute( currentState => { return {...currentState, [key]:value}});
        }
    };

    const updateBaumarten = (data:any) =>{
        setlog && console.log("baumDrawer:React.FC updateBaumarten");
        
        const o: { name:string, lat:string, typ: number, value:number }[] = [];

        if (data.length > 0) {

            const p:number = data.length-1;
            for (let i = 0; i <= p; i++) {
                o.push({
                    name: data[i].name,
                    typ: data[i].typ,
                    lat: data[i].lat_name,
                    value: data[i].index,
                  });
            };				
        }
        setBaumarten(o);         
    };

    const getBaumarten = () =>{
        setlog && console.log("baumDrawer:React.FC getBaumarten",baumarten.length);
        isAuthenticated && baumarten.length <= 1 && downloadAllAttributePG('baumart','baum','index,typ,name,lat_name',getAccessTokenSilently,updateBaumarten); 
    };

    const changeBaumart = (_value: number, option:any) => {
		setlog && console.log("baumDrawer:React.FC changeBaumart", option);
        if ( isAuthenticated ){
            setChanged(true);
            isAuthenticated && setAttribute( currentState => { return {...currentState, 'art_de':option.name, 'art_l':option.lat, 'typ': getBumtyp(option.typ), 'art':option.value}});
        }
	};	

    const updateStrassen = (data:any) =>{
        setlog && console.log("baumDrawer:React.FC updateStrassen");        
        const o: { ort:string, name:string, kuerzel: string , value:number}[] = [];
        if (data.length > 0) {
            const p:number = data.length-1;
            for (let i = 0; i <= p; i++) {
                o.push({
                    ort: data[i].ort,
                    name: data[i].strasse,
                    kuerzel: data[i].kuerzel,
                    value: i,
                });
            };				
        }
        setStrassen(o);
                 
    };

    const getStrassen = () =>{
        setlog && console.log("baumDrawer:React.FC getStrassen");
        isAuthenticated && strassen.length === 0 && downloadAllAttributePG('strassen','baum','ort,strasse,kuerzel',getAccessTokenSilently,updateStrassen); 
    };

    const changeStrasse = (_value: number, option:any) => {
		setlog && console.log("baumDrawer:React.FC changeStrasse", option);
        if ( isAuthenticated ){
            setChanged(true);
            if ( attribute.bezeichnung ){
                setAttribute( currentState => { return {...currentState, 'strasse':option.value}});
            }
            else {
                setAttribute( currentState => { return {...currentState, 'strasse':option.value, 'bezeichnung':option.kuerzel + '_'}});
            }
        }
	};	

    const changeFeature = (page: number, pageSize: number) => {
        setlog && console.log("baumDrawer:React.FC changeFeature", page, pageSize);
        props.setFeaturesPos(page);
    };

    const getBumtyp = (typ: number) : string => {
		setlog && console.log("baumDrawer:React.FC getBumtyp", typ);
        let s_typ:string;
        switch ( typ ){
            case 1:
                s_typ = 'Nadelbaum';
            break;
            case 2:
                s_typ = 'Laubbaum';
            break;
            case 3:
                s_typ = 'Nadelbaum - Gruppe';
            break;
            case 4:
                s_typ = 'Laubbaum - Gruppe';
            break;
            default:
                s_typ = 'Unbekannt';
            break;
        }
        return s_typ;
	};	 
    
    const title = (
        <Row gutter={[8,8]} >
            <Col span={12}>
                <div style={{ fontSize: '1.2em' }}>
                    <a id="baum_mailto" href={"mailto:" +  (isAuthenticated ? user?.email : ( env.baum!.mail ? 'baum-' + env.mailto : ''))  + "?Subject=Baum: " +props.feature?.get('index')+ "&body=Hier der Link zum Baum " + props.url + "%26search=baum," +props.feature?.get('index')}>
                        {"Baum "+props.feature?.get('index')}
                    </a>
                </div>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination simple size='small' current={props.featuresPos} pageSize={1} total={props.featuresCount} onChange={changeFeature}/>
            </Col>
        </Row>
    ); 
    
    const bewaesserungJa = async () =>{
        setlog && console.log("baumDrawer:React.FC bewaesserungJa ",props.feature?.get('bewaessert'));
        const heute = new Date().toISOString().slice(0, 10).replace(/-/g, '');
        props.feature?.set('bewaessert',heute);
        updatebyPkeyPG('baum','baum', props.feature?.get('index'), baumSchemaArray,{'bewaessert': heute},getAccessTokenSilently,() => {});
        setBewaessert(true);
        newwithFKeyPG('baumbewaesserung', 'baum', 'baumid', props.feature!.get('index') as number, bewaesserungSchemaArray, {'datum': heute, 'wasser': wassermenge, 'bemerkung': bewBem, 'fahrer': user?.name}, getAccessTokenSilently, addBewaesserung);
        setIsBewaesserungOpen(false);
    }; 
    const bewaesserungNein = () =>{setIsBewaesserungOpen(false);}; 


	const insertBeurteilung = (index:number, datei:string) =>{
        setlog && console.log("baumDrawer:React.FC insertBeurteilung", index);
        setBeurteilungen([...beurteilungen,{index:index, datei:datei}]);
    };
	
	const uploadProps: UploadProps = {
		showUploadList: false,
		name: 'file',
		beforeUpload: file => {
			const isPDF = file.type === 'application/pdf';
			if (!isPDF) {
			  message.error(`Es können nur PDF Dateien geladen werden!`);
			}
			return isPDF || Upload.LIST_IGNORE;
		  },		
		onChange(info) {
		  if (info.file.status !== 'uploading') {
			console.log(info.file, info.fileList);
		  }
		  if (info.file.status === 'done') {
			message.success(`Die Datei wurde erfolgreich geladen!`);
			uploadAuthDokumentePG('baumbeurteilungen','baum', props.feature?.get('index') as number, getAccessTokenSilently,info.file.originFileObj, insertBeurteilung);
		  } else if (info.file.status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		  }
		},
	};    

    const body = (
        <Form layout="vertical">
            <Row gutter={[8,8]} >                        
                <Col span={24}>
                    <Card title={attribute['typ']} bordered={false} 
                    extra={
                        <Button
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={expandBaumart ? <UpOutlined /> : <DownOutlined /> }
                        style={{ color: 'white', zIndex: 10 }}
                        onClick={() => setExpandBaumart( currentState => !currentState )}
                        />}
                    style={{ width: '100%' }}>
                    
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder={'Bitte wählen Sie eine Baumart aus'}
                            value={attribute['art']}
                            optionFilterProp="children"
                            onFocus={getBaumarten}
                            onChange={changeBaumart}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                            options={baumarten.map((baumart) => ( {label: baumart.name, name: baumart.name, lat: baumart.lat, typ: baumart.typ, value: baumart.value}))}
                        />
                        { expandBaumart && (<Select
                            showSearch
                            style={{ width: '100%', marginTop: 8 }}
                            placeholder={'Bitte wählen Sie eine Baumart aus'}
                            value={attribute['art_l']}                                  
                            optionFilterProp="children"
                            onFocus={getBaumarten}
                            onChange={changeBaumart}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                            options={baumarten.map((baumart) => ( {label: baumart.lat, name: baumart.name, lat: baumart.lat, typ: baumart.typ, value: baumart.value}))}
                        />)}                                
                    </Card>          
                    {
                        baumSchemaArray.map((a,i) => (
                            (a.s && (
                                (a.t === 'boolean' &&
                                    <Checkbox key={'input'+i} name={a.k} checked={attribute[a.k]} onChange={e => changeBoolean(a.k,e.target.checked)} style={{ marginTop: 6, marginBottom: 4 }}>
                                        {a.k.charAt(0).toUpperCase() + a.k.slice(1)}
                                    </Checkbox>
                                ) || (a.t === "number" &&
                                    <Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.k.charAt(0).toUpperCase() + a.k.slice(1)}>
                                        <InputNumber key={'input'+i} name={a.k} value={attribute[a.k]} style={{ width: '100%' }} onChange={(value: number | null) => changeNumber(a.k,value)} />
                                    </Form.Item>										
                                ) || (
                                    ( a.k === 'strasse' ?
                                        <Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.k.charAt(0).toUpperCase() + a.k.slice(1)}>
                                            <Select
                                                showSearch
                                                style={{ width: '100%' }}
                                                placeholder={'Bitte wählen Sie eine Strasse aus'}
                                                value={attribute[a.k]}
                                                optionFilterProp="children"
                                                onFocus={getStrassen}
                                                onChange={changeStrasse}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                                options={strassen.map((strasse) => ( {label: strasse.name + ' ( ' + strasse.kuerzel + ' )', ort: strasse.ort, kuerzel: strasse.kuerzel, value: strasse.name}))}
            
                                            />
                                        </Form.Item>
                                    :
                                        <Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.k.charAt(0).toUpperCase() + a.k.slice(1)}>
                                            <Input key={'input'+i} name={a.k} value={attribute[a.k]} onChange={(e: ChangeEvent<HTMLInputElement>) => changeString(a.k,e.currentTarget.value)} />
                                        </Form.Item>
                                    )
                                )
                            ))
                        ))
                    }     
                    <Form.Item style={{ marginBottom: 4 }} label={"Bilder (" + dokumente.length  + ")"}>                                    
                        {dokumente.length === 0 ? (
                            <Image
                                preview = {false}
                                key = 'image_default'
                                src = "./baum_dokumente/default.jpg"
                            />
                        ) : (
                            <Image.PreviewGroup>
                                <Carousel autoplay={carousel} effect="fade" infinite={false} afterChange={setDokument}>
                                    { dokumente.map((element) => (
                                        <Image
                                            key = {'image'+element.index}
                                            src = { "./baum_dokumente/" + element.datei}
                                            fallback = "./baum_dokumente/default.jpg"
                                        />
                                    ))}
                                </Carousel>
                            </Image.PreviewGroup>
                        )}                                                                                   
                    </Form.Item>

                    { isAuthenticated && (
                        <Space>
                            <ImgCrop {...propsImgCrop}>
                                <Upload {...propsUpload}>
                                    <Button icon={<UploadOutlined />} />             
                                </Upload>
                            </ImgCrop>


                            <Popconfirm
                                onPopupClick={() => setCarousel(true)} 
                                onConfirm={deleteDokument}
                                title={"Wollen Sie das Bild wirklich löschen?"} 
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                okText="...go!"
                                okType="danger"
                                cancelText="Nein"
                            >
                                <Button icon={<DeleteOutlined />} onClick={() => setCarousel(false)}  />
                            </Popconfirm>						
                        </Space>
                    )}                        
                </Col>
            </Row>
        <Space></Space>
            { isAuthenticated && (  
                <Row gutter={[8,8]} >
                    <Col span={12}>
                        <Button onClick={getKontrollen} style={{ marginTop: 8 }} type="primary" block>Kontrollen</Button>	
                    </Col>
                    <Col span={12}>
                        <Button icon={<EyeOutlined />} onClick={newKontrolle} style={{ marginTop: 8 }} type="primary" block>Neue Kontrolle</Button>
                    </Col>
                    <Col span={12}>
                        <Button onClick={getBeurteilungen} style={{ marginTop: 8 }} type="primary" block>Beurteilungen</Button>
                    </Col>
                    <Col span={12}>
							<Upload  {...uploadProps}>
								<Button icon={<AuditOutlined />} style={{ marginTop: 8 }} type="primary" block>Neue Beurteilung</Button>
							</Upload>
					</Col>
                    <Col span={12}>
                        <Button onClick={getBewaesserungen} style={{ marginTop: 8 }} type="primary" block>Bewässerungen</Button>	
                    </Col>
                    <Col span={12}>
                        <Button icon={<BgColorsOutlined />} onClick={bewaessertHeute} disabled={bewaessert} style={{ marginTop: 8 }} type="primary" block>Heute</Button>
                    </Col>                    
                </Row>
            )}                           
        </Form>            
    );    

    if ( !settings.mobile ){
        return ( 
            <div> {contextHolder}
                <Drawer
                    title={title}
                    className="ggw-baum"
                    placement="right"
                    open={props.feature?.get('label')==='baum'}
                    onClose={changed ? closeDrawer : closeDrawer }
                    mask={false}
                    footer={
                        isAuthenticated && (
                            <Row gutter={[8,8]} >
                                <Col span={12}>
                                    <Button icon={<SaveOutlined />} onClick={dboSave} disabled={!changed} type="primary" block>Speichern</Button>	
                                </Col>
                                <Col span={12}>
                                    <Popconfirm 
                                        onConfirm={dboDeleteReally}
                                        title={"Wollen Sie den Baum wirklich löschen?"} 
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        okText="...go!"
                                        okType="danger"
                                        cancelText="Nein"
                                    >
                                        <Button icon={<DeleteOutlined />} type="primary" block>Löschen</Button>
                                    </Popconfirm>						
                                </Col>
                            </Row>
                        )
                    }                                
                >
                    {body}
                </Drawer>           
                <BaumZustandDrawer feature={props.feature} zustaende={zustaende} setZustaende={setZustaende} closeZustandDrawer={closeZustandDrawer} />
                <BaumBewaesserungDrawer setClick={props.setClick} feature={props.feature} bewaesserung={bewaesserung} setBewaesserung={setBewaesserung} bewaessertHeute={bewaessertHeute} closeBewaesserungDrawer={closeBewaesserungDrawer} />
                <BaumBeurteilungenDrawer setClick={props.setClick} feature={props.feature} beurteilungen={beurteilungen} setBeurteilungen={setBeurteilungen} closeBeurteilungenDrawer={closeBeurteilungenDrawer} />
                <Modal 
                    open={isBewaesserungOpen}
                    closable={false} 
                    onOk={bewaesserungJa} 
                    onCancel={bewaesserungNein} 
                    okText='Wasswer marsch!' 
                    cancelText='Abbrechen'
                >
                    <Row gutter={[8,8]} >                    
                        <Col span={24}>
                            <Card title="Bewässerung">
                                <Form.Item key={'wassermenge'} style={{ marginBottom: 4 }} label={'Wassermenge'}>
                                    <Col span={24}>
                                        <Radio.Group onChange={(e: RadioChangeEvent) => {setWassermenge(e.target.value)}} value={wassermenge}>
                                            <Radio value={150}>150 Liter</Radio>
                                            <Radio value={200}>200 Liter</Radio>
                                            <Radio value={400}>400 Liter</Radio>
                                        </Radio.Group>
                                    </Col>
                                </Form.Item>
                                <Form.Item key={'bemerkung'} style={{ marginBottom: 4 }} label={'Bemerkung'}>
                                    <Col span={24}>
                                        <Input key={'bewBem'} value={bewBem} style={{ width: '100%' }} onChange={(e: ChangeEvent<HTMLInputElement>) => setBewBem(e.currentTarget.value)} />
                                    </Col>
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>                    
                </Modal>
            </div>
        );
    }
    else {
        return ( 
            <Modal
                title={ <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}                                    
                    onMouseOver={() => {disabled && setDisabled(false);}}
                    onMouseOut={() => {setDisabled(true);}}
                    onTouchStart={() => {touched ? setDisabled(true) : setTouched(false);}}
                    onTouchEnd={() => {disabled ? setTouched(true) : setDisabled(false);}}
                    onTouchCancel={() => {!disabled && setDisabled(true);}}                    
                    >
                        {title}
                    </div>
                }
                open={props.feature?.get('label')==='baum'}
                onCancel={closeDrawer}
                footer={[                  
                    isAuthenticated && (
                        <Row key='f_r' gutter={[8,8]} >
                            <Col key='f_c1' span={12}>
                                <Button key='f_save' onClick={dboSave} disabled={!changed} type="primary" block>Speichern</Button>	
                            </Col>
                            <Col key='f_c2' span={12}>
                                <Popconfirm
                                    key='popconfirm' 
                                    onConfirm={dboDeleteReally}
                                    title={"Wollen Sie die Trinkstaionen wirklich löschen?"} 
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    okText="...go!"
                                    okType="danger"
                                    cancelText="Nein"
                                >
                                    <Button key='f_delete' type="link" block>Löschen</Button>
                                </Popconfirm>						
                            </Col>
                        </Row>
                    )
                ]}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}                   
            >
                {body}
            </Modal>
        );
    }        
};

export default BaumDrawer;
//export const MemoizedbaumDrawer = React.memo(baumDrawer);