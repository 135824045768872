import React, {useEffect} from 'react';
import { useAuth0, withAuthenticationRequired  } from "@auth0/auth0-react";

import Map from "./Map/Map"

const App: React.FC = () => {
  const setlog = false;
  setlog && console.log("App:React.FC");
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  /*  
  useEffect(() => {
    setlog && console.log("App:React.FC useEffect[]");
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);
  */

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      setlog && console.log("App:React.FC useEffect[ isLoading, isAuthenticated, loginWithRedirect ] - Loading");
      return;
    }
    setlog && console.log("App:React.FC useEffect[ isLoading, isAuthenticated, loginWithRedirect ] - loginWithRedirect");
    (async () => {
      await loginWithRedirect();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isLoading, isAuthenticated, loginWithRedirect ]);

  return (
    <Map />
  );
}

export default withAuthenticationRequired(App);
