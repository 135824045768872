import React, { useContext, useState, useEffect, useReducer } from "react";

import OlLayer from 'ol/layer/Layer';
import OlTileWMSSource from 'ol/source/TileWMS';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import 'antd/dist/reset.css';

import { SettingOutlined } from '@ant-design/icons';

import SimpleButton from "./UI/SimpleButton"
import MapContext from "../Map/MapContext";
import {useSettings} from '../Components/SettingsContext';

import { createStringXY as OlcreateStringXY } from 'ol/coordinate';
import OlMousePosition from 'ol/control/MousePosition';

import { useAuth0 } from '@auth0/auth0-react';

import {
    //Button,
    Card,
    Checkbox,
    Col,
    Form,
    //Input,
    Radio,
    Row,
    Tooltip, 
	Drawer 
} from 'antd';

import type { RadioChangeEvent } from 'antd';
import { env } from '../Components/Env';  

interface settingsProps {
    style: React.CSSProperties;
};

const Settings:React.FC<settingsProps> = (props) => {  
    const [ settings, setSettings ] = useState<Boolean>(false);
 
    const openCloseSettings = () =>{       
        //console.log('openSettings');
        setSettings((prevState) => { return !prevState });
    };        
    return (
        <div>            
            <SettingsDrawer open={settings} closeDrawer={openCloseSettings} />
            <SimpleButton
                type="default"
                style={props.style} 
                shape="circle"
                onClick={openCloseSettings}
                tooltip="Einstellungen"
                tooltipPlacement="right"
                icon={<SettingOutlined />}
            />          
        </div>
    );
};

interface settingsDrawerProps {
    open: Boolean;
    closeDrawer: () => void;
};

const SettingsDrawer:React.FC<settingsDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("SettingsDrawer:React.FC")
    const map = useContext(MapContext);
    const {settings,setSettings} = useSettings();
    const {
        isAuthenticated
    } = useAuth0(); 	    
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [indexDOP, setIndexDOP] = useState(0);

    //setlog && console.log("SettingsDrawer ", map?.getAllLayers());

    const closeDrawer = () =>{
        //setlog && console.log("closeDrawer SettingsDrawer ", props);
        //setlog && console.log("closeDrawer ", settings.mobile);
        //setlog && console.log("closeDrawer ", settings.testNum);
        //setlog && console.log("closeDrawer ", settings.testStr);
        props.closeDrawer();
    };
    
    useEffect(() => {
        setlog && console.log("SettingsDrawer:React.FC useEffect.[indexDOP] "+ indexDOP);
  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexDOP]);    

    const mapFilter : Array<OlLayer> | undefined = map?.getAllLayers().filter((layer) => {
        return layer.get('name').slice(0,1) !== '_';
    });

    const onChangeLayerVisible = (e: CheckboxChangeEvent, layer: OlLayer) => {
        //setlog && console.log('onChangeLayerVisible = ', e.target.checked);
        //setlog && console.log('layer = ', layer);
        layer.setVisible(e.target.checked);
        
        if ( e.target.checked === false ){
            layer.set('select' , false);
            layer.set('digi' , false);
            setSettings({type: 'digi', nextStr: ''})
            setlog && console.log("select aus weil nict sichtbar");
        }
        else {
            if ( layer.get('allow_select') === true){
                layer.set('select' , true);
            }
            if ( layer.get('allow_digi') === true){
                map?.getAllLayers().forEach((l) => {
                    if ( l.get('label') ){
                        if ( layer.get('label') === l.get('label')){
                            setSettings({type: 'digi', nextStr: l.get('name')})
                            l.set('digi' , true);
                        }
                        else{
                            l.set('digi' , false);
                        }
                    }
                });       
            }
        }
        forceUpdate();
    };   

    const onChangeLayerSelect = (e: CheckboxChangeEvent, layer: OlLayer) => {
        //setlog && console.log('onChangeLayerSelect = ', e.target.checked);
        //setlog && console.log('layer = ', layer);
        if ( layer.get('allow_select') === true){
            layer.set('select' , e.target.checked);
            forceUpdate();
        }
    };   

    const onChangeLayerDigi = (e: CheckboxChangeEvent, layer: OlLayer) => {
        //setlog && console.log('onChangeLayerSelect = ', e.target.checked);
        //setlog && console.log('layer = ', layer);

        map?.getAllLayers().forEach((l) => {
            if ( l.get('label') ){
                if ( layer.get('label') === l.get('label')){
                    l.set('digi' ,  true);
                    setSettings({type: 'digi', nextStr: l.get('name')})
                }
                else{
                    l.set('digi' , false);
                }
            }
        });       
        forceUpdate();
    };       

    /*
    const setInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //setlog && console.log('Change:', e.target.value);
        setSettings({type: 'testStr', nextStr: e.target.value})
    };
    */

    const setBaumplan = (e: RadioChangeEvent) => {
        //setlog && console.log('Change:', e.target.value);
        setSettings({type: 'baumplan', nextNum: e.target.value})
    };

    const setNMobplan = (e: RadioChangeEvent) => {
        //setlog && console.log('Change:', e.target.value);
        setSettings({type: 'nmobplan', nextNum: e.target.value})
    };

    const setStrassenschaden = (e: RadioChangeEvent) => {
        //setlog && console.log('Change:', e.target.value);
        setSettings({type: 'strassenschadenplan', nextNum: e.target.value})
    };    

    const setBaumsize = (e: RadioChangeEvent) => {
        //setlog && console.log('Change:', e.target.value);
        setSettings({type: 'baumsize', nextNum: e.target.value})
    };    

    const setDOP = (e: RadioChangeEvent) => {
        setlog && console.log('Change:', e.target.value);
        setIndexDOP(e.target.value);
        map?.getAllLayers().forEach((layer) => {
            if ( layer.get('label') === 'dop'){
                const year = env.dop.year.split(";")[e.target.value];
                const source = layer.getSource();
                if ( source instanceof OlTileWMSSource){
                    layer.set('name','Luftbilder '+ year);
                    source.updateParams({'LAYERS': env.dop.layer + year,'TILED': true});
                }
            }
            
        });
    };    

    const setProjection = (e: RadioChangeEvent) => {
        //setlog && console.log('Change:', e.target.value);
        setSettings({type: 'projection', nextStr: e.target.value})
       
        const controls = map?.getControls();

        controls?.forEach((control) => {
          if (control instanceof OlMousePosition) {
            map?.removeControl(control);
          }
        });

        map?.addControl(new OlMousePosition({
            coordinateFormat: OlcreateStringXY(e.target.value === 'EPSG:4326' ? 15 : 2),
            projection: e.target.value
        }));
    };    

    return ( 
        <Drawer
            title={"Einstellungen"}
            className="ggw-settings"
            placement="right"
            open={props.open?.valueOf()}
            onClose={closeDrawer}
            mask={false}		
        >
            <Form layout="vertical">
                <Row gutter={[8,8]} >					
                    <Col span={24}>
                        <Card title="Ebenen">                            
                            {mapFilter?.map (layer => 
                                <p key={layer.get('label')}>
                                    <Tooltip placement="top" title="Sichtbar">
                                        <Checkbox checked={layer.getVisible()} onChange = { (e: CheckboxChangeEvent) => onChangeLayerVisible(e,layer)}/>
                                    </Tooltip>
                                    {' '}
                                    <Tooltip placement="top" title="Auswählbar">
                                        <Checkbox disabled={!layer.get('allow_select')} checked={layer.get('select')} onChange = { (e: CheckboxChangeEvent) => onChangeLayerSelect(e,layer)}/>
                                    </Tooltip>
                                    {' '}                                     
                                    { isAuthenticated && (
                                        <Tooltip placement="top" title="Veränderbar">
                                            <Radio disabled={!layer.get('allow_digi')} checked={layer.get('name') === settings.digi} onChange = { (e: CheckboxChangeEvent) => onChangeLayerDigi(e,layer)}/>
                                        </Tooltip>
                                    ) }
                                   
                                    {' - '+layer.get('name')}      
                                </p>    
                            )}
                        </Card>
                    </Col>
                    { map?.getAllLayers().find((layer) => layer.get('label') === 'baum') && (
                        <Col span={24}>
                            <Card title="Baum">
                                <Col span={24}>                           
                                    <Checkbox checked={settings.baumbez} onChange = { () => setSettings({type: 'baumbez'})}>Bezeichnung</Checkbox>
                                </Col>                                
                                <Col span={24}>
                                    <Radio.Group onChange={setBaumsize} value={settings.baumsize}>
                                        <Radio key='baumsize1' value={1}>Klein</Radio>
                                        <Radio key='baumsize2' value={2}>Mittel</Radio>
                                        <Radio key='baumsize3' value={3}>Groß</Radio>
                                    </Radio.Group>
                                </Col>                                
                                <Col span={24}>
                                    <Radio.Group onChange={setBaumplan} value={settings.baumplan}>
                                        <Radio key='baumplan1' value={1}>Normal</Radio>
                                        <Radio key='baumplan2' value={2}>Jungbaum</Radio>
                                        <Radio key='baumplan3' value={3} disabled={!isAuthenticated} >Bewässerung</Radio>
                                        <Radio key='baumplan4' value={4} disabled={!isAuthenticated} >So/Wi</Radio>
                                    </Radio.Group>
                                </Col>
                            </Card>
                        </Col>
                    ) }
                    { map?.getAllLayers().find((layer) => layer.get('label') === 'nmob') && (
                        <Col span={24}>
                            <Card title="Nahmobilität">
                                <Col span={24}>
                                    <Radio.Group onChange={setNMobplan} value={settings.nmobplan}>
                                        <Radio key='nmoblan1' value={1}>Beendet aus</Radio>
                                        <Radio key='nmoblan2' value={2}>Beendet aus nach 1 Jahr</Radio>
                                        <Radio key='nmobplan3' value={3}>Alle</Radio>
                                    </Radio.Group>
                                </Col>
                            </Card>
                        </Col>
                    ) }             
                    { map?.getAllLayers().find((layer) => layer.get('label') === 'strassenschaden') && (
                        <Col span={24}>
                            <Card title="Strassenschäden">
                                <Col span={24}>
                                    <Radio.Group onChange={setStrassenschaden} value={settings.strassenschadenplan}>
                                        <Radio key='strassenschaden1' value={1}>Beendet aus</Radio>
                                        <Radio key='strassenschaden2' value={2}>Alle</Radio>
                                    </Radio.Group>
                                </Col>
                            </Card>
                        </Col>
                    ) }                                        
                    { map?.getAllLayers().find((layer) => layer.get('label') === 'dop') && (
                        <Col span={24}>
                            <Card title="Luftbilder">								
                                <Col span={24}>
                                    <Radio.Group onChange={setDOP} value={indexDOP}>
                                        {
                                            env.dop.year.split(";").map((a,i) => (
                                                <Radio key={'dop'+i} value={i}>{a}</Radio>
                                            ))
                                        }
                                    </Radio.Group>
                                </Col>                                
                            </Card>
                        </Col>
                    ) }                                        
                    <Col span={24}>
                        <Card title="Darstellung">
                            <Col span={24}>                           
                                <Checkbox checked={settings.cookies} onChange = { () => setSettings({type: 'cookies'})}>Die App darf Cookies verwenden!</Checkbox>
                            </Col>                            
                            <Col span={24}>                           
                                <Checkbox checked={settings.mobile} onChange = { () => setSettings({type: 'mobile'})}>Optimiert für schmale mobile Geräte</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Radio.Group onChange={setProjection} value={settings.projection}>
                                    <Radio value={'EPSG:25832'}>ETRS89 / UTM zone 32N (EPSG:25832)</Radio>
                                    <Radio value={'EPSG:4326'}>WGS 84 (EPSG:4326)</Radio>
                                </Radio.Group>
                            </Col>
                        </Card>
                    </Col>
                </Row>
            </Form>            
        </Drawer>
    );        
};

export {Settings};
//export const MemoizedHthDrawer = React.memo(HthDrawer);