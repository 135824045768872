import React, {useEffect, useState, ChangeEvent } from "react";
import 'antd/dist/reset.css';

import {
    //App,
    Button,
    Checkbox,
    Col,
    Form,
    Input,
	InputNumber,
//    message,
    Modal,
    Popconfirm,
    Row,
	Select,
//    Space,
	Table,
	Tooltip, 
//	Tag,
	Drawer 
} from 'antd';

import type { SelectProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { 
    BugOutlined,	
	CheckCircleOutlined,
	CheckOutlined,
	CrownOutlined ,
	DashboardOutlined,
	DeleteOutlined,
	ForkOutlined,
	MinusOutlined,
	PauseOutlined,
    QuestionCircleOutlined,
	RadarChartOutlined,
	SaveOutlined,
	UnderlineOutlined 
} from '@ant-design/icons';


import { array2Schema, MapSchema} from '../EnvProps';
import { env } from '../Env'; 

//import MapContext from "../Map/MapContext";
//import {useSettings} from '../Components/SettingsContext';
import Feature from 'ol/Feature';
//import { Geometry } from "ol/geom";
  
import { useAuth0 } from '@auth0/auth0-react';

import { updatebyPkeyPG, newwithFKeyPG, newwith2FKeyPG, deleteAttributePG, getbyFkeyPG} from '../../Postgres/pg';
import { BaumSchadenDrawer } from './BaumSchaden';


const zustandSchemaArray = env.baum ? env.baum.zustandSchema : [];
const zustandSchema = array2Schema(zustandSchemaArray);
type ZustandSchemaTyp = typeof zustandSchema;
type Zustand = MapSchema<ZustandSchemaTyp>;
const isKeyZustand = (k: string): k is keyof ZustandSchemaTyp => k in zustandSchema;


/*
const zustandSchemaArray = Object.entries(zustandSchema).map(([key, value]) => ({
	 attribut: key, typ: value.t, sichtbar: value.s , updatebar: value.u,
}));*/


interface baumZustandDrawerProps {
    closeZustandDrawer: () => void;
	setZustaende: React.Dispatch<React.SetStateAction<Array<Zustand>>>;
	feature: Feature | null;
    zustaende: Array<Zustand>;
}  

const BaumZustandDrawer:React.FC<baumZustandDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("baumZustandDrawer:React.FC");

    //const {settings} = useSettings();
        
    const {
        isAuthenticated,
        getAccessTokenSilently
    } = useAuth0();

	const schadenSchemaArray = env.baum!.schadenSchema;
	const schadenSchema = array2Schema(schadenSchemaArray);
	type SchadenSchemaTyp = typeof schadenSchema;
	type Schaden = MapSchema<SchadenSchemaTyp>;	

	const [changed, setChanged] = useState(false);
	const [updateKontrolle, setUpdateKontrolle] = useState(false);
	const [count, setCount] = useState({index: -1, pos: -1});
	const [attribute, setAttribute] = useState<{[key: string]: any}>({});
	const [schaden, setSchaden] = useState<Schaden[]>([]);
	const [selSchaden, setSelSchaden] = useState(-1);
	const [options, setOptions] = useState<SelectProps['options']>([]);

	const [modal, contextHolder] = Modal.useModal();

    useEffect(() => {
		setlog && console.log("baumZustandDrawer:React.FC useEffect.[props.zustaende]");

		if (props.zustaende) {
            if (props.zustaende.length > 0) {
				setlog && console.log("baumZustandDrawer:React.FC useEffect.[props.zustaende]", props.zustaende.length);

				const p:number = props.zustaende.length-1;
				const o: SelectProps['options'] = [];

				let kontrolle:string;
				for (let i = 0; i <= p; i++) {
					kontrolle = props.zustaende[i].kontrolle.toString();
					//setlog && console.log("baumZustandDrawer:React.FC useEffect.[props.zustaende]", kontrolle);
					o.push({
						label: kontrolle.slice(6,8)+'.'+kontrolle.slice(4,6)+'.'+kontrolle.slice(0,4),
						value: i,
					  });

				};				
				setOptions(o);
				
				const z:Zustand = props.zustaende[p];
				
				//const index: keyof typeof zustandSchema = 'index';
				//const i: Zustand[keyof Zustand] = z[index];

				setCount(c => ({index: z['index'] as number, pos: p}));
			}
		}	

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.zustaende]);
	
	const updateSchadenFunc = (data:any) =>{
		setlog && console.log("baumZustandDrawer:React.FC updateSchaden");
        if (data.length > 0) {
			setSchaden(data);				
        }
		else{
			setSchaden([]);
		}		
	};

	useEffect(() => {
		setlog && console.log("baumZustandDrawer:React.FC useEffect.[count]");
		count.pos>=0  && updateZustand(count.pos);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

	const updateZustand = (pos:number) =>{
        setlog && console.log("baumZustandDrawer:React.FC updateZustand ", pos);
		if (props.zustaende) {
            if (props.zustaende.length > 0) {
				
                //setlog && console.log("baumZustandDrawer:React.FC useEffect.[props.zustaende]", props.zustaende);		
								
				const z:Zustand = props.zustaende[pos];
				
				if (z.kontrolle === props.feature?.get('kontrolle') ){
					setUpdateKontrolle(true);
				}
				else {
					setUpdateKontrolle(false);
				}
				
				
				if ( z['index'] ===-1 ){
					setChanged(true)
				 } 
				else {
					if ( z['index'] < 0){
						getbyFkeyPG('baumschaden','baum','zustandid', schadenSchemaArray,-z.index as number,'',getAccessTokenSilently,updateSchadenFunc,false);
						setChanged(true);
					}
					else {
						getbyFkeyPG('baumschaden','baum','zustandid', schadenSchemaArray,z.index as number,'',getAccessTokenSilently,updateSchadenFunc,false);
						setChanged(false);
					}
				}
			
				zustandSchemaArray.forEach((a) => {
					//setlog && console.log("baumZustandDrawer:React.FC useEffect.[props.zustaende]", a.k, z[a.k]);
					if ( isKeyZustand(a.k) ){
						//const value: ZustandSchema1[keyof ZustandSchema1] = zustandSchema1[a.k];
						const value: keyof ZustandSchemaTyp = a.k;						
						//setlog && console.log("baumZustandDrawer:React.FC useEffect.[props.zustaende]", a.k);
						setAttribute( prevAttribute => { return {...prevAttribute, [a.k]:z[value]}});
					}
				});
            }
        }
    };

	useEffect(() => {
        if (props.feature) {
            if (props.feature.get('label') !== 'baum') {
                setlog && console.log("baumZustandDrawer:React.FC useEffect.[props.feature]", props.feature);
				closeZustandDrawer();
            }
        }
		else {
			closeZustandDrawer();
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.feature]); 	

	const newAttributeSchadenIndex = (index:number) =>{
		setlog && console.log("baumZustandDrawer:React.FC newAttributeSchadenIndex", index);
	};

	const neuerSchaden = () => {
		setlog && console.log("baumZustandDrawer:React.FC neuerSchaden");
		const newSchaden:Schaden = {index:-1, baumid: props.feature!.get('index'), zustandid: count.index };
		schadenSchemaArray.forEach((a) => {
			if ( a.u && a.hasOwnProperty('d')){						
				newSchaden[a.k] = a.d!; 
			}
		});
		setSelSchaden(schaden.length);
		setSchaden([...schaden,newSchaden]);		
	};			

	const newAttributeIndex = (index:number) =>{
        setlog && console.log("baumZustandDrawer:React.FC newAttributeIndex", index);
		setCount(c => ({index: index, pos: 0}));

		let dringlichkeit:number | null = null;

		selectedRowKeys.forEach((s) =>{
			setlog && console.log("baumZustandDrawer:React.FC newAttributeIndex", s);
			schaden.forEach((a) => {
				if ( a.index === s ){
					setlog && console.log("baumZustandDrawer:React.FC newAttributeIndex", a);
					a.behoben = '';
					newwith2FKeyPG('baumschaden', 'baum', 'baumid', props.feature!.get('index') as number, 'zustandid', index, schadenSchemaArray, a, getAccessTokenSilently, newAttributeSchadenIndex);

					if ( a['dringlichkeit'] !== null){
						if ( dringlichkeit === null ) {
							dringlichkeit = a['dringlichkeit'] as number;
						}
						else if ( a['dringlichkeit'] < dringlichkeit ){
							dringlichkeit = a['dringlichkeit'] as number;
						}
					}	

				}
			});
		});

		setlog && console.log("baumZustandDrawer:React.FC newAttributeIndex baumkontrolle", props.feature?.get('index'), attribute['kontrolle']);
		const baumSchemaArray = env.baum!.attribute;
		props.feature?.set('kontrolle',attribute['kontrolle']);
		props.feature?.set('dringlichkeit',dringlichkeit);
		updatebyPkeyPG('baum','baum', props.feature?.get('index'), baumSchemaArray,{'kontrolle': attribute['kontrolle'],'dringlichkeit': dringlichkeit},getAccessTokenSilently,() => {});


		setlog && console.log("baumZustandDrawer:React.FC newAttributeIndex", props.zustaende);
		props.setZustaende(prevZustaende => {
			return prevZustaende.map(zustand => {
			  if (zustand.index < 1 ) {
				let updatedAttributes: Partial<any> = {};

				updatedAttributes = Object.assign(updatedAttributes, {'index':index});
				setlog && console.log("baumZustandDrawer:React.FC newAttributeIndex", updatedAttributes);
				zustandSchemaArray.forEach((a) => {
					if ( a.u && isKeyZustand(a.k)  ){						
						updatedAttributes = Object.assign(updatedAttributes, {[a.k]:attribute[a.k]})
					}
				});
				setlog && console.log("baumZustandDrawer:React.FC newAttributeIndex", updatedAttributes);
				return { ...zustand, ...updatedAttributes};
			  }
			  return zustand;
			});
		});
    };

	const updateAttributeIndex = (index:number) =>{
        setlog && console.log("baumZustandDrawer:React.FC updateAttributeIndex", index);
		
		props.setZustaende(prevZustaende => {
			return prevZustaende.map(zustand => {
			  if (zustand.index === index) {
				let updatedAttributes: Partial<any> = {};

				zustandSchemaArray.forEach((a) => {
					if ( a.u && isKeyZustand(a.k)  ){						
						updatedAttributes = Object.assign(updatedAttributes, {[a.k]:attribute[a.k]})
					}
				});
				return { ...zustand, ...updatedAttributes};
			  }
			  return zustand;
			});
		});
    };

	const deleteAttributeIndex = () =>{
        setlog && console.log("baumZustandDrawer:React.FC deleteAttributeIndex");
		if (props.zustaende) {
            if (props.zustaende.length === 1) {
				closeZustandDrawer();
			}
			else{
				setOptions(options?.filter(item => item.value !== count.pos));
				props.setZustaende(props.zustaende.filter(item => item.index !== count.index));
				const z:Zustand = props.zustaende[0];
				setCount(c => ({index: z['index'] as number, pos: 0}));
			}
		}
    };

    const dboSave = () =>{
        setlog && console.log("baumZustandDrawer:React.FC dboSave ", count.index);
		setChanged(false);
		//attributeListe.map((a) => a.u && (body[a.k] = attribute[a.k]));
		//zustandSchemaArray.forEach((a) => {
		//	setlog && console.log("baumZustandDrawer:React.FC dboSave ", a.k ,attribute[a.k]);
		//});
		
		if ( count.index<1 ){
			newwithFKeyPG('baumzustand', 'baum', 'baumid', props.feature!.get('index') as number, zustandSchemaArray, attribute, getAccessTokenSilently, newAttributeIndex);
		}
		else {
			updatebyPkeyPG('baumzustand','baum', count.index, zustandSchemaArray,attribute,getAccessTokenSilently,updateAttributeIndex);
		}

    };

    const closeZustandDrawer = () =>{
        setlog && console.log("baumZustandDrawer:React.FC closeZustandDrawer");
		changed ? 
			modal.confirm({
				title: 'Die veränderten Daten wurden noch nicht gespeichert!',
				content: 'Möchten Sie den Dialog wirklich schließen?',
				okText: "...go!",
				okType: "danger",
				cancelText: "Nein",                
				onOk: () => closeZustandDrawerReally(),
				onCancel: () => {},
			}) : closeZustandDrawerReally();
		};

	const closeZustandDrawerReally = () =>{
		setlog && console.log("baumZustandDrawer:React.FC dboDeleteReally",selectedRowKeys);
		setChanged(false);
		props.closeZustandDrawer();
	};
	
    const dboDeleteReally = () =>{
        setlog && console.log("baumZustandDrawer:React.FC dboDeleteReally");
		if ( count.index<1 ){
			closeZustandDrawerReally();
		}
		else {
			deleteAttributePG('baumzustand','baum', count.index, getAccessTokenSilently, deleteAttributeIndex);
		}
    };

	const changeString = (key:string, value:string) =>{
        setlog && console.log("baumDrawer:React.FC changeString", value);
		setChanged(true);
        setAttribute( currentState => { return {...currentState, [key]:value}});
    };

	const changeNumber = (key:string, value:number| null) =>{
        setlog && console.log("baumDrawer:React.FC changeString", value);
		setChanged(true);
        setAttribute( currentState => { return {...currentState, [key]:value}});
    };	
    
    const changeBoolean = (key:string, value:boolean) =>{
        //setlog && console.log("baumDrawer:React.FC changeString", value);
		setChanged(true);
        setAttribute( currentState => { return {...currentState, [key]:value}});
    };	

	const changeKontrolle = (value: number) => {
		setlog && console.log("baumDrawer:React.FC changeKontrolle", value);

        changed ? 
            modal.confirm({
                title: 'Die veränderten Daten wurden noch nicht gespeichert!',
                content: 'Möchten Sie den Datensatz wirklich verlassen?',
                okText: "...go!",
                okType: "danger",
                cancelText: "Nein",                
                onOk: () => changeKontrolleReally(value),
                onCancel: () => {},
            }) : changeKontrolleReally(value);
	};		

	const changeKontrolleReally = (value: number) => {			
		if (props.zustaende) {
            if (props.zustaende.length > 0) {			
				const z:Zustand = props.zustaende[value];
				setCount(c => ({index: z.index  as number, pos: value}));
			}
		}				
	};	

	const closeSchadenDrawer = () =>{
		setlog && console.log("baumZustandDrawer:React.FC closeSchadenDrawer");
		if ( schaden.length > 0 ){
			if ( selSchaden !== -1 ){
				setlog && console.log("baumZustandDrawer:React.FC closeSchadenDrawer", schaden[selSchaden].index);
				if ( schaden[selSchaden].index === -1 ) {
					setSchaden(schaden.slice(0,-1));
				}
			}
		}
		setSelSchaden(-1);
	};

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setlog && console.log("baumZustandDrawer:React.FC onSelectChange",newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	interface DataType {
		key: React.Key;
		schaden: string;
		umfeld: boolean;
		wurzel: boolean;
		stammfuß: boolean;
		stamm: boolean;
		krone: boolean;
		dringlichkeit: number;
		behoben: string;
	}
	const [dataSource, setDataSource] = useState<DataType[]>([]);

	useEffect(() => {
		setlog && console.log("baumZustandDrawer:React.FC useEffect.[schaden]", schaden);
		//count.pos>=0  && updateZustand(count.pos);
		const data: DataType[] = [];

		schaden.forEach((a) => {
			//setlog && console.log("baumZustandDrawer:React.FC useEffect.[schaden]", a);
			data.push({
				key: a.index as number, 
				schaden: a.schaden as string, 
				umfeld: a.umfeld as boolean, 
				wurzel: a.wurzel as boolean, 
				stammfuß: a.stammfuß as boolean, 
				stamm: a.stamm as boolean, 
				krone: a.krone as boolean, 
				dringlichkeit: a.dringlichkeit as number, 
				behoben: a.behoben as string
			});
		});
		setDataSource(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schaden]);	
	  
	const columns: ColumnsType<DataType> = [
		{
		  title: 'Schaden',
		  dataIndex: 'schaden',
		  key: 'schaden',
		},
		{
			title: <Tooltip title="Umfeld"><div style={{ textAlign: 'center' }}><RadarChartOutlined /></div></Tooltip>,
			dataIndex: 'umfeld',
			key: 'umfeld',
			render: (value: boolean) => (
			  <div style={{ textAlign: 'center' }}>
				{value ? <CheckOutlined style={{ color: 'green' }} /> : <MinusOutlined style={{ color: 'grey' }} />}
			  </div>
			),
		},
		{
			title: <Tooltip title="Wurzel"><div style={{ textAlign: 'center' }}><ForkOutlined style={{ transform: 'rotate(180deg)' }}/></div></Tooltip>,
			dataIndex: 'wurzel',
			key: 'wurzel',
			render: (value: boolean) => (
			  <div style={{ textAlign: 'center' }}>
				{value ? <CheckOutlined style={{ color: 'green' }} /> : <MinusOutlined style={{ color: 'grey' }} />}
			  </div>
			),
		},		
		{
			title: <Tooltip title="Stammfuß"><div style={{ textAlign: 'center' }}><UnderlineOutlined /></div></Tooltip>,
			dataIndex: 'stammfuß',
			key: 'stammfuß',
			render: (value: boolean) => (
			  <div style={{ textAlign: 'center' }}>
				{value ? <CheckOutlined style={{ color: 'green' }} /> : <MinusOutlined style={{ color: 'grey' }} />}
			  </div>
			),
		},						 
		{
			title: <Tooltip title="Stamm"><div style={{ textAlign: 'center' }}><PauseOutlined /></div></Tooltip>,
			dataIndex: 'stamm',
			key: 'stamm',
			render: (value: boolean) => (
			  <div style={{ textAlign: 'center' }}>
				{value ? <CheckOutlined style={{ color: 'green' }} /> : <MinusOutlined style={{ color: 'grey' }} />}
			  </div>
			),
		},				
		{
			title: <Tooltip title="Korne"><div style={{ textAlign: 'center' }}><CrownOutlined /></div></Tooltip>,
			dataIndex: 'krone',
			key: 'krone',
			render: (value: boolean) => (
			  <div style={{ textAlign: 'center' }}>
				{value ? <CheckOutlined style={{ color: 'green' }} /> : <MinusOutlined style={{ color: 'grey' }} />}
			  </div>
			),
		},				
		{
		  title: <Tooltip title="Dringlichkeit"><div style={{ textAlign: 'center' }}><DashboardOutlined /></div></Tooltip>,
		  dataIndex: 'dringlichkeit',
		  key: 'dringlichkeit',
		  render: (text: React.ReactNode) => <div style={{ textAlign: 'center' }}>{text}</div>,
		},
		{
		  title: <Tooltip title="Behoben"><div style={{ textAlign: 'center' }}><CheckCircleOutlined /></div></Tooltip>,
		  dataIndex: 'behoben',
		  key: 'behoben',
		  render: (value: boolean) => (
			<div style={{ textAlign: 'center' }}>
			  {value ? <CheckOutlined style={{ color: 'green' }} /> : <MinusOutlined style={{ color: 'grey' }} />}
			</div>
		  ),
		},
	];	

	const renderNeuerSchaden = () => {
		return (
		  <Button icon={<BugOutlined />} onClick={() => neuerSchaden()}>Neuer Schaden</Button>
		);
	};

	return (
		<div> {contextHolder}
			<Drawer
				title={count.index<1 ? "Baumzustand - NEU!" : "Baumzustand " + count.index}
				className="ggw-baum-zustand"
				placement="right"
				open={!!props.zustaende?.length}
				onClose={closeZustandDrawer}
				mask={false}
				footer={
					isAuthenticated && (
						<Row gutter={[8,8]} >
							<Col span={12}>
								<Button icon={<SaveOutlined />} onClick={dboSave} disabled={!changed} type="primary" block>Speichern</Button>		
							</Col>
							<Col span={12}>
								<Popconfirm 
									onConfirm={dboDeleteReally}
									title={count.index<1 ? "Der Zustand ist noch nicht gespeichert!" : "Wollen Sie den Zustand wirklich löschen?"} 
									icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
									okText="...go!"
									okType="danger"
									cancelText={count.index<1 ? "Zurück" : "Nein"}
								>
									<Button icon={<DeleteOutlined />} type="primary" block>{count.index<1 ? "Abbrechen" : "Löschen"}</Button>
								</Popconfirm>						
							</Col>
						</Row>
					)
				}                                
			>
				<Form layout="vertical">
					<Row gutter={[8,8]} >					
						<Col span={24}>						
							<Form.Item key='kontrolle'style={{ marginBottom: 4 }} label='Kontrolle'>
								<Select
									value={count.pos}
									style={{ width: '100%' }}
									onChange={changeKontrolle}
									options={options}
								/>
							</Form.Item>

							{
								zustandSchemaArray.map((a,i) => (
									(a.s && (
										(a.t === 'boolean' &&
											<Checkbox key={'input'+i} name={a.k} checked={attribute[a.k]} onChange={e => changeBoolean(a.k,e.target.checked)} style={{ marginTop: 6, marginBottom: 4 }}>
												{a.k.charAt(0).toUpperCase() + a.k.slice(1)}
											</Checkbox>
										) || (a.t === "number" &&
											<Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.k.charAt(0).toUpperCase() + a.k.slice(1)}>
												<InputNumber key={'input'+i} name={a.k} value={attribute[a.k]} style={{ width: '100%' }} onChange={(value: number | null) => changeNumber(a.k,value)} />
											</Form.Item>										
										) || (
											<Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.k.charAt(0).toUpperCase() + a.k.slice(1)}>
												<Input key={'input'+i} name={a.k} value={attribute[a.k]} onChange={(e: ChangeEvent<HTMLInputElement>) => changeString(a.k,e.currentTarget.value)} />
											</Form.Item>
										)
									))
								))
							}
				
							{
								count.index<1 ? <Table
									style={{ marginTop: 20, width: '100%' }}
									rowSelection={rowSelection}
									columns={columns} 
									dataSource={dataSource}
									onRow={(_record: any , rowIndex: any) => {
										return {
											onClick: () => {}, // click row
											onDoubleClick: () => {}, // double click row
											onContextMenu: () => {}, // right button click row
											onMouseEnter: () => {}, // mouse enter row
											onMouseLeave: () => {}, // mouse leave row
										};
									}} 
									/> : <Table
									style={{ marginTop: 20, width: '100%' }}
									columns={columns} 
									dataSource={dataSource}
									footer={renderNeuerSchaden}
									onRow={(_record: any , rowIndex: any) => {
										return {
											onClick: () => {setSelSchaden(rowIndex as number)}, // click row
											onDoubleClick: () => {}, // double click row
											onContextMenu: () => {}, // right button click row
											onMouseEnter: () => {}, // mouse enter row
											onMouseLeave: () => {}, // mouse leave row
										};
									}}
								/>  							
							}							
						</Col>					
					</Row>
				</Form>
			</Drawer>
			<BaumSchadenDrawer feature={props.feature} schaeden={schaden} setSchaeden={setSchaden} select={selSchaden} closeSchadenDrawer={closeSchadenDrawer} updateKontrolle={updateKontrolle} />
		</div>
	);
};

export { BaumZustandDrawer };
export type { Zustand };
//export const MemoizedbaumZustandDrawer = React.memo(baumZustandDrawer);