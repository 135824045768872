import React, {useContext, useReducer} from 'react';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie'

type SettingsProviderProps = {children: React.ReactNode};
type Action = {type: 'mobile' } | 
              {type: 'cookies' } | 
              {type: 'projection', nextStr: string} | 
              {type: 'digi', nextStr: string} |
              {type: 'baumbez' } | 
              {type: 'baumplan', nextNum: number } | 
              {type: 'baumsize', nextNum: number } | 
              {type: 'nmobplan', nextNum: number } |
              {type: 'strassenschadenplan', nextNum: number } | 
              {type: 'testStr', nextStr: string};
type Dispatch = (action: Action) => void;
type Settings = {mobile: boolean, cookies: boolean, projection: string, digi: string, baumbez: boolean, baumsize: number, baumplan: number , nmobplan: number, strassenschadenplan: number, testStr: string};

const SettingsContext = React.createContext<
    {settings: Settings; setSettings: Dispatch} | undefined
  >(undefined);

const settingsReducer = (settings: Settings, action: Action) => {  
  switch (action.type) {
    case 'mobile': {
      settings.cookies ? setCookie('mobile',!settings.mobile, { secure: true,  sameSite: 'none',  expires: 100 }) : removeCookie('mobile');
      return {
        ...settings,
        mobile: !settings.mobile, 
      };
    }  
    case 'projection': {
      //console.log(action.nextStr);
      settings.cookies ? setCookie('projection',action.nextStr, { secure: true,  sameSite: 'none',  expires: 100 }) : removeCookie('projection');
      return {
        ...settings,
        projection: action.nextStr,
      };
    }    
    case 'digi': {
      settings.cookies ? setCookie('digi',action.nextStr, { secure: true,  sameSite: 'none',  expires: 100 }) : removeCookie('digi');
      return {
        ...settings,
        digi: action.nextStr,
      };
    }
    case 'cookies': {
      //console.log("settingsReducer :",settings.cookies);
      if ( settings.cookies === true ){
        removeCookie('cookies');
        removeCookie('mobile');
        removeCookie('projection');
        removeCookie('digi');
        removeCookie('baumbez');
        removeCookie('baumplan');
        removeCookie('baumsize');
        removeCookie('nmobplan');
        removeCookie('strassenschadenplan');
      }
      else {
        setCookie('cookies','true');
        setCookie('mobile',settings.mobile, { secure: true,  sameSite: 'none',  expires: 100 });
        setCookie('projection',settings.projection, { secure: true,  sameSite: 'none',  expires: 100 });
        setCookie('digi',settings.digi, { secure: true,  sameSite: 'none',  expires: 100 });
        setCookie('baumbez',settings.mobile, { secure: true,  sameSite: 'none',  expires: 100 });
        setCookie('baumplan',settings.baumplan, { secure: true,  sameSite: 'none',  expires: 100 });
        setCookie('baumsize',settings.baumsize, { secure: true,  sameSite: 'none',  expires: 100 });
        setCookie('nmobplan',settings.nmobplan, { secure: true,  sameSite: 'none',  expires: 100 });
        setCookie('strassenschadenplan',settings.strassenschadenplan, { secure: true,  sameSite: 'none',  expires: 100 });
      }
      return {
        ...settings,
        cookies: !settings.cookies, 
      };
    }
    case 'baumbez': {
      settings.cookies ? setCookie('baumbez',!settings.baumbez, { secure: true,  sameSite: 'none',  expires: 100 }) : removeCookie('baumbez');
      return {
        ...settings,
        baumbez: !settings.baumbez, 
      };
    }        
    case 'baumplan': {
      settings.cookies ? setCookie('baumplan',action.nextNum, { secure: true,  sameSite: 'none',  expires: 100 }) : removeCookie('baumplan');
      return {
        ...settings,
        baumplan: action.nextNum
      };
    }
    case 'baumsize': {
      settings.cookies ? setCookie('baumsize',action.nextNum, { secure: true,  sameSite: 'none',  expires: 100 }) : removeCookie('baumsize');
      return {
        ...settings,
        baumsize: action.nextNum
      };
    }
    case 'nmobplan': {
      settings.cookies ? setCookie('nmobplan',action.nextNum, { secure: true,  sameSite: 'none',  expires: 100 }) : removeCookie('nmobplan');
      return {
        ...settings,
        nmobplan: action.nextNum
      };
    }
    case 'strassenschadenplan': {
      settings.cookies ? setCookie('strassenschadenplan',action.nextNum, { secure: true,  sameSite: 'none',  expires: 100 }) : removeCookie('strassenschadenplan');
      return {
        ...settings,
        strassenschadenplan: action.nextNum
      };
    }    
    case 'testStr': {
      return {
        ...settings,
        testStr: action.nextStr
      };
    }        
    default: {
      throw new Error('Unhandled action type');
      //throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
};

const SettingsProvider = ({children}: SettingsProviderProps) => {  

  let cookies:string|undefined;
  cookies = getCookie('cookies');
  //console.log("getCookie :",cookies);

  let mobile:string|undefined;
  mobile = getCookie('mobile');

  let projection:string|undefined;
  projection = getCookie('projection');
  //console.log("SettingsProvider :",projection);
  
  let digi:string|undefined;
  digi = getCookie('digi');

  let baumbez:string|undefined;
  baumbez = getCookie('baumbez');

  let baumplan:string|undefined;
  baumplan = getCookie('baumplan');  

  let nmobplan:string|undefined;
  nmobplan = getCookie('nmobplan');    

  let strassenschadenplan:string|undefined;
  strassenschadenplan = getCookie('strassenschadenplan');      

  let baumsize:string|undefined;
  baumsize = getCookie('baumsize');    

  const initialState: Settings = {
    cookies: cookies === 'true' ?  true : false,
    mobile: mobile === 'true' ?  true : false, 
    projection: projection === 'EPSG:4326' ? 'EPSG:4326' : 'EPSG:25832',
    digi: digi === undefined ? '' : digi,
    baumbez: baumbez  === 'true' ?  true : false,
    baumsize: baumsize === undefined ? 2 : Number(baumsize), 
    baumplan: baumplan === undefined ? 1 : Number(baumplan), 
    nmobplan: nmobplan === undefined ? 2 : Number(nmobplan),
    strassenschadenplan: strassenschadenplan === undefined ? 1 : Number(strassenschadenplan),  
    testStr: ''
  };

  const [settings, setSettings] = useReducer(settingsReducer, initialState);
  const value = {settings, setSettings};
  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

const useSettings = () => {  
  //custom-react-hook
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};

export {SettingsProvider,useSettings};