import React, {useContext, useEffect, useRef, useState} from "react";
import 'antd/dist/reset.css';

import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

import OlImageLayer from 'ol/layer/Image';
import OlSourceImageStatic from 'ol/source/ImageStatic';

import Feature from 'ol/Feature';
import { buffer } from 'ol/extent';

import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Pagination, 
	Drawer 
} from 'antd';

import { DownloadOutlined } from '@ant-design/icons';

import MapContext from "../Map/MapContext";
import {useSettings} from './SettingsContext';
import { env } from './Env';

import { useAuth0 } from '@auth0/auth0-react';

interface hthBplanProps {
    url: string;
    closeDrawer: () => void;
    featuresPos: number;
    featuresCount: number;
    setFeaturesPos: React.Dispatch<React.SetStateAction<number>>;    
    feature: Feature | null;
}

const BPlanDrawer:React.FC<hthBplanProps> = (props) => {
    const setlog = false;
    setlog && console.log("BPlanDrawer:React.FC")

    const map = useContext(MapContext);
    const {settings} = useSettings();
    const [disabled, setDisabled] = useState(true);
    const [touched, setTouched] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null);
    
	const imageLayerRef = useRef<OlImageLayer<OlSourceImageStatic> | null>(null);
    const imageSourceRef = useRef<OlSourceImageStatic | null>(null);	
    
    const {
        isAuthenticated,
        user
    } = useAuth0();      

    useEffect(() => {
		setlog && console.log("BPlanDrawer:React.FC useEffect[]");

        var imageSource = new OlSourceImageStatic({
            url: '',
            projection: 'EPSG:25832',
            imageExtent: []
        });

        const imageLayer = new OlImageLayer({
            source: imageSource,
            opacity: 0.6,
        });
        
        imageLayer.set('name', '_Overlay');
        imageLayer.set('label', '');
        imageLayer.set('digi', false);
        imageLayer.set('select', false);							

        map?.addLayer(imageLayer);

        imageLayerRef.current = imageLayer;
        imageSourceRef.current = imageSource;

        return () => {
            map?.removeLayer(imageLayer);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);   
    
    useEffect(() => {
		//setlog && console.log("BPlanDrawer:React.FC useEffect[props.feature]");
        if (props.feature) {
            if (props.feature.get('label') === 'bplan') {
                setlog && console.log("BPlanDrawer:React.FC useEffect[props.feature]", props.feature);
                imageSourceRef.current  = new OlSourceImageStatic({
                    url: "./BPlan/" + props.feature.get('Raster_Raster'),
                    projection: 'EPSG:25832',
                    imageExtent: [props.feature.get('MinX'), props.feature.get('MinY'), props.feature.get('MaxX'), props.feature.get('MaxY')]
                });
                imageLayerRef.current!.setSource(imageSourceRef.current);

                const geometry = props.feature.getGeometry();
                const extent = geometry!.getExtent();
                const extentB = buffer(extent, 50);
                map?.getView().fit(extentB, {
                    duration: 2000
                });                                        
            }
            else{
                imageLayerRef.current!.setSource(null);
            }
        }
        else{
            imageLayerRef.current!.setSource(null);
        }        
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.feature]);   

    const closeDrawer = () =>{
        setlog && console.log(props.feature);
        //setShowDrawer(!showDrawer);
        props.closeDrawer();
    };

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        setlog && console.log("Start");
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };    

	const changeFeature = (page: number, pageSize: number) => {
        setlog && console.log("BPlanDrawer:React.FC changeFeature", page, pageSize);
        props.setFeaturesPos(page);
    };
    
    const title = (
        <Row gutter={[8,8]} >
            <Col span={12}>
                <div style={{ fontSize: '1.2em' }}>
                <a id="bplan_mailto" href={"mailto:" + (isAuthenticated ? user?.email : ( env.bplan!.mail ? 'bplan-' + env.mailto : ''))  + "?Subject=Bebauungsplan: " +props.feature?.get('index')+ "&body=Hier der Link zum Objekt " + props.url + "%26search=bplan," +props.feature?.get('index')}>
                        {"Bebauungsplan "+props.feature?.get('index')}
                    </a>
                </div>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination simple size='small' current={props.featuresPos} pageSize={1} total={props.featuresCount} onChange={changeFeature}/>
            </Col>
        </Row>
    );
    
    const body = (
        <Form layout="vertical">
            <Row gutter={[8,8]} >					
                <Col span={24}>
                    <Form.Item style={{ marginBottom: 4 }} label="Nummer">
                        <Input name='nummer' value={props.feature?.get('nummer')} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 4 }} label="Bezeichnung">
                        <Input name='bezeichnung' value={props.feature?.get('bezeichnung')} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 4 }} label="Rechtsgültig seit">
                        <Input name='rechtsgueltig' value={props.feature?.get('rechtsgueltig')} />
                    </Form.Item> 
                    <Button type="link" style={{ marginTop: 20 }} href={"../BPlanO/"+props.feature?.get('Original_Pfad')} target="_blank" icon={<DownloadOutlined />} block>Originalplan</Button>
                </Col>
            </Row>
        </Form>            
    );
    
    if ( !settings.mobile ){
        return ( 
            <Drawer
                title={title}
                className="ggw-bplan"
                placement="right"
                open={props.feature?.get('label')==='bplan'}
                onClose={closeDrawer}
                mask={false}               	 
            >
                {body}
            </Drawer>        
        );
    }
    else {
        return ( 
            <Modal
                title={ <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}                                    
                    onMouseOver={() => {disabled && setDisabled(false);}}
                    onMouseOut={() => {setDisabled(true);}}
                    onTouchStart={() => {touched ? setDisabled(true) : setTouched(false);}}
                    onTouchEnd={() => {disabled ? setTouched(true) : setDisabled(false);}}
                    onTouchCancel={() => {!disabled && setDisabled(true);}}                    
                    >
                        {title}
                    </div>
                }
                open={props.feature?.get('label')==='bplan'}
                onCancel={closeDrawer}
                footer={[]}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}                   
            >
                {body}
            </Modal>
        );
    }
};


export default BPlanDrawer;