import React, { useContext, useEffect, useRef } from 'react';

//Layer
import OlTileLayer from 'ol/layer/Tile';
//Source
import OlTileWMSSource from 'ol/source/TileWMS';

import { env } from '../Components/Env'; 

import MapContext from "../Map/MapContext";

interface AlkisLayerProps {
}

const AlkisLayer: React.FC<AlkisLayerProps> = (props) => {
	const setlog = true;
    setlog && console.log("AlkisLayer:React.FC");

	const map = useContext(MapContext);
	const tileLayerRef = useRef<OlTileLayer<OlTileWMSSource> | null>(null);
    //const tileWMSSourceRef = useRef<OlTileWMSSource | null>(null);    
    
    useEffect(() => {
		setlog && console.log("AlkisLayer:React.FC useEffect[]");    
 
		const alkisLayer = new OlTileLayer ({
			source: new OlTileWMSSource({
				url: env.alkis.url+env.alkis.wms,
				params: {'LAYERS': env.alkis.schema+env.alkis.layer,'TILED': true},
				projection: 'EPSG:25832',
				serverType: env.alkis.serverType,
				attributions: ['<a href="https://gds.hessen.de">GDS-Hessen</a> ']
			}),
		});

		alkisLayer.set('name', 'Katasterkarte (ALKIS)');
		alkisLayer.set('label', 'alkis');
		alkisLayer.set('type', 'WPS');
		alkisLayer.set('digi', true);
		alkisLayer.set('allow_digi', false);
		alkisLayer.set('select', true);
		alkisLayer.set('allow_select', true);

        map?.addLayer(alkisLayer);
		tileLayerRef.current = alkisLayer;

        return () => {
            map?.removeLayer(alkisLayer);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default AlkisLayer;
