import React, { useContext, useEffect, useState } from "react";

import OlLayer from 'ol/layer/Layer';
import OlSourceVector  from 'ol/source/Vector';
import OlFeature from 'ol/Feature';

//import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import 'antd/dist/reset.css';

import { QuestionOutlined } from '@ant-design/icons';

import SimpleButton from "./UI/SimpleButton"
import MapContext from "../Map/MapContext";

//import { useAuth0 } from '@auth0/auth0-react';
import { getWFSwithFilter } from '../Postgres/pg';
import { env /*, getAttribute */} from '../Components/Env';

import {
    Button,
    Card,
    Col,
    Form,
    Row,
    InputNumber,
    Select,
	Drawer 
} from 'antd';
//import { Point, LineString, MultiPolygon } from "ol/geom";

//import { env } from '../Components/Env';  

interface settingsProps {
    findFeatures:(featuresFound:OlFeature[]) => any;
    style: React.CSSProperties;
};

const Search:React.FC<settingsProps> = (props) => {  
    const [ search, setSearch ] = useState<Boolean>(false);
 
    const openCloseSearch = () =>{       
        //console.log('openSearch');
        //!search && props.setFeature(null);
        setSearch((prevState) => { return !prevState });
    };  

    return (
        <div>            
            <SearchDrawer open={search} closeDrawer={openCloseSearch} findFeatures={props.findFeatures}/>
            <SimpleButton
                type="default"
                style={props.style} 
                shape="circle"
                onClick={openCloseSearch}
                tooltip="Suchen"
                tooltipPlacement="right"
                icon={<QuestionOutlined />}
            />          
        </div>
    );
};

interface settingsDrawerProps {
    open: Boolean;
    findFeatures:(featuresFound:OlFeature[]) => any;
    closeDrawer: () => void;
};

const SearchDrawer:React.FC<settingsDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("SearchDrawer:React.FC")
    const map = useContext(MapContext);

    const [changed, setChanged] = useState(false);
    const [layers, setLayers] = useState<{ name:string, label: string }[]>([]);
    const [layer, setLayer] = useState<string | null>(null);

    const [getSearchList, setGetSearchList] = useState(false);
    const [searchList, setSearchList] = useState<{ label:string, value: number }[]>([]);

    const [index, setIndex] = useState<number | null>(null);

    const [zaehler, setZaehler] = useState<number | null>(null);
    const [nenner, setNenner] = useState<number | null>(null);
    const [flur, setFlur] = useState<number | null>(null);
    const [gem, setGem] = useState<number | null>(null);

    //setlog && console.log("SearchDrawer ", map?.getAllLayers());

    useEffect(() => {
        setlog && console.log("SearchDrawer:React.FC useEffect.[] ");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    

    const closeDrawer = () =>{
        setChanged(false);
        setGetSearchList(false);
        setIndex(null);
        setSearchList([]);     
        props.closeDrawer();
    };

    const search = () =>{
        map?.getAllLayers().forEach((l) => {
            if ( l.get('label') ){
                if ( l.get('label') === layer ){
                    if (l.getSource() instanceof OlSourceVector) {
                        const targetSource:OlSourceVector = l.getSource() as OlSourceVector;
                        setlog && console.log("SearchDrawer:React.FC source", targetSource);
                        targetSource.forEachFeature((feature) => {
                            //setlog && console.log("SearchDrawer:React.FC feature", feature);
                            const properties = feature.getProperties();
                            //setlog && console.log("SearchDrawer:React.FC properties", properties);
                            if ( index ){
                                if ( properties.index === index ) {
                                    setlog && console.log("SearchDrawer:React.FC properties", properties);
                                    closeDrawer();
                                    if ( feature ) {
                                        //console.log("SearchDrawer:React.FC source", feature);
                                        feature.set('label', layer);
                                        props.findFeatures([feature]);
                                    }
                                }
                            }
                        });
                    }
                    else {
                        if ( l.get('label') === 'alkis' ){
                            let find:string = '';
                            if ( zaehler ) {
                                find += 'zaehler='+zaehler;
                            }
                            if ( nenner ) {
                                if (find.length > 0){
                                    find += ' AND ';
                                }
                                find += 'nenner='+nenner;
                            }                                            
                            if ( flur ) {
                                if (find.length > 0){
                                    find += ' AND ';
                                }
                                find += 'flur='+flur;
                            }                
                            if ( gem ) {
                                if (find.length > 0){
                                    find += ' AND ';
                                }
                                find += 'gemarkung='+gem;
                            }                     
                                                        
                            if (find.length > 0){
                                setlog && console.log("SearchDrawer:React.FC properties", find);
                                getWFSwithFilter(
                                    env.alkis.url+env.alkis.wfs,
                                    env.alkis.schema+env.alkis.filter,
                                    env.alkis.layer,
                                    find,
                                    props.findFeatures);
                            }
                        }                 
                    }
                }
            }
        });
    };

    const mapFilter : Array<OlLayer> | undefined = map?.getAllLayers().filter((layer) => {
        return layer.get('name').slice(0,1) !== '_' && layer.get('allow_select') === true;
    });

    useEffect(() => {
        setlog && console.log("SearchDrawer:React.FC useEffect.[] ");
        getEbenen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);    


    const getEbenen = () =>{
        if ( layers.length === 0 ){
            setlog && console.log("SearchDrawer:React.FC getEbenen");
            const o: { name:string, label: string }[] = [];
            mapFilter?.map (layer => {           
                o.push({
                    name: layer.get('name'),
                    label: layer.get('label')
                });
                return true;            
            });
            setLayers(o);
            o.length === 1 && setLayer(o[0].label);
            setGetSearchList(false);
        } 
    };

    const changeEbenen = (_value: string, option:any) => {
		setlog && console.log("SearchDrawer:React.FC changeEbenen", option);
        setChanged(false);
        setIndex(null);
        setZaehler(null);
        setNenner(null);
        setFlur(null);
        setGem(null);           
        setLayer(option.value);
	};

    const changeZ = (value:number| null) =>{
        setlog && console.log("baumDrawer:React.FC changeZ", value);
        setZaehler( value );
        setChanged( true );
    };

    const changeN = (value:number| null) =>{
        setlog && console.log("baumDrawer:React.FC changeN", value);
        setNenner( value );
        setChanged( true );
    };
    
    const changeF = (value:number| null) =>{
        setlog && console.log("baumDrawer:React.FC changeF", value);
        setFlur( value );
        setChanged( true );
    };
    
    const changeG = (value:number| null) =>{
        setlog && console.log("baumDrawer:React.FC changeG", value);
        setGem( value );
        setChanged( true );
    };
    
    const changeIndex = (value:number| null) =>{
        setlog && console.log("baumDrawer:React.FC changeIndex", value);
        setIndex( value );
        setChanged( true );
    };    
    
    const getSearch = (attribut: string) =>{
        setlog && console.log("SearchDrawer:React.FC getSearch");
        const o: { label:string, value: number }[] = [];

        map?.getAllLayers().forEach((l) => {
            if ( l.get('label') ){
                if ( l.get('label') === layer ){
                    if (l.getSource() instanceof OlSourceVector) {
                        const targetSource:OlSourceVector = l.getSource() as OlSourceVector;
                        setlog && console.log("SearchDrawer:React.FC source", targetSource);
                        targetSource.forEachFeature((feature) => {
                            const properties = feature.getProperties();     
                            if ( properties[attribut]) {
                                o.push({
                                    label: properties[attribut],
                                    value: properties.index
                                });
                            }
                        });
                    }
                }
            }            
        });
        setSearchList(o);
        setGetSearchList(true); 
    };    

    const changeSearch = (value: number, _option:any) => {
		setlog && console.log("SearchDrawer:React.FC changeSearch", value);
        setChanged(true);
        setIndex(value);
	};
 
    return ( 
        <Drawer
            title={"Suchen"}
            className="ggw-search"
            placement="right"
            open={props.open?.valueOf()}
            onClose={closeDrawer}
            mask={false}
            footer={
                <Row gutter={[8,8]} >
                    <Col span={12}>
                        <Button onClick={search} disabled={!changed} type="primary" block>Suchen</Button>	
                    </Col>
                    <Col span={12}>
                        <Button onClick={closeDrawer} type="primary" block>Abbrechen</Button>
                    </Col>
                </Row>
            }                                           		
        >
            <Form layout="vertical">
                <Row gutter={[8,8]} >					
                    <Col span={24}>
                        <Card title='Ebenen' bordered={false}>
                            <Select
                                showSearch
                                key='layer_search'
                                style={{ width: '100%' }}
                                placeholder={'Bitte wählen Sie eine Ebene aus'}
                                value={layer}
                                optionFilterProp="children"
                                onFocus={getEbenen}
                                onChange={changeEbenen}
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                options={layers.map((layer) => ( {label: layer.name, value: layer.label}))}
                            />
                        </Card>
                        {( layer === 'baum' && 
                            <Card title='Attribute' bordered={false} style={{ marginTop: 8 }}>
                                <Form.Item key='index_s' style={{ marginBottom: 4 }} label='Index'>
                                    <InputNumber key='input_index' value={index} style={{ width: '100%' }} onPressEnter={search} onChange={(value: number | null) => changeIndex(value)} />
                                </Form.Item>
                                <Form.Item key='bezeichnung_s' style={{ marginBottom: 4 }} label='Bezeichnung'>
                                    <Select
                                        showSearch
                                        allowClear
                                        key='bezeichnung_search'
                                        style={{ width: '100%' }}
                                        value={getSearchList ? index : null}
                                        placeholder={'Bitte wählen Sie eine Bezeichnung aus'}                                       
                                        optionFilterProp="children"
                                        onFocus={() => getSearch('bezeichnung')}
                                        onChange={changeSearch}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                        options={searchList.map((obj) => ( {label: obj.label, value: obj.value}))}
                                    />
                                </Form.Item>                                
                            </Card>					                        
                        )}
                        {( layer === 'bplan' && 
                            <Card title='Attribute' bordered={false} style={{ marginTop: 8 }}>
                                <Form.Item key='index_s' style={{ marginBottom: 4 }} label='Index'>
                                    <InputNumber key='input_index' value={index} style={{ width: '100%' }} onPressEnter={search} onChange={(value: number | null) => changeIndex(value)} />
                                </Form.Item>
                                <Form.Item key='bezeichnung_s' style={{ marginBottom: 4 }} label='Bezeichnung'>
                                    <Select
                                        showSearch
                                        allowClear
                                        key='bezeichnung_search'
                                        style={{ width: '100%' }}
                                        value={getSearchList ? index : null}
                                        placeholder={'Bitte wählen Sie eine Bezeichnung aus'}                                       
                                        optionFilterProp="children"
                                        onFocus={() => getSearch('bezeichnung')}
                                        onChange={changeSearch}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                        options={searchList.map((obj) => ( {label: obj.label, value: obj.value}))}
                                    />
                                </Form.Item>                                                                
                            </Card>					                        
                        )}
                        {( layer === 'defi' && 
                            <Card title='Attribute' bordered={false} style={{ marginTop: 8 }}>
                                <Form.Item key='index_s' style={{ marginBottom: 4 }} label='Index'>
                                    <InputNumber key='input_index' value={index} style={{ width: '100%' }} onPressEnter={search} onChange={(value: number | null) => changeIndex(value)} />
                                </Form.Item>
                            </Card>					                        
                        )}                                                                
                        {( layer === 'hth' && 
                            <Card title='Attribute' bordered={false} style={{ marginTop: 8 }}>
                                <Form.Item key='index_s' style={{ marginBottom: 4 }} label='Index'>
                                    <InputNumber key='input_index' value={index} style={{ width: '100%' }} onPressEnter={search} onChange={(value: number | null) => changeIndex(value)} />
                                </Form.Item>
                            </Card>					                        
                        )}                      
                        {( layer === 'nmob' && 
                            <Card title='Attribute' bordered={false} style={{ marginTop: 8 }}>
                                <Form.Item key='index_s' style={{ marginBottom: 4 }} label='Index'>
                                    <InputNumber key='input_index' value={index} style={{ width: '100%' }} onPressEnter={search} onChange={(value: number | null) => changeIndex(value)} />
                                </Form.Item>
                            </Card>					                        
                        )}
                        {( layer === 'tswt' && 
                            <Card title='Attribute' bordered={false} style={{ marginTop: 8 }}>
                                <Form.Item key='index_s' style={{ marginBottom: 4 }} label='Index'>
                                    <InputNumber key='input_index' value={index} style={{ width: '100%' }} onPressEnter={search} onChange={(value: number | null) => changeIndex(value)} />
                                </Form.Item>
                            </Card>					                        
                        )}
                        {( layer === 'strassenschaden' && 
                            <Card title='Attribute' bordered={false} style={{ marginTop: 8 }}>
                                <Form.Item key='index_s' style={{ marginBottom: 4 }} label='Index'>
                                    <InputNumber key='input_index' value={index} style={{ width: '100%' }} onPressEnter={search} onChange={(value: number | null) => changeIndex(value)} />
                                </Form.Item>
                            </Card>					                        
                        )}                        
                        {( layer === 'alkis' && 
                            <Card title='Attribute' bordered={false} style={{ marginTop: 8 }}>
                                <Form.Item key='zaehler' style={{ marginBottom: 4 }} label='Zähler'>
                                    <InputNumber key='input_z' value={zaehler} style={{ width: '100%' }} onPressEnter={search} onChange={(value: number | null) => changeZ(value)} />
                                </Form.Item>
                                <Form.Item key='nenner' style={{ marginBottom: 4 }} label='Nenner'>
                                    <InputNumber key='input_n' value={nenner} style={{ width: '100%' }} onPressEnter={search} onChange={(value: number | null) => changeN(value)} />
                                </Form.Item>
                                <Form.Item key='flur' style={{ marginBottom: 4 }} label='Flur'>
                                    <InputNumber key='input_f' value={flur} style={{ width: '100%' }} onPressEnter={search} onChange={(value: number | null) => changeF(value)} />
                                </Form.Item>
                                <Form.Item key='gem' style={{ marginBottom: 4 }} label='Gemarkung'>
                                    <InputNumber key='input_g' value={gem} style={{ width: '100%' }} onPressEnter={search} onChange={(value: number | null) => changeG(value)} />
                                </Form.Item>

                            </Card>					                        
                        )}                        
					</Col>
                </Row>
            </Form>            
        </Drawer>
    );        
};

export {Search};