import React, {useContext, useEffect, useRef, useState} from "react";
import 'antd/dist/reset.css';

import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

import { Vector as OlVectorLayer } from 'ol/layer';
import { Vector as OlVectorSource } from 'ol/source';

import Feature from 'ol/Feature';
import { Geometry  } from "ol/geom";
import { buffer } from 'ol/extent';
import {Fill, Stroke, Style, Circle} from 'ol/style';

import {
    Col,
    Form,
    Input,
    Modal,
    Pagination,
    Row, 
	Drawer 
} from 'antd';

import MapContext from "../Map/MapContext";
import {useSettings} from './SettingsContext';
import { env } from './Env';

import { useAuth0 } from '@auth0/auth0-react';
    
interface alkisProps {
    url: string;
    closeDrawer: () => void;
    featuresPos: number;
    featuresCount: number;
    setFeaturesPos: React.Dispatch<React.SetStateAction<number>>;    
    feature: Feature | null;
}

const AlkisDrawer:React.FC<alkisProps> = (props) => {
    const setlog = false;
    setlog && console.log("AlkisDrawer:React.FC");
    const map = useContext(MapContext);
    const {settings} = useSettings();

    const [disabled, setDisabled] = useState(true);
    const [touched, setTouched] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null);    

	const vectorLayerRef = useRef<OlVectorLayer<Feature<Geometry>> | null>(null);
    const vectorSourceRef = useRef<OlVectorSource | null>(null);

    const {
        isAuthenticated,
        user
    } = useAuth0();    

    useEffect(() => {
		setlog && console.log("AlkisDrawer:React.FC useEffect[]");

        const selectSource = new OlVectorSource();
        const selectLayer = new OlVectorLayer({
            source: selectSource,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.05)',
                }),
                stroke: new Stroke({
                    color: 'rgb(255,0,0)',
                    width: 4,
                }),
                image: new Circle({
                    radius: 20,
                    fill: new Fill({
                        color: 'rgba(255,0,0,0.3)', 
                    }),
                })			
            })			        
        });
        selectLayer.set('name', '_ALKIS');
        selectLayer.set('label', '');
        selectLayer.set('digi', false);
        selectLayer.set('select', false);

        map?.addLayer(selectLayer);

        vectorLayerRef.current = selectLayer;
        vectorSourceRef.current = selectSource;

        return () => {
            map?.removeLayer(selectLayer);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);   

    useEffect(() => {
		setlog && console.log("AlkisDrawer:React.FC useEffect[props.feature]");
        if (props.feature) {
            vectorSourceRef.current?.forEachFeature( (f) => { vectorSourceRef.current?.removeFeature(f)});
            if (props.feature.get('label') === 'alkis') {
                setlog && console.log("Alkis:React.FC useEffect[props.feature]", props.feature);
                vectorSourceRef.current?.addFeature(props.feature);

                const geometry = props.feature.getGeometry();
                const extent = geometry!.getExtent();
                const extentB = buffer(extent, 50);
                map?.getView().fit(extentB, {
                    duration: 2000
                });                                                                  
            }
        }
        else{
            vectorSourceRef.current?.forEachFeature( (f) => { vectorSourceRef.current?.removeFeature(f)});
        }        
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.feature]);       

    const closeDrawer = () =>{
        setlog && console.log(props.feature);
        props.closeDrawer();
    };

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        setlog && console.log("Start");
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };    

	const changeFeature = (page: number, pageSize: number) => {
        setlog && console.log("AlkisDrawer:React.FC changeFeature", page, pageSize);
        props.setFeaturesPos(page);
    };
    
    const title = (
        <Row gutter={[8,8]} >
            <Col span={12}>
                <div style={{ fontSize: '1.2em' }}>
                    <a id="alis_mailto" href={"mailto:" + (isAuthenticated ? user?.email : ( env.alkis.mail ? 'alkis-' + env.mailto : ''))  + "?Subject=Flurstück: " +props.feature?.get('id')+ "&body=Hier der Link zum Objekt " + props.url + "%26search=alkis," +props.feature?.get('zaehler')+","+props.feature?.get('nenner')+","+props.feature?.get('flur')+","+props.feature?.get('gemarkung')}>
                        {"Flurstück"}
                    </a>
                </div>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination simple size='small' current={props.featuresPos} pageSize={1} total={props.featuresCount} onChange={changeFeature}/>
            </Col>
        </Row>
    );            

    const body = (
        <Form layout="vertical">
            <Row gutter={[8,8]} >					
                <Col span={24}>
                    <Form.Item style={{ marginBottom: 4 }} label="Gemeinde">
                        <Input name='gemeinde' value={props.feature?.get('gemename')} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 4 }} label="Gemarkung">
                        <Input name='gemarkung' value={props.feature?.get('gemarkung')} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 4 }} label="Flur">
                        <Input name='flur' value={props.feature?.get('flur')} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 4 }} label="Zähler">
                        <Input name='zaehler' value={props.feature?.get('zaehler')} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 4 }} label="Nenner">
                        <Input name='nenner' value={props.feature?.get('nenner')} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 4 }} label="Amtliche Fläche (m²)">
                        <Input name='amtflaeche' value={props.feature?.get('amtflaeche')} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 4 }} label="Tatsächliche Nutzung">
                        <Input name='tn' value={props.feature?.get('tn')} />
                    </Form.Item>                         
                </Col>
            </Row>
        </Form>            
    );            

    if ( !settings.mobile ){
        return ( 
            <Drawer
                title={title}
                className="ggw-alkis"
                placement="right"
                open={props.feature?.get('label')==='alkis'}
                onClose={closeDrawer}
                mask={false}		 
            >
                {body}
            </Drawer>        
        );
    }
    else {
        return ( 
            <Modal
                title={ <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}                                    
                    onMouseOver={() => {disabled && setDisabled(false);}}
                    onMouseOut={() => {setDisabled(true);}}
                    onTouchStart={() => {touched ? setDisabled(true) : setTouched(false);}}
                    onTouchEnd={() => {disabled ? setTouched(true) : setDisabled(false);}}
                    onTouchCancel={() => {!disabled && setDisabled(true);}}                    
                    >
                        {title}
                    </div>
                }
                open={props.feature?.get('label')==='alkis'}
                onCancel={closeDrawer}
                footer={[]}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}                   
            >
                {body}
            </Modal>
        );
    }
};


export default AlkisDrawer;