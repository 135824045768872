import React, { useContext } from "react";
import OlView from 'ol/View';
import 'antd/dist/reset.css';

import { HomeOutlined  } from '@ant-design/icons';

import { env } from './Env';
import SimpleButton from "./UI/SimpleButton"
import MapContext from "../Map/MapContext";


interface homeProps {
    style: React.CSSProperties;
};

const Home:React.FC<homeProps> = (props) => {  
    const setlog = false;
    const map = useContext(MapContext);

    const goHome = () =>{       
        setlog && console.log('goHome');
        const view: OlView|undefined  = map?.getView();
        view?.animate({
            center: env.center,
            duration: 2000,
            zoom: env.zoom
          });  
    };   

    return (
        <div>            
            <SimpleButton
                type="default"
                style={props.style} 
                shape="circle"
                onClick={goHome}
                tooltip="nach Hause telefonieren ..."
                tooltipPlacement="right"
                icon={<HomeOutlined  />}
            />            
        </div>
    );
};

export {Home};
