import React, { useContext, useRef, useEffect } from 'react';
import { Vector as OlVectorLayer } from 'ol/layer';
import { Vector as OlVectorSource } from 'ol/source';
import OlGeoJSON from 'ol/format/GeoJSON';
import { Style, Icon } from 'ol/style';
import Feature, { FeatureLike } from 'ol/Feature';
import { Geometry  } from "ol/geom";

import { env } from '../Components/Env'; 
import MapContext from "../Map/MapContext";

interface HthLayerProps {
	setLoading: React.Dispatch<React.SetStateAction<number | null>>;
}

const HthLayer: React.FC<HthLayerProps> = (props) => {
	const setlog = false;
    setlog && console.log("HthLayer:React.FC");

	const map = useContext(MapContext);
	const vectorLayerRef = useRef<OlVectorLayer<Feature<Geometry>> | null>(null);
    const vectorSourceRef = useRef<OlVectorSource | null>(null);	

    useEffect(() => {
		setlog && console.log("HthLayer:React.FC useEffect[]");

		const vectorSource = new OlVectorSource({
			url: env.hth ? env.hth.url : '',
			format: new OlGeoJSON(),
			attributions: env.attributions		
		});
		
		const vectorStyle = (feature: FeatureLike): Style =>{    
			const properties = feature.getProperties();
			const select = properties['select'];

			var style: Style;

			style = new Style({
				image: new Icon({
					scale: [0.08, 0.08],
					anchor: [0.5, 1],
					src: select? 'sel_hth.png' : 'hth.png',
				})
			});
			return style;
		};		

        const vectorLayer = new OlVectorLayer({
            source: vectorSource,
            style: vectorStyle,
        });
		
		vectorLayer.set('name', 'Hundekottütenhalter');
		vectorLayer.set('label', 'hth');
		vectorLayer.set('type', 'Point');
		vectorLayer.set('digi', false);
		vectorLayer.set('allow_digi', false);
		vectorLayer.set('select', true);
		vectorLayer.set('allow_select', true);		

        map?.addLayer(vectorLayer);

		vectorLayerRef.current = vectorLayer;
        vectorSourceRef.current = vectorSource;
		props.setLoading((l) => l ? l - 1 : 1);				

        return () => {
            map?.removeLayer(vectorLayer);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default HthLayer;
