import React, { useContext, useEffect } from 'react';

//Layer
import OlLayerTile from 'ol/layer/Tile';
//Source
import OlTileWMSSource from 'ol/source/TileWMS';

import { env } from '../Components/Env';
import MapContext from "../Map/MapContext";

interface DOPLayerProps {
}

const DOPLayer: React.FC<DOPLayerProps> = (props) => {
	const setlog = false;
    setlog && console.log("DOPLayer:React.FC");

	const map = useContext(MapContext);

    useEffect(() => {
		setlog && console.log("DOPLayer:React.FC useEffect[]");

		const year = env.dop.year.split(";")[0];

		const dopLayer = new OlLayerTile({
			source: new OlTileWMSSource({
				url: env.dop.url,
				params: {'LAYERS': env.dop.layer + year,'TILED': true},
				projection: 'EPSG:25832',
				serverType: env.dop.serverType,
			}), 
			visible: false
		});	

		dopLayer.set('name', 'Luftbilder '+ year);
		dopLayer.set('label', 'dop');
		dopLayer.set('digi', false);
		dopLayer.set('select', false);


        map?.addLayer(dopLayer);

        return () => {
            map?.removeLayer(dopLayer);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};        

export default DOPLayer;