import React, { useContext, useEffect, useRef } from 'react';
import { Vector as OlVectorLayer } from 'ol/layer';
import { Vector as OlVectorSource } from 'ol/source';
import OlGeoJSON from 'ol/format/GeoJSON';
import { Style, Circle, Fill, Stroke, Text, Icon } from 'ol/style';
import Feature, { FeatureLike } from 'ol/Feature';
import { Geometry  } from "ol/geom";

import { env } from '../Components/Env'; 
import MapContext from "../Map/MapContext";
import {useSettings} from '../Components/SettingsContext'; 
import { getAllPG } from '../Postgres/pg';

const name = 'Baum';
const label = 'baum';
const type = 'Point';

const heute = new Date();

interface BaumLayerProps {
	setLoading: React.Dispatch<React.SetStateAction<number | null>>;
	removeFeature: Feature | null;
}

const BaumLayer: React.FC<BaumLayerProps> = (props) => {
	const setlog = false;
    setlog && console.log("BaumLayer:React.FC");

	const map = useContext(MapContext);
	const {settings} = useSettings();
	const vectorLayerRef = useRef<OlVectorLayer<Feature<Geometry>> | null>(null);
    const vectorSourceRef = useRef<OlVectorSource | null>(null);
	const vectorStyleRef = useRef<((feature: FeatureLike, resolution: number) => Style[]) | null>(null);	

    useEffect(() => {
		setlog && console.log("BaumLayer:React.FC useEffect[]");

		const vectorSource = new OlVectorSource({
			format: new OlGeoJSON(),
			attributions: env.attributions		
		});
		
        const vectorLayer = new OlVectorLayer({
            source: vectorSource,
            style: (feature: FeatureLike, resolution: number) => vectorStyleRef.current ? vectorStyleRef.current(feature,resolution) : new Style(),
        });
		
		vectorLayer.set('name', name);
		vectorLayer.set('label', label);
		vectorLayer.set('type', type);
		vectorLayer.set('digi', false);
		vectorLayer.set('allow_digi', true);
		vectorLayer.set('select', true);
		vectorLayer.set('allow_select', true);		

		getAllPG(env.baum!.url,env.baum!.database,env.baum!.schema, env.baum!.tabelle, env.baum!.attribute, vectorSource,() => {props.setLoading((l) => l ? l - 1 : 1)});
		if ( map ){
            setlog && console.log("BaumLayer:React.FC useEffect.[] map");
			map.addLayer(vectorLayer);
		}

		vectorLayerRef.current = vectorLayer;
        vectorSourceRef.current = vectorSource;		

        return () => {
            map?.removeLayer(vectorLayer);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setlog && console.log("BaumLayer:React.FC useEffect.[settings.baumbez] "+ settings.baumbez,settings.baumplan,settings.baumsize);

		vectorStyleRef.current = (feature: FeatureLike, resolution: number) => { 
			const properties = feature.getProperties();
			const select = properties['select'];
			const typ = properties['typ'];
			const bez = properties['bezeichnung'];
			const bewaessern = properties['bewaessern'];
			const jungbaum = properties['jungbaum'];
			const gefaellt = properties['gefaellt'];
			const kontrolle = properties['kontrolle'];
			const dringlichkeit = properties['dringlichkeit'];
			const bewaessert = properties['bewaessert'];
	
			var style: Style;
			var style2: Style;
	
			if ( gefaellt ){
				style = new Style({
					image: new Circle({
						radius: 0.3/resolution,
						fill: new Fill({
							color: select? '#BF0603' : '#583101',
						}),
						stroke: new Stroke({
							color: '#38340E',
							width: 2,
						}),
					})
				})
				return [style];
			}
			else {
				let scale:number;
				let mult:number;
				let opacity:number = 1; 
	
				switch (settings.baumsize) {       
					case 1:
						scale = 0.015/resolution;
						scale < 0.02 && ( scale = 0.02 );
						mult = 3;
					break;            
					case 2:
					default:
						scale = 0.03/resolution;
						scale < 0.03 && ( scale = 0.03 );
						mult = 3;
					break;
					case 3:
						scale = 0.06/resolution;
						scale < 0.04 && ( scale = 0.04 );
						mult = 3;
					break;
	
				}        
			   
				switch (settings.baumplan) {
					case 2:
						jungbaum && (scale = mult * scale);
					break;
					case 3:
						bewaessern && (scale = mult * scale);
					break;
					case 4:
						!kontrolle && (opacity = 0.5);
					break;
				}
			 
				switch (typ) {
					case 'Laubbaum':
						style = new Style({

							image: new Icon({
								scale: [scale, scale],
								opacity: opacity, 
								src: select? 'sel_laubbaum.png' : (bewaessern? (jungbaum? 'laubbaum_jung_wasser.png' : 'laubbaum_wasser.png') : (jungbaum? 'laubbaum_jung.png' : 'laubbaum.png')),
							}),
							text: settings.baumbez ? new Text({
								text: bez,
								scale: scale*2,
								offsetY: scale * 160 ,
								textBaseline: 'top',
								font: 'bold 30px Calibri,sans-serif',
								fill: new Fill({
								  color: 'black',
								}),
								stroke: new Stroke({
								  color: 'white',
								  width: 2,
								})                   
							}): undefined,
						});
						break;
					case 'Nadelbaum':            
						style = new Style({
							image: new Icon({
								scale: [scale, scale],
								opacity: opacity,
								src: select? 'sel_nadelbaum.png' : (bewaessern? (jungbaum? 'nadelbaum_jung_wasser.png' : 'nadelbaum_wasser.png') : (jungbaum? 'nadelbaum_jung.png' : 'nadelbaum.png')),
							}),
							text: settings.baumbez ? new Text({
								text: bez,
								scale: scale*2,
								offsetY: scale * 160 ,
								textBaseline: 'top',
								font: 'bold 30px Calibri,sans-serif',
								fill: new Fill({
								  color: 'black',
								}),
								stroke: new Stroke({
								  color: 'white',
								  width: 2,
								})                   
							}): undefined,
						});
						break;
					default:            
						style = new Style({
						image: new Icon({
							scale: [scale, scale],
							opacity: opacity,
							src: select? 'sel_laubbaum.png' : 'laubbaum.png' ,
						}),
					});
					break;
				}

				if ( dringlichkeit > 0 && settings.baumplan === 4){
					let color_dringlichkeit:string;
					switch (true) {
						case (dringlichkeit <= 1):
							color_dringlichkeit = '#800000C8';
						break;
						case (dringlichkeit <= 2):
							color_dringlichkeit = '#FFA500C8';
						break;
						case (dringlichkeit <= 3):
							color_dringlichkeit = '#FFD700C8';
						break;
						case (dringlichkeit <= 4):
							color_dringlichkeit = '#90EE90C8';
						break;
						case (dringlichkeit <= 5):
							color_dringlichkeit = '#32CD32C8';
						break;
						case (dringlichkeit <= 6):
							color_dringlichkeit = '#008000C8';
						break;
						default:
							color_dringlichkeit = '#F8F8FFC8';
					}
					style2 = new Style({
						image: new Circle({
							radius: scale * 180,
							fill: new Fill({
								color: color_dringlichkeit,
							}),
						})
					})
					return [style2,style];			

				}
				else {
					if ( bewaessert && settings.baumplan === 3){

						const d:Date = new Date(bewaessert.slice(4,6)+"/"+bewaessert.slice(6,8)+"/"+bewaessert.slice(0,4));
						const diff = heute.getTime() - d.getTime(); 

						if ( diff > 777600000) {
							return [style];	
						}
						else {
							let color_bewaessert:string;
							switch (true) {
								case (diff <= 86400000):
									color_bewaessert = '#03045EC8';
								break;
								case (diff <= 86400000 * 2):
									color_bewaessert = '#023E8AC8';
								break;
								case (diff <= 86400000 * 3):
									color_bewaessert = '#0077B6C8';
								break;
								case (diff <= 86400000 * 4):
									color_bewaessert = '#0096C7C8';
								break;
								case (diff <= 86400000 * 5):
									color_bewaessert = '#00B4D8C8';
								break;
								case (diff <= 86400000 * 6):
									color_bewaessert = '#48CAE4C8';
								break;
								case (diff <= 86400000 * 7):
									color_bewaessert = '#90E0EFC8';
								break;
								case (diff <= 86400000 * 8):
									color_bewaessert = '#ADE8F4C8';
								break;
								case (diff <= 86400000 * 9):
									color_bewaessert = '#CAF0F8C8';
								break;
								default:
									color_bewaessert = '#F8F8FFC8';
							}
							style2 = new Style({
								image: new Circle({
									radius: scale * 180,
									fill: new Fill({
										color: color_bewaessert,
									}),
								})
							})
							return [style2,style];									
						}
					}
					else {
						return [style];
					}
				}

			}
		};	

		if ( vectorLayerRef.current && vectorStyleRef.current ){
        	vectorLayerRef.current.setStyle(vectorStyleRef.current);
		}

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.baumbez,settings.baumplan,settings.baumsize]);   

	useEffect(() => {
        setlog && console.log("BaumLayer:React.FC useEffect.[props.removefeature] "+ props.removeFeature);
		if ( props.removeFeature){
			if ( props.removeFeature.get('label') === label) { 
				if (vectorSourceRef.current) {
					vectorSourceRef.current.removeFeature(props.removeFeature);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
    } , [props.removeFeature]);

    return null;
};

export default BaumLayer;
