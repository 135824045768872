import React, { useContext, useEffect, useRef } from 'react';
import { Vector as OlVectorLayer } from 'ol/layer';
import { Vector as OlVectorSource } from 'ol/source';
import OlGeoJSON from 'ol/format/GeoJSON';
import { Style, Stroke } from 'ol/style';
import Feature, { FeatureLike } from 'ol/Feature';
import { Geometry  } from "ol/geom";

import { env } from '../Components/Env';
import {useSettings} from '../Components/SettingsContext'; 
import MapContext from "../Map/MapContext";

import { getAllPG } from '../Postgres/pg';

const name = 'Nahmobilität';
const label = 'nmob';
const type = 'LineString';

interface NMobLayerProps {
	setLoading: React.Dispatch<React.SetStateAction<number | null>>;
	removeFeature: Feature | null;
}

const heute = new Date();	

const NMobLayer: React.FC<NMobLayerProps> = (props) => {
	const setlog = false;
    setlog && console.log("NMobLayer:React.FC");
	const {settings} = useSettings();
	const map = useContext(MapContext);
	
	const vectorLayerRef = useRef<OlVectorLayer<Feature<Geometry>> | null>(null);
    const vectorSourceRef = useRef<OlVectorSource | null>(null);
	const vectorStyleRef = useRef<((feature: FeatureLike) => Style) | null>(null);	


    useEffect(() => {
		setlog && console.log("NMobLayer:React.FC useEffect[]");
		
		const vectorSource = new OlVectorSource({
			format: new OlGeoJSON(),
			attributions: env.attributions		
		});		

        const vectorLayer = new OlVectorLayer({
            source: vectorSource,
            style: (feature: FeatureLike) => vectorStyleRef.current ? vectorStyleRef.current(feature) : new Style(),
        });
		
		vectorLayer.set('name', name);
		vectorLayer.set('label', label);
		vectorLayer.set('type', type);
		vectorLayer.set('digi', false);
		vectorLayer.set('allow_digi', true);
		vectorLayer.set('select', true);
		vectorLayer.set('allow_select', true);		

		getAllPG(env.nmob!.url,env.nmob!.database,env.nmob!.schema, env.nmob!.tabelle, env.nmob!.attribute, vectorSource,() => {props.setLoading((l) => l ? l - 1 : 1)});
        map?.addLayer(vectorLayer);

		vectorLayerRef.current = vectorLayer;
        vectorSourceRef.current = vectorSource;

        return () => {
            map?.removeLayer(vectorLayer);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	useEffect(() => {
        setlog && console.log("NMobLayer:React.FC useEffect.[settings.nmobplan] "+ settings.nmobplan);

		vectorStyleRef.current = (feature: FeatureLike) => {    
			const properties = feature.getProperties();
			const select = properties['select'];
			const beendet = properties['beendet'];
			
			var style: Style;
	
			switch (settings.nmobplan) { 
				case 1 :
					if (beendet) {
						return new Style();
					}
				break; 
				case 2:
					if (beendet){
						const d:Date = new Date(beendet.slice(4,6)+"/"+beendet.slice(6,8)+"/"+beendet.slice(0,4));
						if ( heute.getTime() - d.getTime()  > 31536000000 ){
							return new Style(); 
						}
					}
				break;
			}
	
			style = new Style({
				stroke: new Stroke({
					color: select? 'red' : beendet? 'green' : 'orange',
					width: 5,
				}),
			});
			
			return style;
		};	

		if ( vectorLayerRef.current && vectorStyleRef.current ){
        	vectorLayerRef.current.setStyle(vectorStyleRef.current);
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.nmobplan]);    	

	useEffect(() => {
        setlog && console.log("NMobLayer:React.FC useEffect.[props.removefeature] "+ props.removeFeature);
		if ( props.removeFeature){
			if ( props.removeFeature.get('label') === label) { 
				if (vectorSourceRef.current) {
					vectorSourceRef.current.removeFeature(props.removeFeature);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
    } , [props.removeFeature]);

    return null;
};

export default NMobLayer;
