import React, { useContext, useState } from "react";

import OlLayer from 'ol/layer/Layer';
import {useSettings} from './SettingsContext';
import 'antd/dist/reset.css';

import { UnorderedListOutlined } from '@ant-design/icons';

import SimpleButton from "./UI/SimpleButton"
import MapContext from "../Map/MapContext";

import {
    Avatar,
    //Button,
    Card,
    //Checkbox,
    Col,
    Divider,
    Form,
    Image,
    //Input,
    //Radio,
    Row,
    //Tooltip,
    Typography, 
	Drawer 
} from 'antd';

const { Text } = Typography;

interface legendeProps {
    style: React.CSSProperties;
};

const Legende:React.FC<legendeProps> = (props) => { 
    const [ legende, setLegende ] = useState<Boolean>(false);
 
    const openCloseLegende = () =>{       
        //console.log('openLegende');
        setLegende((prevState) => { return !prevState });
    };

    return (
        <div>            
            <LegendeDrawer open={legende} closeDrawer={openCloseLegende} />
            <SimpleButton
                type="default"
                style={props.style} 
                shape="circle"
                onClick={openCloseLegende}
                tooltip="Legende"
                tooltipPlacement="right"
                icon={<UnorderedListOutlined />}
            />          
        </div>
    );
};

interface legendeDrawerProps {
    open: Boolean;
    closeDrawer: () => void;
};

const LegendeDrawer:React.FC<legendeDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("LegendeDrawer:React.FC")
    const map = useContext(MapContext);
    const {settings} = useSettings();  

    //setlog && console.log("LegendeDrawer ", map?.getAllLayers());

    const closeDrawer = () =>{
        //setlog && console.log("closeDrawer LegendeDrawer ", props);
        //setlog && console.log("closeDrawer ", legende.mobile);
        //setlog && console.log("closeDrawer ", legende.testNum);
        //setlog && console.log("closeDrawer ", legende.testStr);
        props.closeDrawer();
    };
    
    const mapFilter : Array<OlLayer> | undefined = map?.getAllLayers().filter((layer) => {
        return layer.get('name').slice(0,1) !== '_';
    });

    return ( 
        <Drawer
            title={"Legende"}
            className="ggw-legende"
            placement="right"
            open={props.open?.valueOf()}
            onClose={closeDrawer}
            mask={false}		
        >
            <Form layout="vertical">                
                <Row gutter={[8,8]} >					
                    <Col span={24}>
                        { mapFilter?.map (layer => 
                             layer.getVisible() && (
                                <Card key={'legende_'+layer.get('label')} title={layer.get('name')}>
                                    { layer.get('label') === 'baum' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./laubbaum.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Laubbaum</Text>
                                                </Col>                                               
                                            </Row>                        
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./laubbaum_jung.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>junger Laubbaum</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./laubbaum_wasser.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Laubbaum (bewässert)</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./laubbaum_jung_wasser.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>junger Laubbaum (bewässert)</Text>
                                                </Col>                                               
                                            </Row>

                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./nadelbaum.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Nadelbaum</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./nadelbaum_jung.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>junger Nadelbaum</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./nadelbaum_wasser.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Nadelbaum (bewässert)</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./nadelbaum_jung_wasser.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>junger Nadelbaum (bewässert)</Text>
                                                </Col>                                               
                                            </Row>
                                            { settings.baumplan === 4 && (
                                                <div> 
                                                    <Divider />
                                                    <Row align="middle" gutter={[8, 8]}>
                                                        <Col flex="50px">
                                                            <Avatar style={{ backgroundColor: '#800000C8', verticalAlign: 'middle' }} size="small" >1</Avatar>
                                                        </Col>
                                                        <Col flex="auto">
                                                            <Text strong>Dringlichkeit 1</Text>
                                                        </Col>  
                                                    </Row>
                                                    <Row align="middle" gutter={[8, 8]}>
                                                        <Col flex="50px">
                                                            <Avatar style={{ backgroundColor: '#FFA500C8', verticalAlign: 'middle' }} size="small" >2</Avatar>
                                                        </Col>
                                                        <Col flex="auto">
                                                            <Text strong>Dringlichkeit 2</Text>
                                                        </Col>  
                                                    </Row>                
                                                    <Row align="middle" gutter={[8, 8]}>
                                                        <Col flex="50px">
                                                            <Avatar style={{ backgroundColor: '#FFD700C8', verticalAlign: 'middle' }} size="small" >3</Avatar>
                                                        </Col>
                                                        <Col flex="auto">
                                                            <Text strong>Dringlichkeit 3</Text>
                                                        </Col>  
                                                    </Row>                
                                                    <Row align="middle" gutter={[8, 8]}>
                                                        <Col flex="50px">
                                                            <Avatar style={{ backgroundColor: '#90EE90C8', verticalAlign: 'middle' }} size="small" >4</Avatar>
                                                        </Col>
                                                        <Col flex="auto">
                                                            <Text strong>Dringlichkeit 4</Text>
                                                        </Col>  
                                                    </Row>                
                                                    <Row align="middle" gutter={[8, 8]}>
                                                        <Col flex="50px">
                                                            <Avatar style={{ backgroundColor: '#32CD32C8', verticalAlign: 'middle' }} size="small" >5</Avatar>
                                                        </Col>
                                                        <Col flex="auto">
                                                            <Text strong>Dringlichkeit 5</Text>
                                                        </Col>  
                                                    </Row>                
                                                    <Row align="middle" gutter={[8, 8]}>
                                                        <Col flex="50px">
                                                            <Avatar style={{ backgroundColor: '#008000C8', verticalAlign: 'middle' }} size="small" >6</Avatar>
                                                        </Col>
                                                        <Col flex="auto">
                                                            <Text strong>Dringlichkeit 6</Text>
                                                        </Col>  
                                                    </Row>                                                                                                                                                                                                                                                                                                                                        					
                                                </div> 
                                            )}                                                                                                                                                                                              
                                        </div>   
                                    )}
                                    { layer.get('label') === 'tswt' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./biene.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Schale</Text>
                                                </Col>                                               
                                            </Row>                        
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./vogel.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Futter</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./fuchs.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Wanne</Text>
                                                </Col>                                               
                                            </Row>                                            
                                        </div>   
                                    )}
                                    { layer.get('label') === 'nmob' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Divider style={{ color: 'orange', border: '2px solid' , margin: '2px'}}/>
                                                </Col>
                                                <Col flex="auto">
                                                    <Text strong>geplante Maßnahme</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Divider style={{ color: 'green', border: '2px solid' , margin: '2px'}}/>
                                                </Col>
                                                <Col flex="auto">
                                                    <Text strong>fertiggestellte Maßnahme</Text>
                                                </Col>                                               
                                            </Row>                                            
                                        </div>
                                    )}                                    
                                    { layer.get('label') === 'alkis' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Divider style={{ color: 'grey', border: '2px solid' , margin: '2px'}}/>
                                                </Col>
                                                <Col flex="auto">
                                                    <Text strong>Flurstück</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Divider style={{ color: 'red', border: '2px solid' , margin: '2px'}}/>
                                                </Col>
                                                <Col flex="auto">
                                                    <Text strong>Flurstück (ausgewählt)</Text>
                                                </Col>                                               
                                            </Row>                                            
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Divider style={{ color: 'darkred', border: '1px solid' , margin: '2px' }}/>
                                                </Col>
                                                <Col flex="auto">
                                                    <Text strong>Gebäude</Text>
                                                </Col>                                               
                                            </Row>                                            
                                        </div>
                                    )}
                                    { layer.get('label') === 'bplan' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Divider style={{ color: 'black', border: '2px dashed' , margin: '2px' }}/>
                                                </Col>
                                                <Col flex="auto">
                                                    <Text strong>Bebauunspläne (rechtsgültig)</Text>
                                                </Col>                                               
                                            </Row>                                            
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Divider style={{ color: 'red', border: '2px dashed' , margin: '2px' }}/>
                                                </Col>
                                                <Col flex="auto">
                                                    <Text strong>Bebauunspläne (rechtsgültig, ausgewählt)</Text>
                                                </Col>                                               
                                            </Row>                                            
                                        </div>                                        
                                    )}                                                                                               
                                    { layer.get('label') === 'defi' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./defi.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Standort</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./sel_defi.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Standort (ausgewählt)</Text>
                                                </Col>                                               
                                            </Row>                                            
                                        </div>
                                    )}
                                    { layer.get('label') === 'bank' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={15}
                                                        src="./bank.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Standort</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={15}
                                                        src="./sel_bank.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Standort (ausgewählt)</Text>
                                                </Col>                                               
                                            </Row>                                            
                                        </div>
                                    )}                                    
                                    { layer.get('label') === 'hth' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={15}
                                                        src="./hth.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Standort</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={15}
                                                        src="./sel_hth.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Standort (ausgewählt)</Text>
                                                </Col>                                               
                                            </Row>                                            
                                        </div>
                                    )}                                      
                                    { layer.get('label') === 'schule' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={15}
                                                        src="./schule.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Standort</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={15}
                                                        src="./sel_schule.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Standort (ausgewählt)</Text>
                                                </Col>                                               
                                            </Row>                                            
                                        </div>
                                    )}
                                   { layer.get('label') === 'schulweg' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                <Divider style={{ color: 'darkmagenta', border: '1px solid' , margin: '2px' }}/>
                                                </Col>
                                                <Col flex="auto">
                                                    <Text strong>großer Schulweg</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                <Divider style={{ color: 'blue', border: '1px solid' , margin: '2px' }}/>
                                                </Col>
                                                <Col flex="auto">
                                                    <Text strong>Schulweg</Text>
                                                </Col>                                               
                                            </Row>                                                                                        
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                <Divider style={{ color: 'red', border: '1px solid' , margin: '2px' }}/>
                                                </Col>
                                                <Col flex="auto">
                                                    <Text strong>Schulweg (nicht gesichert)</Text>
                                                </Col>                                               
                                            </Row>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                <Divider style={{ color: 'green', border: '1px solid' , margin: '2px' }}/>
                                                </Col>
                                                <Col flex="auto">
                                                    <Text strong>Fußgängerampel</Text>
                                                </Col>                                               
                                            </Row>                                                                                                   
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Divider style={{ color: 'green', border: '2px dashed' , margin: '2px' }}/>
                                                </Col>
                                                <Col flex="auto">
                                                    <Text strong>Fußgängerüberweg</Text>
                                                </Col>                                               
                                            </Row>                                            
                                        </div>                                        
                                    )}                                                                                                                                                                                              
                                    { layer.get('label') === 'osm' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./osm.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Hintergrund</Text>
                                                </Col>                                               
                                            </Row>
                                        </div>
                                    )}                                     
                                    { layer.get('label') === 'dop' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./raster.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Hintergrund</Text>
                                                </Col>                                               
                                            </Row>
                                        </div>
                                    )}
                                   { layer.get('label') === 'boris' && (
                                        <div>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col flex="50px">
                                                    <Image
                                                        width={20}
                                                        src="./boris.png"
                                                    />
                                                    </Col>
                                                <Col flex="auto">
                                                    <Text strong>Bodenrichtwerte</Text>
                                                </Col>                                               
                                            </Row>
                                        </div>
                                    )}                                                                                                                                                                                       
                                </Card>
                            ) 
                        ) }
                    </Col>
                </Row>
            </Form>            
        </Drawer>
    );        
};

export {Legende};