import React, { useContext, useEffect, useRef } from 'react';
import { Vector as OlVectorLayer } from 'ol/layer';
import { Vector as OlVectorSource } from 'ol/source';
import OlGeoJSON from 'ol/format/GeoJSON';
import { Style, Icon } from 'ol/style';
import Feature, { FeatureLike } from 'ol/Feature';

import { env } from '../Components/Env'; 
import MapContext from "../Map/MapContext";

import { getAllPG } from '../Postgres/pg';

const name = 'Trinkstation';
const label = 'tswt';
const type = 'Point';

interface TswtLayerProps {
	setLoading: React.Dispatch<React.SetStateAction<number | null>>;
	removeFeature: Feature | null;
}

const TswtLayer: React.FC<TswtLayerProps> = (props) => {
	const setlog = false;
    setlog && console.log("TswtLayer:React.FC");

	const map = useContext(MapContext);
	//const vectorLayerRef = useRef<OlVectorLayer<OlVectorSource> | null>(null);
    const vectorSourceRef = useRef<OlVectorSource | null>(null);

    useEffect(() => {
		setlog && console.log("TswtLayer:React.FC useEffect[]");

		const vectorSource = new OlVectorSource({
			format: new OlGeoJSON(),
			attributions: env.attributions		
		});
		
		const vectorStyle = (feature: FeatureLike): Style =>{    
			//var typ = feature.get('typ');
			const properties = feature.getProperties();
			const select = properties['select'];
			const typ = properties['typ'];

			var style: Style;

			switch (typ) {
				case 'Wanne':
					style = new Style({
						image: new Icon({
							scale: [0.12, 0.12],
							anchor: [0.5, 1],
							src: select? 'sel_fuchs.png' : 'fuchs.png',
						}),
					});
				break;
				case 'Schale':
					style = new Style({
						image: new Icon({
							scale: [0.12, 0.12],
							anchor: [0.5, 1],
							src: select? 'sel_biene.png' : 'biene.png',
						}),
					});
				break;
				case 'Futter':
					style = new Style({
						image: new Icon({
							scale: [0.12, 0.12],
							anchor: [0.5, 1],
							src: select? 'sel_vogel.png': 'vogel.png',
						}),
					});
				break;
				default:
					style = new Style({
						image: new Icon({
							scale: [0.12, 0.12],
							anchor: [0.5, 1],
							src: select? 'sel_vogel.png' : 'vogel.png',
						}),
					});
				break;        
			} 
			return style;
		};		

        const vectorLayer = new OlVectorLayer({
            source: vectorSource,
            style: vectorStyle,
        });
		
		vectorLayer.set('name', name);
		vectorLayer.set('label', label);
		vectorLayer.set('type', type);
		vectorLayer.set('digi', false);
		vectorLayer.set('allow_digi', true);
		vectorLayer.set('select', true);
		vectorLayer.set('allow_select', true);		

		getAllPG(env.tswt!.url,env.tswt!.database,env.tswt!.schema, env.tswt!.tabelle, env.tswt!.attribute, vectorSource,() => {props.setLoading((l) => l ? l - 1 : 1)});
        map?.addLayer(vectorLayer);

		//vectorLayerRef.current = vectorLayer;
        vectorSourceRef.current = vectorSource;		

        return () => {
            map?.removeLayer(vectorLayer);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
	
	useEffect(() => {
        setlog && console.log("TswtLayer:React.FC useEffect.[props.removefeature] "+ props.removeFeature);
		if ( props.removeFeature){
			if ( props.removeFeature.get('label') === label) { 
				if (vectorSourceRef.current) {
					vectorSourceRef.current.removeFeature(props.removeFeature);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
    } , [props.removeFeature]);

    return null;
};

export default TswtLayer;
