import React, { useContext, useEffect } from 'react';
import { Vector as OlVectorLayer } from 'ol/layer';
import { Vector as OlVectorSource } from 'ol/source';
import OlGeoJSON from 'ol/format/GeoJSON';
import { Style, Fill, Stroke } from 'ol/style';
import { FeatureLike } from 'ol/Feature';

import { env } from '../Components/Env'; 
import MapContext from "../Map/MapContext";

interface SchulwegLayerProps {
	setLoading: React.Dispatch<React.SetStateAction<number | null>>;
}

const SchulwegLayer: React.FC<SchulwegLayerProps> = (props) => {
	const setlog = false;
    setlog && console.log("SchulwegLayer:React.FC");

	const map = useContext(MapContext);

    useEffect(() => {
		setlog && console.log("SchulwegLayer:React.FC useEffect[]");

		const vectorSource = new OlVectorSource({
			url: env.schulweg ? env.schulweg.url : '',
			format: new OlGeoJSON(),
			attributions: env.attributions		
		});
		
		const vectorStyle = (feature: FeatureLike): Style =>{    
			const properties = feature.getProperties();
			const select = properties['select'];
			var uebergang = feature.get('übergang');

			var style: Style;

			var faktor = 1; 
			if ( select ){
				faktor = 3;
			}

			switch (uebergang) {
				case 'nicht gesichert':
					style = new Style({
						fill: new Fill({
							color: 'rgba(255, 255, 255, 0.2)',
						}),
						stroke: new Stroke({
							color: 'red',
							width: 5*faktor,
						}),
					});
				break;
				case 'Fußgängerampel':
					style = new Style({
						fill: new Fill({
							color: 'rgba(255, 255, 255, 0.2)',
						}),
						stroke: new Stroke({
							color: 'green',
							width: 5*faktor,
						}),
					});
				break;
				case 'Fussgängerüberweg':
					style = new Style({
						fill: new Fill({
							color: 'rgba(255, 255, 255, 0.2)',
						}),
						stroke: new Stroke({
							color: 'green',
							lineDash: [5, 15, 5, 15],
							width: 5*faktor,
						}),
					});
				break;
				case 'großer Schulweg':
					style = new Style({
						fill: new Fill({
							color: 'rgba(255, 255, 255, 0.2)',
						}),
						stroke: new Stroke({
							color: 'darkmagenta',
							width: 5*faktor,
						}),
					});
				break;																							
				default:
					style = new Style({
						fill: new Fill({
							color: 'rgba(255, 255, 255, 0.2)',
						}),
						stroke: new Stroke({
							color: 'blue',
							width: 5*faktor,
						}),
					});						
			} 

			return style;
		};		


        const vectorLayer = new OlVectorLayer({
            source: vectorSource,
            style: vectorStyle,
        });
		
		vectorLayer.set('name', 'Schulweg');
		vectorLayer.set('label', 'schulweg');
		vectorLayer.set('type', 'Point');
		vectorLayer.set('digi', false);
		vectorLayer.set('allow_digi', true);
		vectorLayer.set('select', true);
		vectorLayer.set('allow_select', true);		

        map?.addLayer(vectorLayer);
		props.setLoading((l) => l ? l - 1 : 1);

        return () => {
            map?.removeLayer(vectorLayer);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default SchulwegLayer;
