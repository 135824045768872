import React, {useEffect, useState, ChangeEvent } from "react";
import 'antd/dist/reset.css';

import {
    //App,
    Button,
    Col,
    Form,
//    message,
	Input,
    Modal,
	Popconfirm,
    Row,
//    Space,
	Table,
	Tooltip, 
//	Tag,
	Drawer 
} from 'antd';

import { 
	BgColorsOutlined,
	CalendarOutlined,
	CheckOutlined,
	CloudDownloadOutlined,
	DeleteOutlined,
	ExclamationCircleOutlined,
	MinusOutlined,
	QuestionCircleOutlined,
	SaveOutlined
} from '@ant-design/icons';

//import type { SelectProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';


import { array2Schema, MapSchema} from '../EnvProps';
import { env } from '../Env'; 

//import MapContext from "../Map/MapContext";
//import {useSettings} from '../Components/SettingsContext';
import Feature from 'ol/Feature';
//import { Geometry } from "ol/geom";
  
import { useAuth0 } from '@auth0/auth0-react';
import { updatebyPkeyPG, deleteAttributePG} from '../../Postgres/pg';


const bewaesserungSchemaArray = env.baum ? env.baum.bewaesserungSchema : [];
const bewaesserungSchema = array2Schema(bewaesserungSchemaArray);
type BewaesserungSchemaTyp = typeof bewaesserungSchema;
type Bewaesserung = MapSchema<BewaesserungSchemaTyp>;
const isKeyBewaesserung = (k: string): k is keyof BewaesserungSchemaTyp => k in bewaesserungSchema;


/*
const bewaesserungSchemaArray = Object.entries(bewaesserungSchema).map(([key, value]) => ({
	 attribut: key, typ: value.t, sichtbar: value.s , updatebar: value.u,
}));*/


interface baumBewaesserungDrawerProps {
    closeBewaesserungDrawer: () => void;
	setClick: React.Dispatch<React.SetStateAction<boolean>>;
	bewaessertHeute: () => void;
	setBewaesserung: React.Dispatch<React.SetStateAction<Array<Bewaesserung>>>;
	feature: Feature | null;
    bewaesserung: Array<Bewaesserung>;
}  

const BaumBewaesserungDrawer:React.FC<baumBewaesserungDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("baumBewaesserungDrawer:React.FC");

    //const {settings} = useSettings();
	const [changed, setChanged] = useState(false);
	const [modal, contextHolder] = Modal.useModal();
        
    const {
        isAuthenticated,
        getAccessTokenSilently
    } = useAuth0();

	const [bewaesserung, setBewaesserung] = useState<Bewaesserung | null>(null);
	const [selectedRowsArray, setSelectedRowsArray] = useState<React.Key[]>([]);

    useEffect(() => {
		setlog && console.log("baumBewaesserungDrawer:React.FC useEffect.[props.bewaesserung]");

		if (props.bewaesserung) {
            if (props.bewaesserung.length > 0) {
				setlog && console.log("baumBewaesserungDrawer:React.FC useEffect.[props.bewaesserung]", props.bewaesserung.length);
				const data: DataType[] = [];
				
				props.bewaesserung.forEach((a) => {
					//setlog && console.log("baumZustandDrawer:React.FC useEffect.[schaden]", a);
					const datum = a.datum as string;
					const bem = a.bemerkung as string;
					data.push({
						key: a.index as number, 
						datum: datum.slice(6,8)+'.'+datum.slice(4,6)+'.'+datum.slice(0,4),
						wasser: a.wasser as string + ' l',
						bemerkung: bem && bem.length > 0 ? true : false
					});
				});
				setDataSource(data);
				setBewaesserung(null);
				setSelectedRowsArray([]);
			}
		}	
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.bewaesserung]);
	
	useEffect(() => {
        if (props.feature) {
            if (props.feature.get('label') !== 'baum') {
                setlog && console.log("baumBewaesserungDrawer:React.FC useEffect.[props.feature]", props.feature);
				closeBewaesserungDrawer();
            }
        }
		else {
			closeBewaesserungDrawer();
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.feature]); 	

    const dboSave = () =>{
        setlog && console.log("baumBewaesserungDrawer:React.FC dboSave ");
		if ( bewaesserung ) {
			updatebyPkeyPG('baumbewaesserung','baum', bewaesserung.index as number, bewaesserungSchemaArray,{'bemerkung': bewaesserung.bemerkung},getAccessTokenSilently,()=>{});
			setChanged(false);

			props.setBewaesserung(prevBewaesserung => {
				return prevBewaesserung.map(b => {
				  if (b.index === bewaesserung.index) {
					let updatedAttributes: Partial<any> = {};
	
					bewaesserungSchemaArray.forEach((a) => {
						if ( a.u && isKeyBewaesserung(a.k)  ){						
							updatedAttributes = Object.assign(updatedAttributes, {[a.k]:bewaesserung[a.k]})
						}
					});
					return { ...b, ...updatedAttributes};
				  }
				  return b;
				});
			});
		}
    };

    const closeBewaesserungDrawer = () =>{
        setlog && console.log("baumBewaesserungDrawer:React.FC closeBewaesserungDrawer");
		changed ? 
			modal.confirm({
				title: 'Die veränderten Daten wurden noch nicht gespeichert!',
				content: 'Möchten Sie den Dialog wirklich schließen?',
				okText: "...go!",
				okType: "danger",
				cancelText: "Nein",                
				onOk: () => closeBewaesserungDrawerReally(),
				onCancel: () => {},
			}) : closeBewaesserungDrawerReally();
		};

	const closeBewaesserungDrawerReally = () =>{
		setlog && console.log("baumBewaesserungDrawer:React.FC dboDeleteReally");
		setChanged(false);
		props.closeBewaesserungDrawer();
	};
	
    const dboDeleteReally = () =>{
        setlog && console.log("baumBewaesserungDrawer:React.FC dboDeleteReally");
		if ( bewaesserung ){
			deleteAttributePG('baumbewaesserung','baum', bewaesserung.index as number, getAccessTokenSilently, ()=>{});
			updatebyPkeyPG('baum','baum', props.feature?.get('index'), env.baum!.attribute, {'bewaessert': null},getAccessTokenSilently,() => {});
			setChanged(false);

			if (props.bewaesserung.length === 1) {
				closeBewaesserungDrawer();
			}
			else{
				props.setBewaesserung(props.bewaesserung.filter(item => item.index !== bewaesserung.index));
			}
		}
    };

	interface DataType {
		key: React.Key;
		datum: string;
		wasser: string;
		bemerkung: boolean;
	}
	const [dataSource, setDataSource] = useState<DataType[]>([]);	

	const columns: ColumnsType<DataType> = [
		{
		  title: <Tooltip title="Datum"><div style={{ textAlign: 'center' }}><CalendarOutlined /></div></Tooltip>,
		  dataIndex: 'datum',
		  key: 'datum',
		  render: (text: React.ReactNode) => <div style={{ textAlign: 'center' }}>{text}</div>,
		},
		{
			title: <Tooltip title="Wasser"><div style={{ textAlign: 'center' }}><CloudDownloadOutlined /></div></Tooltip>,
			dataIndex: 'wasser',
			key: 'wasser',
			render: (text: React.ReactNode) => <div style={{ textAlign: 'center' }}>{text}</div>,
		},
		{
			title: <Tooltip title="Bemerkung"><div style={{ textAlign: 'center' }}><ExclamationCircleOutlined /></div></Tooltip>,
			dataIndex: 'bemerkung',
			key: 'bemerkung',
			render: (value: boolean) => (
				<div style={{ textAlign: 'center' }}>
				  {value ? <CheckOutlined style={{ color: 'green' }} /> : <MinusOutlined style={{ color: 'grey' }} />}
				</div>
			),
		},
	];

	useEffect(() => {
        setlog && console.log("baumDrawer:React.FC useEffect.[changed] "+ changed);
        props.setClick(() => (!changed));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changed]);    

	const changeBemerkung = (value:string) =>{
        setlog && console.log("baumBewaesserungDrawer:React.FC changeBemerkung", value);
		if ( bewaesserung ) {
			setChanged(true);
			setBewaesserung( currentState => { return {...currentState, bemerkung:value}}); 
		}
    };	

	const bewaesserungHeute = () => {
		setlog && console.log("baumBewaesserungDrawer:React.FC bewaesserungHeute");
		props.bewaessertHeute();
	};				
	
	const renderBewaesserungHeute = () => {
		return (
		  <Button icon={<BgColorsOutlined />} onClick={() => bewaesserungHeute()}>Heute</Button>
		);
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setlog && console.log("baumBewaesserungDrawer:React.FC onSelectChange",newSelectedRowKeys);

		setSelectedRowsArray(newSelectedRowKeys);
		const found = props.bewaesserung.find((element) => element.index === newSelectedRowKeys[0] as number) as Bewaesserung;
		setBewaesserung(found);
	};

	const rowSelection = {
		selectedRowKeys: selectedRowsArray,
		onChange: onSelectChange,
		getCheckboxProps: () => ({ disabled: changed })
	};
	  

	return (
		<div> {contextHolder}
			<Drawer
				title={"Baumbewässerung"}
				className="ggw-baum-bewaesserung"
				placement="right"
				open={!!props.bewaesserung?.length}
				onClose={closeBewaesserungDrawer}
				mask={false}
				footer={
					isAuthenticated && (
						<Row gutter={[8,8]} >
							<Col span={12}>
								<Button icon={<SaveOutlined />} onClick={dboSave} disabled={!changed} type="primary" block>Speichern</Button>		
							</Col>
							<Col span={12}>
								<Popconfirm 
									onConfirm={dboDeleteReally}
									title={"Wollen Sie die Bewässerung wirklich löschen?"} 
									icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
									okText="...go!"
									okType="danger"
									cancelText="Nein"
								>
									<Button icon={<DeleteOutlined />} disabled={bewaesserung ? false : true} type="primary" block>Löschen</Button>
								</Popconfirm>												
							</Col>
						</Row>
					)
				}                                
			>
				<Form layout="vertical">
					<Row gutter={[8,8]} >					
						<Col span={24}>	
							<Table
								style={{ marginTop: 20, width: '100%' }}
								rowSelection={{
									type: 'radio',
									selections: false,
									preserveSelectedRowKeys: false,
									...rowSelection,
								}}
								columns={columns} 
								dataSource={dataSource}
								footer={renderBewaesserungHeute}
							/>
							<Form.Item key={'bemerkung'} style={{ marginBottom: 4 }} label={'Bemerkung'}>
								<Input key={'input'} name={'bemerkung'} value={bewaesserung ? bewaesserung.bemerkung as string : ''} onChange={(e: ChangeEvent<HTMLInputElement>) => changeBemerkung(e.currentTarget.value)} />
							</Form.Item>
						</Col>					
					</Row>
				</Form>
			</Drawer>
		</div>
	);
};

export { BaumBewaesserungDrawer };
export type { Bewaesserung };
//export const MemoizedbaumBewaesserungDrawer = React.memo(baumBewaesserungDrawer);