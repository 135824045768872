import React, { useContext, useEffect } from 'react';

//Layer
import OlImageLayer from 'ol/layer/Image';
//Source
import OlImageWMSSource from 'ol/source/ImageWMS';

import MapContext from "../Map/MapContext";

interface BorisLayerProps {
}

const BorisLayer: React.FC<BorisLayerProps> = (props) => {
	const setlog = false;
    setlog && console.log("BorisLayer:React.FC");

	const map = useContext(MapContext);    
    
    useEffect(() => {
		setlog && console.log("BorisLayer:React.FC useEffect[]");    
        const borisLayer = new OlImageLayer({
            opacity: 0.6,
            source: new OlImageWMSSource({
                url: 'https://www.gds-srv.hessen.de/cgi-bin/lika-services/ogc-free-maps.ows',
                params: {
                'LAYERS': 'hboris_zonen,hboris_feature,hboris_label',
                'FORMAT': 'image/jpeg',
                },
                attributions: ['<a href="http://www.geoportal.hessen.de">GDI-Hessen</a> '],
                serverType: 'mapserver',
            })
        });

        borisLayer.set('name', 'BORIS');
        borisLayer.set('label', 'boris');
        borisLayer.set('digi', false);
        borisLayer.set('allow_digi', false);
        borisLayer.set('select', false);
        borisLayer.set('allow_select', false);

        map?.addLayer(borisLayer);

        return () => {
            map?.removeLayer(borisLayer);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default BorisLayer;
