import React, {useContext, useEffect, useRef, useState, ChangeEvent } from "react";
import 'antd/dist/reset.css';

import MapContext from "../../Map/MapContext";
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

import Feature from 'ol/Feature';
import { buffer } from 'ol/extent';

import {
    Button,
    Carousel,
    Checkbox,
    Col,
    Form,
    Image,
    Input,
    InputNumber,
    message,
    Modal,
    Pagination,
    Popconfirm,
    Row,
    Space,
    Upload, 
	Drawer 
} from 'antd';

import { 
    DeleteOutlined,
    QuestionCircleOutlined,
    UploadOutlined 
} from '@ant-design/icons';

import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile, UploadProps, UploadChangeParam } from 'antd/es/upload/interface';
import type { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';

import { array2Schema /*, MapSchema*/} from '../EnvProps';
import { env } from '../Env'; 

import {useSettings} from '../SettingsContext';
//import { Geometry } from "ol/geom";
  
import { useAuth0 } from '@auth0/auth0-react';

import { uploadPublicDokumentePG, downloadDokumentePG, deleteDokumentePG } from '../../Postgres/pg';
//import { Geometry } from "ol/geom";

const tswtSchemaArray = env.tswt ? env.tswt.attribute : [];
const tswtSchema = array2Schema(tswtSchemaArray);
type TswtSchemaTyp = typeof tswtSchema;
//type Tswt = MapSchema<TswtSchemaTyp>;
const isKeyTswt = (k: string): k is keyof TswtSchemaTyp => k in tswtSchema;

interface tswtDrawerProps {
    url: string;
    closeDrawer: (unclick:Boolean,action:string) => void;
    setClick: React.Dispatch<React.SetStateAction<boolean>>; 
    featuresPos: number;
    featuresCount: number;
    setFeaturesPos: React.Dispatch<React.SetStateAction<number>>;    
    feature: Feature | null;
}  

const TswtDrawer:React.FC<tswtDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("tswtDrawer:React.FC");
    const map = useContext(MapContext);

    const {settings} = useSettings();
    const [disabled, setDisabled] = useState(true);
    const [touched, setTouched] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null); 
  
    const [changed, setChanged] = useState(false);
    const [attribute, setAttribute] = useState<{[key: string]: any}>({});
    const [dokumente, setDocumente] = useState<Array<{index:number, datei:string}>>([]);
    const [indexDokument, setIndexDokument] = useState(-1);
    const [carousel, setCarousel] = useState(true);
        
    const {
        isAuthenticated,
        getAccessTokenSilently,
        user
    } = useAuth0();

    const [modal, contextHolder] = Modal.useModal();
        
    const insertDokument = (index:number, datei:string) =>{
        setlog && console.log("TswtDrawer:React.FC insertDokument", index);
        message.success('Das Bild wurde erfolgreich geladen!');
        setDocumente([...dokumente,{index:index, datei:datei}]);
    };

    const uploadDokument = async ( options: RcCustomRequestOptions): Promise<void> => {
        setlog && console.log("TswtDrawer:React.FC uploadDokument", options);
        uploadPublicDokumentePG(props.feature!,getAccessTokenSilently,options.file,insertDokument);
    };

    const setDokument = (current:number) =>{
        setlog && console.log("TswtDrawer:React.FC setDokument", current);
        setIndexDokument(current);
    };

    const deleteDokument = () =>{
        setlog && console.log("TswtDrawer:React.FC deleteDokument",indexDokument);
        if ( indexDokument !== -1){
            const index = dokumente[indexDokument].index;
            deleteDokumentePG(props.feature!,getAccessTokenSilently,index);
            setDocumente(dokumente.filter(item => item.index !== index));
        }
    };

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj as RcFile);
            reader.onload = () => resolve(reader.result as string);
          });
        }
        //const image = new (window as any).Image();
        const image = new window.Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
      };    


    const onChange = (info: UploadChangeParam) => {
        setlog && console.log("TswtDrawer:React.FC onChange",info);
    };

    const propsUpload: UploadProps = {
        showUploadList: false,
        customRequest: uploadDokument,
        onPreview: onPreview,
        listType: 'picture',
        onChange: onChange,
    };

    const propsImgCrop = {
        modalTitle: 'Bild bearbeiten',
        modalOk: '...go!',
        modalCancel: 'Abbrechen',
        resetText: 'Zurücksetzen',
        showGrid: true,
        rotationSlider: true,
        aspectSlider: true,
        showReset: true,
    };

    const updateDokumente = (data:any) =>{
        setlog && console.log("TswtDrawer:React.FC updateDokumente", data);
        setIndexDokument(0);
        setDocumente(data);
    };

    useEffect(() => {
        setlog && console.log("TswtDrawer:React.FC useEffect.[changed] "+ changed);
        props.setClick(() => (!changed));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changed]);        

    useEffect(() => {
        if (props.feature) {
            if (props.feature.get('label') === 'tswt') {
                setlog && console.log("TswtDrawer:React.FC useEffect.[props.feature]", props.feature);

                setChanged(false);
                setDocumente([]);
                setIndexDokument(-1);
                if (props.feature) {
                    downloadDokumentePG(props.feature!,updateDokumente);

                    tswtSchemaArray.forEach((a) => {
                        if ( a.u && isKeyTswt(a.k) ){
                            const value: keyof TswtSchemaTyp = a.k;                        
                            setAttribute( prevAttribute => { return {...prevAttribute, [value]:props.feature?.get(value)}});
                        }
                    });
                }
                const geometry = props.feature.getGeometry();
                const extent = geometry!.getExtent();
                const extentB = buffer(extent, 50);
                map?.getView().fit(extentB, {
                    duration: 2000
                });                                        
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.feature]);    

    const dboSave = () =>{
        setlog && console.log("TswtDrawer:React.FC dboSave");

        if ( isAuthenticated ){

            tswtSchemaArray.forEach((a) => {
                if ( a.u && isKeyTswt(a.k) ){
                    props.feature?.set(a.k,attribute[a.k]);
                }
            });
     
            cleanUp();             
            props.closeDrawer(true,'save');
        }
        else {
            cleanUp();
            props.closeDrawer(true,'');
        }
    };

    const closeDrawer = () =>{
        setlog && console.log("TswtDrawer:React.FC closeDrawer");

        changed ? 
            modal.confirm({
                title: 'Die veränderten Daten wurden noch nicht gespeichert!',
                content: 'Möchten Sie den Dialog wirklich schließen?',
                okText: "...go!",
                okType: "danger",
                cancelText: "Nein",                
                onOk: () => closeDrawerReally(),
                onCancel: () => {},
            }) : closeDrawerReally();
    };

    const cleanUp = () =>{
        setlog && console.log("TswtDrawer:React.FC cleanUp");
        setChanged(false);
        setDocumente([]);
        setIndexDokument(-1);
    };

    const closeDrawerReally = () =>{
        setlog && console.log("TswtDrawer:React.FC closeDrawer");
        cleanUp();
        props.closeDrawer(true,'');
    };

    const dboDeleteReally = () =>{
        setlog && console.log("TswtDrawer:React.FC dboDeleteReally");
        if ( isAuthenticated ){
            setlog && console.log("TswtDrawer:React.FC dboDeleteReally",attribute);
            cleanUp();
            props.closeDrawer(true,'delete');
        }
        else {
            cleanUp();
            props.closeDrawer(true,'');
        }            
    };    

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        //setlog && console.log("TswtDrawer:React.FC omStart");
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };    

    const changeString = (key:string, value:string) =>{
        //setlog && console.log("TswtDrawer:React.FC changeString", value);
        if ( isAuthenticated ){
            setChanged(true);
            setAttribute( currentState => { return {...currentState, [key]:value}});
        }
    };

	const changeNumber = (key:string, value:number| null) =>{
        setlog && console.log("TswtDrawer:React.FC changeString", value);
        if ( isAuthenticated ){
            setChanged(true);        
            setAttribute( currentState => { return {...currentState, [key]:value}});
        }
    };	
    
    const changeBoolean = (key:string, value:boolean) =>{
        //setlog && console.log("TswtDrawer:React.FC changeString", value);
        if ( isAuthenticated ){
            setChanged(true);
            setAttribute( currentState => { return {...currentState, [key]:value}});
        }
    };	

	const changeFeature = (page: number, pageSize: number) => {
        setlog && console.log("TswtDrawer:React.FC changeFeature", page, pageSize);
        props.setFeaturesPos(page);
    };
    
    const title = (
        <Row gutter={[8,8]} >
            <Col span={12}>
                <div style={{ fontSize: '1.2em' }}>
                    <a id="nmob_mailto" href={"mailto:" + (isAuthenticated ? user?.email : ( env.tswt!.mail ? 'tswt-' + env.mailto : ''))  + "?Subject=Trinkstation: " +props.feature?.get('index')+ "&body=Hier der Link zur Trinkstation " + props.url + "%26search=tswt," +props.feature?.get('index')}>
                        {"Trinkstation "+props.feature?.get('index')}
                    </a>
                </div>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination simple size='small' current={props.featuresPos} pageSize={1} total={props.featuresCount} onChange={changeFeature}/>
            </Col>
        </Row>
    ); 
    
    const body = (
        <Form layout="vertical">
            <Row gutter={[8,8]} >					
                <Col span={24}>
                    {
                        tswtSchemaArray.map((a,i) => (
                            (a.s && (
                                (a.t === 'boolean' &&
                                    <Checkbox key={'input'+i} name={a.k} checked={attribute[a.k]} onChange={e => changeBoolean(a.k,e.target.checked)} style={{ marginTop: 6, marginBottom: 4 }}>
                                        {a.k.charAt(0).toUpperCase() + a.k.slice(1)}
                                    </Checkbox>
                                ) || (a.t === "number" &&
                                    <Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.k.charAt(0).toUpperCase() + a.k.slice(1)}>
                                        <InputNumber key={'input'+i} name={a.k} value={attribute[a.k]} style={{ width: '100%' }} onChange={(value: number | null) => changeNumber(a.k,value)} />
                                    </Form.Item>										
                                ) || (
                                    <Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.k.charAt(0).toUpperCase() + a.k.slice(1)}>
                                        <Input key={'input'+i} name={a.k} value={attribute[a.k]} onChange={(e: ChangeEvent<HTMLInputElement>) => changeString(a.k,e.currentTarget.value)} />
                                    </Form.Item>
                                )
                            ))
                        ))
                    }

                    <Form.Item style={{ marginBottom: 4 }} label={"Bilder (" + dokumente.length  + ")"}>
                        {dokumente.length === 0 ? (
                            <Image
                                preview = {false}
                                key = 'image_default'
                                src = "./tswt_dokumente/default.jpg"
                            />
                        ) : (
                            <Image.PreviewGroup>
                                <Carousel autoplay={carousel} effect="fade" infinite={false} afterChange={setDokument}>
                                    { dokumente.map((element) => (
                                        <Image
                                            key = {'image'+element.index}
                                            src = { "./tswt_dokumente/" + element.datei}
                                            fallback = "./tswt_dokumente/default.jpg"
                                        />
                                    ))}
                                </Carousel>
                            </Image.PreviewGroup>
                        )}                                               
                    </Form.Item>

                    { isAuthenticated && (
                        <Space>
                            <ImgCrop {...propsImgCrop}>
                                <Upload {...propsUpload}>
                                    <Button icon={<UploadOutlined />} />             
                                </Upload>
                            </ImgCrop>


                            <Popconfirm
                                onPopupClick={() => setCarousel(true)} 
                                onConfirm={deleteDokument}
                                title={"Wollen Sie das Bild wirklich löschen?"} 
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                okText="...go!"
                                okType="danger"
                                cancelText="Nein"
                            >
                                <Button icon={<DeleteOutlined />} onClick={() => setCarousel(false)}  />
                            </Popconfirm>						
                        </Space>
                    )}
                </Col>
            </Row>
        </Form>            
    );
        
    if ( !settings.mobile ){
        return ( 
            <div>{contextHolder}
                <Drawer
                    title={title}
                    className="ggw-tswt"
                    placement="right"
                    open={props.feature?.get('label')==='tswt'}
                    onClose={closeDrawer}
                    mask={false}
                    footer={
                        isAuthenticated && (
                            <Row gutter={[8,8]} >
                                <Col span={12}>
                                    <Button onClick={dboSave} disabled={!changed} type="primary" block>Speichern</Button>	
                                </Col>
                                <Col span={12}>
                                    <Popconfirm 
                                        onConfirm={dboDeleteReally}
                                        title={"Wollen Sie die Trinkstaionen wirklich löschen?"} 
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        okText="...go!"
                                        okType="danger"
                                        cancelText="Nein"
                                    >
                                        <Button type="link" block>Löschen</Button>
                                    </Popconfirm>						
                                </Col>
                            </Row>
                        )
                    }                                
                >
                    {body}
                </Drawer>
            </div>
        );
    }
    else {
        return ( 
            <div>{contextHolder}            
                <Modal
                    title={ <div
                        style={{
                            width: '100%',
                            cursor: 'move',
                        }}                                    
                        onMouseOver={() => {disabled && setDisabled(false);}}
                        onMouseOut={() => {setDisabled(true);}}
                        onTouchStart={() => {touched ? setDisabled(true) : setTouched(false);}}
                        onTouchEnd={() => {disabled ? setTouched(true) : setDisabled(false);}}
                        onTouchCancel={() => {!disabled && setDisabled(true);}}                    
                        >
                            {title}
                        </div>
                    }
                    open={props.feature?.get('label')==='tswt'}
                    onCancel={closeDrawer}
                    footer={[                  
                        isAuthenticated && (
                            <Row key='f_r' gutter={[8,8]} >
                                <Col key='f_c1' span={12}>
                                    <Button key='f_save' onClick={dboSave} type="primary" block>Speichern</Button>	
                                </Col>
                                <Col key='f_c2' span={12}>
                                    <Popconfirm
                                        key='popconfirm' 
                                        onConfirm={dboDeleteReally}
                                        title={"Wollen Sie die Trinkstaionen wirklich löschen?"} 
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        okText="...go!"
                                        okType="danger"
                                        cancelText="Nein"
                                    >
                                        <Button key='f_delete' type="link" block>Löschen</Button>
                                    </Popconfirm>						
                                </Col>
                            </Row>
                        )
                    ]}
                    maskClosable={false}
                    modalRender={(modal) => (
                        <Draggable
                            disabled={disabled}
                            bounds={bounds}
                            onStart={(event, uiData) => onStart(event, uiData)}
                        >
                            <div ref={draggleRef}>{modal}</div>
                        </Draggable>
                    )}                   
                >
                    {body}
                </Modal>
            </div>
        );
    }        
};

export default TswtDrawer;
//export const MemoizedtswtDrawer = React.memo(TswtDrawer);