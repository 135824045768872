import React, { useContext, useEffect } from 'react';

import OlSourceOsm from 'ol/source/OSM';
import OlLayerTile from 'ol/layer/Tile';

import MapContext from "../Map/MapContext";

interface OSMLayerProps {
}

const OSMLayer: React.FC<OSMLayerProps> = (props) => {
	const setlog = false;
    setlog && console.log("OSMLayer:React.FC");

	const map = useContext(MapContext);

    useEffect(() => {
		setlog && console.log("OSMLayer:React.FC useEffect[]");

        const layerTile = new OlLayerTile({
            visible: true,
            source: new OlSourceOsm({
                attributions: ['<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | ']
            })
        });

        layerTile.set('name', 'Open Street Map');
        layerTile.set('label', 'osm');
        layerTile.set('digi', false);
        layerTile.set('allow_digi', false);
        layerTile.set('select', false);
        layerTile.set('allow_select', false);

        map?.addLayer(layerTile);

        return () => {
            map?.removeLayer(layerTile);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};        

export default OSMLayer;