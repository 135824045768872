import { Coordinate } from 'ol/coordinate';
import { AttributionLike } from 'ol/source/Source';
import { ServerType }  from 'ol/source/wms';

type MapSchemaTypes = {
	string: string;
	number: number;
	boolean: boolean;
};

type pgAttribut = {
    k: string;
    t: keyof MapSchemaTypes;
    s: boolean;    
    u: boolean;
    d?: string|number|boolean;    
};

type pgSchema = Record<string, keyof MapSchemaTypes>;

type MapSchemaKeys = keyof MapSchemaTypes;

type MapSchema<T extends Record<string, MapSchemaKeys>> = {
	-readonly [K in keyof T]: MapSchemaTypes[T[K]]
};

const array2Schema = (input: Array<pgAttribut>): pgSchema => {
    return input.reduce((acc, { k, t }) => {
        acc[k] = t;
        return acc;
    }, {} as pgSchema);
};


export type {MapSchemaTypes, MapSchema, pgAttribut};
export {array2Schema};

export interface envProps {
    readonly zoom: number;
    readonly center: Coordinate;
    readonly layers: string[];
    readonly defaultLayers: string[];
    readonly attributions: AttributionLike;
    printOrientation:  "portrait" | "landscape";
    printScale: number;
    printSize: string;
    printResolution:number;
    logoPath:string;
    logoType:string;
    logoSize:[number,number];
    mailto:string;
    alkis: {
        url:string,
        wms:string,
        wfs:string,
        schema:string,
        layer:string,
        filter:string,
        mail:boolean;
        serverType:ServerType | undefined
    };
    dop: {
        url:string,
        layer:string,
        year:string,
        serverType:ServerType | undefined
    };
    hth?: {
        public: boolean,
        mail:boolean;
        url:string
    };    
    bplan?: {
        public: boolean,
        mail:boolean;
        url:string
    }; 
    defi?: {
        public: boolean,
        mail:boolean;
        url:string
    };              
    bank?: {
        public: boolean,
        mail:boolean;
        url:string
    };
    schule?: {
        public: boolean,
        mail:boolean;
        url:string
    };                            
    schulweg?: {
        public: boolean,
        mail:boolean;
        url:string
    };            
    tswt?: {
        public: boolean,
        url:string,
        database:string,
        schema:string,
        tabelle:string,
        attribute:Array<pgAttribut>,
        history:boolean,
        mail:boolean;
        dokumente:boolean
    };
    strassenschaden?: {
        public: boolean,
        url:string,
        database:string,
        schema:string,
        tabelle:string,
        attribute:Array<pgAttribut>,
        history:boolean,
        mail:boolean;
        dokumente:boolean,
        logo: string
    }; 
    baum?: {
        public: boolean,
        url:string,
        database:string,
        schema:string,
        tabelle:string,
        attribute:Array<pgAttribut>,
        zustandSchema:Array<pgAttribut>,
        schadenSchema:Array<pgAttribut>,
        bewaesserungSchema:Array<pgAttribut>,
        beurteilungenSchema:Array<pgAttribut>,
        history:boolean,
        mail:boolean;
        dokumente:boolean
    };    
    nmob?: {
        public: boolean,
        url:string,
        database:string,
        schema:string,
        tabelle:string,
        attribute:Array<pgAttribut>,
        history:boolean,
        mail:boolean;
        dokumente:boolean
    };            
};
