import React, {useEffect, useState } from "react";
import 'antd/dist/reset.css';
import '../../antd.css';

import {
    //App,
    Button,
    Col,
    Form,
    message,
//	Input,
//    Modal,
	Popconfirm,
    Row,
//    Space,
	Table,
	Tooltip, 
//	Tag,
	Upload,
	Drawer 
} from 'antd';

import { 
	AuditOutlined,
	DeleteOutlined,
	QuestionCircleOutlined
} from '@ant-design/icons';

//import type { SelectProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { UploadProps } from 'antd';

import { array2Schema, MapSchema} from '../EnvProps';
import { env } from '../Env'; 

//import MapContext from "../Map/MapContext";
//import {useSettings} from '../Components/SettingsContext';
import Feature from 'ol/Feature';
//import { Geometry } from "ol/geom";
  
import { useAuth0 } from '@auth0/auth0-react';
import { downloadDokument,uploadAuthDokumentePG, deleteAttributePG} from '../../Postgres/pg';


const beurteilungenSchemaArray = env.baum ? env.baum.beurteilungenSchema : [];
const beurteilungenSchema = array2Schema(beurteilungenSchemaArray);
type BeurteilungenSchemaTyp = typeof beurteilungenSchema;
type Beurteilungen = MapSchema<BeurteilungenSchemaTyp>;
//const isKeyBeurteilungen = (k: string): k is keyof BeurteilungenSchemaTyp => k in beurteilungenSchema;

/*
const beurteilungenSchemaArray = Object.entries(beurteilungenSchema).map(([key, value]) => ({
	 attribut: key, typ: value.t, sichtbar: value.s , updatebar: value.u,
}));*/

interface baumBeurteilungenDrawerProps {
    closeBeurteilungenDrawer: () => void;
	setClick: React.Dispatch<React.SetStateAction<boolean>>;
	setBeurteilungen: React.Dispatch<React.SetStateAction<Array<Beurteilungen>>>;
	feature: Feature | null;
    beurteilungen: Array<Beurteilungen>;
}  

const BaumBeurteilungenDrawer:React.FC<baumBeurteilungenDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("baumBeurteilungenDrawer:React.FC");

    //const {settings} = useSettings();
	const [changed, setChanged] = useState(false);
	//const [modal, contextHolder] = Modal.useModal();
        
    const {
        isAuthenticated,
        getAccessTokenSilently
    } = useAuth0();

	const [beurteilungen, setBeurteilungen] = useState<Beurteilungen | null>(null);
	const [selectedRowsArray, setSelectedRowsArray] = useState<React.Key[]>([]);

    useEffect(() => {
		setlog && console.log("baumBeurteilungenDrawer:React.FC useEffect.[props.beurteilungen]");

		if (props.beurteilungen) {
            if (props.beurteilungen.length > 0) {
				setlog && console.log("baumBeurteilungenDrawer:React.FC useEffect.[props.beurteilungen]", props.beurteilungen.length);
				const data: DataType[] = [];
				
				props.beurteilungen.forEach((a) => {
					//setlog && console.log("baumZustandDrawer:React.FC useEffect.[schaden]", a);
					
					data.push({
						key: a.index as number, 
						datei: a.datei as string 
					});
				});
				setDataSource(data);
				setBeurteilungen(null);
				setSelectedRowsArray([]);
			}
		}	
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.beurteilungen]);
	
	useEffect(() => {
        if (props.feature) {
            if (props.feature.get('label') !== 'baum') {
                setlog && console.log("baumBeurteilungenDrawer:React.FC useEffect.[props.feature]", props.feature);
				closeBeurteilungenDrawerReally();
            }
        }
		else {
			closeBeurteilungenDrawerReally();
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.feature]); 	

	const closeBeurteilungenDrawerReally = () =>{
		setlog && console.log("baumBeurteilungenDrawer:React.FC dboDeleteReally");
		setChanged(false);
		props.closeBeurteilungenDrawer();
	};
	
    const dboDeleteReally = () =>{
        setlog && console.log("baumBeurteilungenDrawer:React.FC dboDeleteReally");
		if ( beurteilungen ){
			deleteAttributePG('baumbeurteilungen','baum', beurteilungen.index as number, getAccessTokenSilently, ()=>{});
			setChanged(false);

			if (props.beurteilungen.length === 1) {
				closeBeurteilungenDrawerReally();
			}
			else{
				props.setBeurteilungen(props.beurteilungen.filter(item => item.index !== beurteilungen.index));
			}
		}
    };

	interface DataType {
		key: React.Key;
		datei: string;
	}
	const [dataSource, setDataSource] = useState<DataType[]>([]);	

	const columns: ColumnsType<DataType> = [
		{
			title: <Tooltip title="Datei"><div style={{ textAlign: 'center' }}><AuditOutlined/></div></Tooltip>,
			dataIndex: 'datei',
			key: 'datei',
			render: (text: React.ReactNode) => <div style={{ textAlign: 'left' }}>{text}</div>,
		},
	];

	useEffect(() => {
        setlog && console.log("baumDrawer:React.FC useEffect.[changed] "+ changed);
        props.setClick(() => (!changed));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changed]);    
	
	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setlog && console.log("baumBeurteilungenDrawer:React.FC onSelectChange",newSelectedRowKeys);

		setSelectedRowsArray(newSelectedRowKeys);
		const found = props.beurteilungen.find((element) => element.index === newSelectedRowKeys[0] as number) as Beurteilungen;
		setBeurteilungen(found);
	};

	const onOpenChange = (record: DataType) => {
		setlog && console.log("baumBeurteilungenDrawer:React.FC onOpenChange",record);
		downloadDokument('baumbeurteilungen','baum',record.datei,getAccessTokenSilently,()=>{});
	};

	const rowSelection = {
		selectedRowKeys: selectedRowsArray,
		onChange: onSelectChange,
		getCheckboxProps: () => ({ disabled: changed })
	};

	const insertDokument = (index:number, datei:string) =>{
        setlog && console.log("baumDrawer:React.FC insertDokument", index);
        props.setBeurteilungen([...props.beurteilungen,{index:index, datei:datei}]);
    };
	
	const uploadProps: UploadProps = {
		showUploadList: false,
		name: 'file',
		beforeUpload: file => {
			const isPDF = file.type === 'application/pdf';
			if (!isPDF) {
			  message.error(`Es können nur PDF Dateien geladen werden!`);
			}
			return isPDF || Upload.LIST_IGNORE;
		  },		
		onChange(info) {
		  if (info.file.status !== 'uploading') {
			console.log(info.file, info.fileList);
		  }
		  if (info.file.status === 'done') {
			message.success(`Die Datei wurde erfolgreich geladen!`);
			uploadAuthDokumentePG('baumbeurteilungen','baum', props.feature?.get('index') as number, getAccessTokenSilently,info.file.originFileObj, insertDokument);
		  } else if (info.file.status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		  }
		},
	};

	return (
		<Drawer
			title={"Baumbeurteilungen"}
			className="ggw-baum-beurteilungen"
			placement="right"
			open={!!props.beurteilungen?.length}
			onClose={closeBeurteilungenDrawerReally}
			mask={false}
			footer={
				isAuthenticated && (
					<Row gutter={[8,8]}>
						<Col span={12}>
							<Upload  {...uploadProps}>
								<Button type="primary" block>Hinzufügen</Button>
							</Upload>
						</Col>
						<Col span={12}>
							<Popconfirm 
								onConfirm={dboDeleteReally}
								title={"Wollen Sie die Beurteilung wirklich löschen?"} 
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
								okText="...go!"
								okType="danger"
								cancelText="Nein"
							>
								<Button icon={<DeleteOutlined />} disabled={beurteilungen ? false : true} type="primary" block>Löschen</Button>
							</Popconfirm>												
						</Col>
					</Row>
				)
			}                                
		>
			<Form layout="vertical">
				<Row gutter={[8,8]} >					
					<Col span={24}>	
						<Table
							style={{ marginTop: 20, width: '100%' }}
							rowSelection={{
								type: 'radio',
								selections: false,
								preserveSelectedRowKeys: false,
								...rowSelection,
							}}
							onRow={(record: any, rowIndex: any) => {
								return {
									onDoubleClick: () => {onOpenChange(record)},
								};
							}}																
							columns={columns} 
							dataSource={dataSource}
						/>
					</Col>					
				</Row>
			</Form>
		</Drawer>
	);
};

export { BaumBeurteilungenDrawer };
export type { Beurteilungen };
//export const MemoizedbaumBeurteilungenDrawer = React.memo(baumBeurteilungenDrawer);