import React, { useCallback, useContext, useEffect, useState } from "react";
//import OlView from 'ol/View';
import OlVectorLayer from 'ol/layer/Vector';
import OlSourceVector  from 'ol/source/Vector';

import {Fill, Stroke, Style, Circle} from 'ol/style';
import 'antd/dist/reset.css';

import { PrinterOutlined   } from '@ant-design/icons';

import { env } from './Env';

import {getPointResolution} from 'ol/proj';
import Feature  from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import { Select as OlSelect, Translate as OlTranslate} from 'ol/interaction';

import SimpleButton from "./UI/SimpleButton"
import MapContext from "../Map/MapContext";
import { Geometry } from "ol/geom";

import OlScaleLine from 'ol/control/ScaleLine';

import { jsPDF } from "jspdf";
import domtoimage, { Options } from 'dom-to-image-more';


import {
    Button, 
	Card,
    Checkbox,
	Col, 
	Drawer, 
	Form,
	Radio,
	Row,
    Select } from 'antd';


import { ObjectEvent } from "ol/Object";
import { Coordinate } from "ol/coordinate";

const dims:{ [typ:string] : {size: [number,number]} }  = {};

dims['a0'] = {size: [1189, 841]};
dims['a1'] = {size: [841, 594]};
dims['a2'] = {size: [594, 420]};
dims['a3'] = {size: [420, 297]};
dims['a4'] = {size: [297, 210]};
dims['a5'] = {size: [210, 148]};


interface printProps {
    setClick: React.Dispatch<React.SetStateAction<boolean>>; 
    style: React.CSSProperties;
};

const Print:React.FC<printProps> = (props) => {  

    const setlog = false;
    setlog && console.log("Print:React.FC");

    const map = useContext(MapContext);
    const [source, setSource] = useState<OlSourceVector|undefined>();
    //const [vector, setVector] = useState<OlVectorLayer<OlSourceVector<Type>>|undefined>();
    const [vector, setVector] = useState<OlVectorLayer<Feature<Geometry>>|undefined>();
    const [printOpen, setPrintOpen] = useState<boolean>(false);

    const [printOrientation, setPrintOrientation] = useState<"portrait" | "landscape">(env.printOrientation);
    const [printSize, setPrintSize] = useState<string>(env.printSize);
    const [printScale, setPrintScale] = useState<number>(env.printScale);
    const [printResolution, setPrintResolution] = useState<number>(env.printResolution);
    const [printRotation, setPrintRotation] = useState<number>(0);
    const [printNorth, setPrintNorth] = useState<boolean>(true);
    const [printRuler, setPrintRuler] = useState<boolean>(true);
    const [printNorthSize, setPrintNorthSize] = useState<number>(10);

    const [select, setSelect] = useState<OlSelect|undefined>(); 
    const [drag, setDrag] = useState<OlTranslate|undefined>(); 

    /*
    const select = new OlSelect({
        layers: [vectorLayer],
    });
    */

    //import { unByKey } from "ol/Observable";
    //const [eventKey, setPrintEventKey] = useState<EventsKey|undefined>(undefined);
    
    //const [, forceUpdate] = useReducer(x => x + 1, 0);

    /*
    const drag = new Translate({
        features: select.getFeatures(),
    });
    */

    useEffect(() => {
        setlog && console.log("Print:React.FC useEffect.[] Intitialisierung");

        const initSource = new OlSourceVector();
        const initVector = new OlVectorLayer({
            source: initSource,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.3)',
                }),
                stroke: new Stroke({
                    color: 'rgb(255,0,0)',
                    width: 4,
                }),
                image: new Circle({
                    radius: 20,
                    fill: new Fill({
                        color: 'rgba(255,0,0,0.3)', 
                    }),
                })			
            })			   
        });    
        
        initVector.set('name', '_Vector');
        initVector.set('label', 'print');
        initVector.set('digi', false);
        initVector.set('select', false);        

        setSource(initSource );
        setVector(initVector);   

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    const rotationHandler = useCallback ( (evt: ObjectEvent)  => {
        setPrintRotation(evt.oldValue);
    },[]);        

    const startPrint = () =>{       
        setlog && console.log('startPrint');
        //props.closeDrawer(false);

        if ( !printOpen ){
            //setlog && console.log('not printOpen');
 
            map?.addLayer(vector!);

            setPrintOpen(true);
            props.setClick(() => (false));
            setPrintRotation(0);

            const initSelect = new OlSelect({
                layers: [vector!],
            });

            const initDrag = new OlTranslate({
                features: initSelect.getFeatures(),
            });
            
            setSelect(initSelect);
            setDrag(initDrag);

            map?.addInteraction(initSelect);
            map?.addInteraction(initDrag);

            map?.getView().on('change:rotation', rotationHandler);

        }
        else{
            endPrint(false);
        }
    }; 
    
    useEffect(() => {
        setlog && console.log("Print:React.FC useEffect.[map, printOpen, printOrientation, printScale, printSize, printResolution, printRotation]");
        //log();
        if ( printOpen ){            
            if ( source ){
                //setlog && console.log('printSource');
                let center:Coordinate|undefined;

                const printFeatuers = source.getFeatures();
                if ( printFeatuers ){        
                    //setlog && console.log('printSource',printFeatuers);    
                    if ( printFeatuers.length === 0){
                        center = map?.getView().getCenter();
                    }
                    else{
                        const printPolygon = printFeatuers[0].getGeometry();
                        const printExtent = printPolygon?.getExtent();            
                        center = [printExtent![0] + (printExtent![2]-printExtent![0])/2,printExtent![1] + (printExtent![3]-printExtent![1])/2];
                    }

                    source.clear();

                    let l,b;
                    if ( printOrientation === 'portrait'){ l=1; b=0; }
                    else{ l=0; b=1; }

                    const inch = 25.4;	
                    const frame = 4;
                    const dim = dims[printSize].size;
                    const width = Math.round(((dim[l]-(2*frame)) * printResolution) / (2*inch));
                    const height = Math.round(((dim[b]-(2*frame)) * printResolution) / (2*inch));
                
                    let rotation:number;
                    rotation = printRotation;
                    if ( printRotation === 0 ){
                        rotation = map!.getView().getRotation();
                    }

                    if ( center ){
                        //setlog && console.log('center: ', center);            
                        var scaleResolution = printScale / getPointResolution( map?.getView().getProjection(), printResolution / inch, center );
                        const printFrame:Feature<Geometry> = new Feature({
                            geometry: new Polygon([[[center[0]-(width*scaleResolution),center[1]+(height*scaleResolution)],
                                    [center[0]+(width*scaleResolution),center[1]+(height*scaleResolution)],
                                    [center[0]+(width*scaleResolution),center[1]-(height*scaleResolution)],
                                    [center[0]-(width*scaleResolution),center[1]-(height*scaleResolution)],
                                    [center[0]-(width*scaleResolution),center[1]+(height*scaleResolution)]]]),
                            }
                        );

                        if ( rotation !== 0){
                            //setlog && console.log('rotation: ', rotation);
                            printFrame.getGeometry()?.rotate(rotation!,center);
                        }                    

                        source.addFeature(printFrame);
                                            
                        //forceUpdate();
                        //setlog && console.log('Ende');
                    }
                }
            }
        }
        // map, source entfernt
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[printOpen, printOrientation, printScale, printSize, printResolution, printRotation]);

    const endPrint = (print:boolean) =>{
        setlog && console.log('endPrint');
        
        //setlog && console.log('getSource');
        if ( source ){
            if ( print ){
                //setlog && console.log('printSource');
                const printFeatuers = source.getFeatures();
                if ( printFeatuers ){
                    const printPolygon = printFeatuers[0].getGeometry();
                    const printExtent = printPolygon?.getExtent();
                    //setlog && console.log('printSource ', printExtent );

                    let l:number;
                    let b:number;
                    if ( printOrientation === 'portrait'){ l=1; b=0; }
                    else{ l=0; b=1; }

                    const inch = 25.4;	
                    const frame = 4;
                    const dim = dims[printSize].size;
                    const width = Math.round(((dim[l]-(2*frame)) * printResolution) / (inch));
                    const height = Math.round(((dim[b]-(2*frame)) * printResolution) / (inch));  
                    const viewResolution = map?.getView().getResolution();
                    
                    const center = [printExtent![0] + (printExtent![2]-printExtent![0])/2,printExtent![1] + (printExtent![3]-printExtent![1])/2];
                    //setlog && console.log('printSource ', center );
                    var scaleResolution = printScale / getPointResolution( map?.getView().getProjection(), printResolution / inch, center  );
                    let rotation = map!.getView().getRotation();
                    //setlog && console.log('rotation ', rotation );
                    let nx:number, ny:number;
                    let dx:number, dy:number;

                    nx = dim[l]-(2*frame)-(printNorthSize*2); 
                    ny = (2*frame);

                    dx = Math.cos(rotation) - Math.sin(rotation);
                    dy = Math.sin(rotation) + Math.cos(rotation);

                    nx = nx - ( ( dy * printNorthSize ) - printNorthSize );
                    ny = ny + ( ( dx * printNorthSize ) - printNorthSize ); 

                    rotation = (-rotation*180)/3.14;
                   // setlog && console.log('rotation ', rotation );
                   // setlog && console.log('printSource map.once');
                    map?.once('rendercomplete', () => {
                        const exportOptions:Options = {
                            filter: function (element: any) {
                                var className = element.className || '';
                                //setlog && console.log('exportOptions: ', className);

                                if ( className.indexOf('ol-attribution') > -1 ) {
                                    //setlog && console.log('ol-attribution');
                                    return true;
                                }

                                if ( className.indexOf('ol-mouse-position') > -1 ) {
                                    //setlog && console.log('ol-mouse-position');
                                    return false;
                                }

                                if ( className.indexOf('ol-scale') > -1 ) {
                                    //setlog && console.log('ol-mouse-position');
                                    return printRuler;
                                }

                                return (
                                    className.indexOf('ol-control') === -1
                                );
                            },
                            width: width, 
                            height: height
                        };
/*
                        domtoimage.toJpeg(map?.getViewport(),exportOptions).then((dataUrl) => {
                            var pdf = new jsPDF(printOrientation, undefined, printSize);
                            pdf.addImage(dataUrl, 'JPEG', frame, frame, dim[l]-(2*frame), dim[b]-(2*frame));
*/
                        domtoimage.toPng(map?.getViewport(),exportOptions).then((dataUrl) => {
                            //domtoimage.toPng(map?.getViewport(),exportOptions).then((dataUrl) => {
                            var pdf = new jsPDF(printOrientation, undefined, printSize);
                            pdf.addImage(dataUrl, 'PNG', frame, frame, dim[l]-(2*frame), dim[b]-(2*frame));
                            //setlog && console.log(dataUrl);
/*    
                        domtoimage.toSvg(map?.getViewport(),exportOptions).then((dataUrl) => {
                            var pdf = new jsPDF(printOrientation, undefined, printSize);
                            setlog && console.log(dataUrl);
*/
                            pdf.addImage(dataUrl, 'SVG', frame, frame, dim[l]-(2*frame), dim[b]-(2*frame));

                            pdf.addImage(env.logoPath, env.logoType, dim[l]-(2*frame)-env.logoSize[0], dim[b]-(2*frame)-(1.5*env.logoSize[1]), env.logoSize[0], env.logoSize[1]); 	 

                            if ( printNorth ){
                                pdf.addImage('north.png','PNG', nx, ny, printNorthSize*2, printNorthSize*2,undefined,undefined,rotation);
                            }

                            pdf.line(frame, frame, dim[l]-frame, frame);
                            pdf.line(dim[l]-frame, frame, dim[l]-frame, dim[b]-frame);
                            pdf.line(dim[l]-frame, dim[b]-frame, frame, dim[b]-frame);
                            pdf.line(frame, dim[b]-frame, frame, frame);
                
                            pdf.save('map.pdf');
                        })
                        .catch( (error) => {
                            setlog && console.log(error);
                        })
                        .finally( () => {
                            map.getControls().forEach(function (control) {
                                if(control instanceof OlScaleLine) { 
                                    control.setDpi(undefined);
                                }
                            });                        

                            map.getTargetElement().style.width = '100vw';
                            map.getTargetElement().style.height = '100vh';
                            map.updateSize();
                            map.getView().setResolution(viewResolution);
                        })
                    //})
                    });
                    
                    if ( map ){
                        //setlog && console.log('Druckvorbereitung ',map.getControls());
                        map.getControls().forEach(function (control) {
                            if(control instanceof OlScaleLine) { 
                                control.setDpi(printResolution);
                            }
                        });
                        map.getTargetElement().style.width = width + 'px';
                        map.getTargetElement().style.height = height + 'px';		
                        map.updateSize();
                        map.getView().setCenter(center);
                        map.getView().setResolution(scaleResolution);
                    }

                }
            }
            
            // Aufräumen
            source.clear();
            if ( map ){
                map.removeLayer(vector!);
                // hat nicht funktioniert ???
                map.removeInteraction(select!);
                map.removeInteraction(drag!);
                //dafür
                //map?.getInteractions().pop();
                map.getView().un('change:rotation', rotationHandler);
            }
            setPrintOpen(false);
            props.setClick(() => (true));
 
            setlog && console.log('print fertig');
        }
    };

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    return (
        <div>            
            <SimpleButton
                type="default"
                style={props.style} 
                shape="circle"
                onClick={startPrint}
                tooltip="Drucken"
                tooltipPlacement="right"
                icon={<PrinterOutlined />}
            />
          <Drawer
                title={"Drucken"}
                className="ggw-print"
                placement="right"
                open={printOpen}
                onClose={() => {endPrint(false)}}
                mask={false}		 
            >
                <Form layout="vertical">
                    <Row gutter={[8,12]}>
                        <Col span={24}>
                            <Card title="Planausstattung">
                                <Col span={24}>
                                    <Checkbox onChange={(e) => {setPrintNorth(e.target.checked)}} checked={printNorth}>Nordpfeil</Checkbox>
                                    <Select onChange={(value) => {setPrintNorthSize(+value)}} defaultValue={printNorthSize.toString()} 
                                        options={[
                                            { value: '5', label: 'sehr klein' },
                                            { value: '10', label: 'klein' },
                                            { value: '20', label: 'groß' },
                                            { value: '40', label: 'sehr groß' },
                                            { value: '80', label: 'mega' },
                                        ]}
                                    />                                
                                </Col>                                
                                <Col span={24}>
                                    <Checkbox onChange={(e) => {setPrintRuler(e.target.checked)}} checked={printRuler}>Maßstab</Checkbox>
                                </Col>                                
                            </Card>
                        </Col>
						<Col span={24}>                            
                            <Card title="Ausrichtung">
                                <Radio.Group onChange={(e) => {setPrintOrientation(e.target.value)}} value={printOrientation}>
                                    <Radio style={radioStyle} value={'portrait'}>Hochformat</Radio>
                                    <Radio style={radioStyle} value={'landscape'}>Querformat</Radio>
                                </Radio.Group>
                            </Card>
                            <Card title="Papierformat">
                                <Select onChange={(value) => {setPrintSize(value)}} defaultValue={printSize} 
                                    options={[
                                        { value: 'a0', label: 'DIN A0' },
                                        { value: 'a1', label: 'DIN A1' },
                                        { value: 'a2', label: 'DIN A2' },
                                        { value: 'a3', label: 'DIN A3' },
                                        { value: 'a4', label: 'DIN A4' },
                                        { value: 'a5', label: 'DIN A5' },
                                    ]}
                                />
                            </Card>
                        </Col>
						<Col span={24}>                            
                            <Card title="Maßstab">
                                <Select onChange={(value) => {setPrintScale(+value)}} defaultValue={printScale.toString()} 
                                    options={[
                                        { value: '10', label: '1 : 10000' },
                                        { value: '7.5', label: '1 : 7500' },
                                        { value: '5', label: '1 : 5000' },
                                        { value: '2.5', label: '1 : 2500' },
                                        { value: '1', label: '1 : 1000' },
                                        { value: '0.75', label: '1 : 750' },
                                        { value: '0.5', label: '1 : 500' },
                                        { value: '0.25', label: '1 : 250' },
                                        { value: '0.1', label: '1 : 100' },
                                    ]}
                                />
                            </Card>
                        </Col>
						<Col span={24}>                            
                            <Card title="Auflösung">
                                <Select onChange={(value) => {setPrintResolution(+value)}} defaultValue={printResolution.toString()} 
                                    options={[
                                        { value: '72', label: '72 dpi (schnell)' },
                                        { value: '150', label: '150 dpi' },
                                        { value: '300', label: '300 dpi (langsam)' },
                                    ]}
                                />
                            </Card>                                                                                                           
                        </Col>                 
                        <Col span={12}>
                            <Button type="link" onClick={() => {endPrint(true)}} block>Drucken</Button>
                        </Col>
						<Col span={12}>                            
                            <Button type="link" onClick={() => {endPrint(false)}} block>Abbruch</Button>
                        </Col>
                    </Row>                    
                </Form>            
            </Drawer>                        
        </div>
    );
};

export {Print};
