//import { type } from '@testing-library/user-event/dist/types/setup/directApi';
import { MapSchemaTypes, envProps } from './EnvProps';


function getAttribute(input: string | undefined): string[] {
    if ( input ){
        if (input.trim() === '') {
            return [];
        }
        const parts = input.split(',').map((part) => part.trim());
        return parts;
    }
    else return [];
};

function getSchemaString(input: Record<string, keyof MapSchemaTypes>): string {
    let ret:string = '';
    if (input)  {for(let key in input) {ret ? ret += ',' + key :  ret = key}};       
    return ret;
};

/* eslint-disable */ 
const env_freigericht:envProps = {
    printOrientation: 'portrait',
    printScale: 0.5,
    printSize: 'a4',
    printResolution: 72,
    zoom: 14,
    center : [510000,5552750],
    //center : [487210,5554770],
    layers : ['osm','boris','hth','bplan','defi','alkis','dop','tswt','bank','schulweg','schule'],
    defaultLayers: ['osm'],
    logoPath : './logo_freigericht.png',
    logoType: 'PNG',
    logoSize: [60,15],
    mailto: 'freigericht@gisgoweb.de',
    alkis: {
        url: '/geoserver/freigericht/',
        wms: 'wms',
        wfs: 'ows',
        schema: 'freigericht:',        
        layer:  'alkis',
        filter: 'flurstueck',
        mail: false,        
        serverType: 'geoserver'
    },
    dop: {
        url:    '/geoserver/freigericht/wms', 
        layer:  'freigericht:DOP_',
        year: '2024;2018',
        serverType: 'geoserver'
    },     
    hth: {
        public: true,
        mail: true,
        url:    'Hundetuetenhalter.geojson'
    },         
    bplan: {
        public: true,
        mail: true,
        url:    'Bebauungsplan.geojson'
    },         
    defi: {
        public: true,
        mail: true,
        url:    'Defibrillatoren.geojson'
    },
    bank: {
        public: true,
        mail: true,
        url:    'Sitzbank.geojson'
    },                                        
    schulweg: {
        public: true,
        mail: true,
        url:    'Schulweg.geojson'
    },             
    schule: {
        public: true,
        mail: true,
        url:    'Schule.geojson'
    },                                          
    attributions : ['<a href="https://www.freigericht.de/impressum/">Impressum</a> | <a href="https://www.freigericht.de/datenschutz/">Datenschutz</a> | ']
}

const env_maintal:envProps = {
    printOrientation: 'portrait',
    printScale: 0.5,
    printSize: 'a4',
    printResolution: 72,
    zoom: 14,
    center : [487210,5554770],
    layers : ['osm','boris','alkis','dop','tswt','baum','nmob','strassenschaden','bplan','defi','hth'],
    defaultLayers: ['osm'],
    logoPath : './logo_maintal.png',
    logoType: 'PNG',
    logoSize: [60,15],
    mailto: 'maintal@gisgoweb.de',
    alkis: {
        url: '/geoserver/maintal/',
        wms: 'wms',
        wfs: 'ows',
        schema: 'maintal:',        
        layer:  'alkis',
        filter: 'flurstueck',
        mail: false,
        serverType: 'geoserver'
    },
    dop: {
        url:    '/geoserver/maintal/wms', 
        layer:  'maintal:DOP_',
        year: '2023;2023_08;2021',
        serverType: 'geoserver'
    },                     
    tswt: {
        public: true,
        url: 'https://v44172.1blu.de:8085',
        database: 'maintal',
        schema: 'dbo',
        tabelle: 'tswt',
        attribute: [{ k:'index',t:'number', s:false, u:false}, { k:'typ',t:'string', s:true, u:true}, { k:'bemerkung',t:'string', s:true, u:true}],
        history: false,
        mail: true,
        dokumente: true
    },
    strassenschaden: {
        public: false,
        url: 'https://v44172.1blu.de:8085',
        database: 'maintal',
        schema: 'dbo',
        tabelle: 'strassenschaden',
        attribute: [{ k:'index',t:'number', s:false, u:false}, { k:'stadtteil',t:'string', s:false, u:true}, { k:'strasse',t:'string', s:false, u:true}, { k:'lage',t:'string', s:true, u:true}, { k:'schaden',t:'string', s:true, u:true}, { k:'groesse',t:'string', s:true, u:true}, { k:'zkl',t:'number', s:true, u:true}, { k:'bemerkung',t:'string', s:true, u:true}, { k:'datum_heute',t:'string', s:false, u:true}, { k:'auftrag_pfad',t:'string', s:false, u:true}, { k:'behoben_am',t:'string', s:false, u:true}],
        history: false,
        mail: true,
        dokumente: true,
        logo: './strscha_logo.png'
    },
    nmob: {
        public: true,
        url: 'https://v44172.1blu.de:8085',
        database: 'maintal',
        schema: 'dbo',
        tabelle: 'nmob',
        attribute: [{ k:'index',t:'number', s:false, u:false}, { k:'projekt',t:'string', s:true, u:true}, { k:'maßnahme',t:'string', s:true, u:true}, { k:'straße',t:'string', s:true, u:true}, { k:'zeitraum',t:'string', s:true, u:true}, { k:'sachstand',t:'string', s:true, u:true}, { k:'beendet',t:'string', s:false, u:true}, { k:'kosten',t:'string', s:true, u:true}, { k:'förderung',t:'string', s:true, u:true}],
        history: false,
        mail: true,
        dokumente: true
    },    
    baum: {
        public: true,
        url: 'https://v44172.1blu.de:8085',
        database: 'maintal',
        schema: 'gruen',
        tabelle: 'baum',
        attribute : [{ k:'index',t:'number', s:false, u:false}, { k:'gisgo',t:'number', s:false, u:false}, { k:'bezeichnung',t:'string', s:true, u:true}, { k:'typ',t:'number', s:false, u:true}, { k:'art',t:'string', s:false, u:true, d:'noch nicht bestimmt'}, { k:'art_de',t:'string', s:false, u:true, d:'-'}, { k:'art_l',t:'string', s:false, u:true, d:'-'}, { k:'jungbaum',t:'boolean', s:true, u:true, d:false}, { k:'bewaessern',t:'boolean', s:true, u:true, d:false}, { k:'gefaellt',t:'boolean', s:true, u:true, d:false}, { k:'strasse',t:'string', s:true, u:true}, { k:'kontrolle',t:'string', s:false, u:true}, { k:'dringlichkeit',t:'number', s:false, u:true}, { k:'bemerkung',t:'string', s:true, u:true},{ k:'bewaessert',t:'string', s:false, u:true}],        
        zustandSchema : [{ k:'index',t:'number', s:false, u:false}, {k:'kontrolle',t:'string', s:false, u:true}, {k:'kontrolleur',t:'string', s:false, u:true}, { k:'zugang',t:'string', s:true, u:true} , {k:'staemme',t:'number', s:true, u:true, d:1}, {k:'hoehe',t:'number', s:true, u:true}, {k:'krone',t:'number', s:true, u:true}, {k:'zustand',t:'number', s:true, u:true}],
        schadenSchema : [{ k:'index',t:'number', s:false, u:false}, { k:'zustandid',t:'number', s:false, u:false}, { k:'umfeld',t:'boolean', s:true, u:true}, { k:'wurzel',t:'boolean', s:true, u:true}, { k:'stammfuß',t:'boolean', s:true, u:true}, { k:'stamm',t:'boolean', s:true, u:true}, { k:'krone',t:'boolean', s:true, u:true},{ k:'schaden',t:'string', s:true, u:true}, { k:'bemerkung',t:'string', s:true, u:true}, { k:'dringlichkeit',t:'number', s:true, u:true} , { k:'massnahme',t:'string', s:true, u:true}, { k:'behoben',t:'string', s:false, u:true}],
        bewaesserungSchema : [{ k:'index',t:'number', s:false, u:false }, { k:'baumid',t:'number', s:false, u:false },{ k:'datum',t:'string', s:true, u:true }, { k:'bemerkung',t:'string', s:true, u:true }, { k:'wasser',t:'number', s:true, u:true }, { k:'fahrer',t:'string', s:false, u:true }],
        beurteilungenSchema : [{ k:'index',t:'number', s:false, u:false }, { k:'baumid',t:'number', s:false, u:false },{ k:'datei',t:'string', s:true, u:true }],
        history: false,
        mail: true,
        dokumente: true
    },
    /*
    hth: {
        public: true,
        mail: true,
        url:    'Hundetuetenhalter.geojson'
    },         
    bplan: {
        public: true,
        mail: true,
        url:    'Bebauungsplan.geojson'
    },         
    defi: {
        public: true,
        mail: true,
        url:    'Defibrillatoren.geojson'
    },
    */                           
    attributions : ['<a href="https://www.maintal.de/impressum/index.php#content">Impressum</a> | <a href="https://www.maintal.de/datenschutz/index.php#content">Datenschutz</a> | ']
}
 
const env_erlensee:envProps = {
    printOrientation: 'portrait',
    printScale: 0.5,
    printSize: 'a2',
    printResolution: 72,
    zoom: 15,
    center : [498050,5556870],
    layers : ['osm'],
    defaultLayers: ['osm'],
    logoPath : './logo_erlensee.png',
    logoType: 'PNG',
    logoSize: [51,14],
    mailto: 'erlensee@gisgoweb.de',
    alkis: {
        url: '/geoserver/freigericht/', 
        wms: 'wms',
        wfs: 'ows',
        schema: 'freigericht:',        
        layer:  'alkis',
        filter: 'flurstueck',
        mail: false,
        serverType: 'geoserver'
    }, 
    dop: {
        url:    '/geoserver/freigericht/wms', 
        layer:  'freigericht:DOP_',
        year: '2018',
        serverType: 'geoserver'
    },
    hth: {
        public: true,
        mail: true,
        url:    'Hundetuetenhalter.geojson'
    },         
    bplan: {
        public: true,
        mail: true,
        url:    'Bebauungsplan.geojson'
    },         
    defi: {
        public: true,
        mail: true,
        url:    'Defibrillatoren.geojson'
    },                                     
    attributions : ['<a href="https://www.erlensee.de/portal/seiten/Seite-900000001-32880.html">Impressum</a> | <a href="https://www.erlensee.de/portal/seiten/Seite-900000002-32880.html">Datenschutz</a> | ']
} 
 /* eslint-disable */ 

export {env_maintal as env,getAttribute, getSchemaString};
//export {env_freigericht as env,getAttribute, getSchemaString};
