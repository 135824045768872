import React, { useContext, useState } from "react";

import { VideoCameraOutlined } from '@ant-design/icons';

import SimpleButton from "./UI/SimpleButton"
import MapContext from "../Map/MapContext";

interface dopProps {
    updateMap: () => void;
    style: React.CSSProperties;
};

const DOP:React.FC<dopProps> = (props) => {  
    const setlog = false;
    const map = useContext(MapContext);
    const [open, setOpen] = useState<boolean>(false);
    const [openOSM, setOpenOSM] = useState<boolean>(false);

    const openCloseDOP = () =>{       
        if ( !open ) {
            setlog && console.log('openDOP');
            map?.getAllLayers().forEach((layer) => {
                if ( layer.get('label') === 'osm' ){
                    setOpenOSM(layer.getVisible());
                    layer.setVisible(false);
                }
                if ( layer.get('label') === 'dop' ){
                    layer.setVisible(true);
                }
            });
            setOpen(true);
        }
        else {
            setlog && console.log('closeDOP');
            map?.getAllLayers().forEach((layer) => {
                if ( layer.get('label') === 'osm' ){
                    if ( openOSM ) {
                        layer.setVisible(true);
                    }
                }
                if ( layer.get('label') === 'dop' ){
                    layer.setVisible(false);
                }                                    
            });            
            setOpen(false);
        }
        //forceUpdate();
        props.updateMap();
    };        

    return (
        <div>            
            <SimpleButton
                type="default"
                style={props.style} 
                shape="circle"
                onClick={openCloseDOP}
                tooltip={"Luftbilder ( Ein / Aus )"} 
                tooltipPlacement="right"
                icon={<VideoCameraOutlined rotate={90}/>}
            />
            
        </div>
    );
};

export default DOP;