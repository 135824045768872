import React, {useContext, useEffect, useRef, useState, ChangeEvent } from "react";
import 'antd/dist/reset.css';

import MapContext from "../../Map/MapContext";
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

import Feature from 'ol/Feature';
import { buffer } from 'ol/extent';

import {
    Button,
    Carousel,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Image,
    Input,
    InputNumber,
    message,
    Modal,
    Pagination,
    Popconfirm,
    Row,
    Select,
    Space,
    Upload, 
	Drawer 
} from 'antd';

import { 
    DeleteOutlined,
    QuestionCircleOutlined,
    UploadOutlined 
} from '@ant-design/icons';

import locale from 'antd/es/date-picker/locale/de_DE';
import 'dayjs/locale/de';
import dayjs, { type Dayjs } from 'dayjs';

import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile, UploadProps, UploadChangeParam } from 'antd/es/upload/interface';
import type { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';

import { array2Schema /*, MapSchema*/} from '../EnvProps';
import { env } from '../Env'; 

import {useSettings} from '../SettingsContext';
//import { Geometry } from "ol/geom";
  
import { useAuth0 } from '@auth0/auth0-react';

import { jsPDF } from "jspdf";
import loadImage from "blueimp-load-image";

import { uploadDokumentAndMail, uploadPublicDokumentePG, downloadDokumentePG, deleteDokumentePG ,downloadAllAttributePG} from '../../Postgres/pg';
//import { Geometry } from "ol/geom";

const strassenschadenSchemaArray = env.strassenschaden ? env.strassenschaden.attribute : [];
const strassenschadenSchema = array2Schema(strassenschadenSchemaArray);
type StrassenschadenSchemaTyp = typeof strassenschadenSchema;
//type Strassenschaden = MapSchema<StrassenschadenSchemaTyp>;
const isKeyStrassenschaden = (k: string): k is keyof StrassenschadenSchemaTyp => k in strassenschadenSchema;

interface strassenschadenDrawerProps {
    url: string;
    closeDrawer: (unclick:Boolean,action:string) => void;
    setClick: React.Dispatch<React.SetStateAction<boolean>>; 
    featuresPos: number;
    featuresCount: number;
    setFeaturesPos: React.Dispatch<React.SetStateAction<number>>;    
    feature: Feature | null;
}  

const StrassenschadenDrawer:React.FC<strassenschadenDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("strassenschadenDrawer:React.FC");
    const map = useContext(MapContext);

    const {settings} = useSettings();
    const [disabled, setDisabled] = useState(true);
    const [touched, setTouched] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null); 
  
    const [changed, setChanged] = useState(false);
    const [attribute, setAttribute] = useState<{[key: string]: any}>({});
    const [dokumente, setDocumente] = useState<Array<{index:number, datei:string}>>([]);
    const [indexDokument, setIndexDokument] = useState(-1);
    const [carousel, setCarousel] = useState(true);

    const [begangen, setBegangen] = useState<Dayjs | null>(null);
    const [behoben, setBehoben] = useState<Dayjs | null>(null);

    const [strassen, setStrassen] = useState<{ strasse:string, gemaname:string, gemename:string }[]>([]);
    const [stadtteil, setStadtteil] = useState<{ gemaname:string, gemename:string }[]>([]);
        
    const {
        isAuthenticated,
        getAccessTokenSilently,
        user
    } = useAuth0();
    const [modal, contextHolder] = Modal.useModal();
        
    const insertDokument = (index:number, datei:string) =>{
        setlog && console.log("StrassenschadenDrawer:React.FC insertDokument", index);
        message.success('Das Bild wurde erfolgreich geladen!');
        setDocumente([...dokumente,{index:index, datei:datei}]);
    };

    const uploadDokument = async ( options: RcCustomRequestOptions): Promise<void> => {
        setlog && console.log("StrassenschadenDrawer:React.FC uploadDokument", options);
        uploadPublicDokumentePG(props.feature!,getAccessTokenSilently,options.file,insertDokument);
    };

    const setDokument = (current:number) =>{
        setlog && console.log("StrassenschadenDrawer:React.FC setDokument", current);
        setIndexDokument(current);
    };

    const deleteDokument = () =>{
        setlog && console.log("StrassenschadenDrawer:React.FC deleteDokument",indexDokument);
        if ( indexDokument !== -1){
            const index = dokumente[indexDokument].index;
            deleteDokumentePG(props.feature!,getAccessTokenSilently,index);
            setDocumente(dokumente.filter(item => item.index !== index));
        }
    };

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj as RcFile);
            reader.onload = () => resolve(reader.result as string);
          });
        }
        //const image = new (window as any).Image();
        const image = new window.Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
      };    


    const onChange = (info: UploadChangeParam) => {
        setlog && console.log("StrassenschadenDrawer:React.FC onChange",info);
    };

    const propsUpload: UploadProps = {
        showUploadList: false,
        customRequest: uploadDokument,
        onPreview: onPreview,
        listType: 'picture',
        onChange: onChange,
    };

    const propsImgCrop = {
        modalTitle: 'Bild bearbeiten',
        modalOk: '...go!',
        modalCancel: 'Abbrechen',
        resetText: 'Zurücksetzen',
        showGrid: true,
        rotationSlider: true,
        aspectSlider: true,
        showReset: true,
    };

    const updateDokumente = (data:any) =>{
        setlog && console.log("StrassenschadenDrawer:React.FC updateDokumente", data);
        setIndexDokument(0);
        setDocumente(data);
    };

    useEffect(() => {
        setlog && console.log("StrassenschadenDrawer:React.FC useEffect.[changed] "+ changed);
        props.setClick(() => (!changed));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changed]);        

    useEffect(() => {
        if (props.feature) {
            if (props.feature.get('label') === 'strassenschaden') {
                setlog && console.log("StrassenschadenDrawer:React.FC useEffect.[props.feature]", props.feature);

                setChanged(false);
                setDocumente([]);
                setIndexDokument(-1);
                if (props.feature) {
                    downloadDokumentePG(props.feature!,updateDokumente);

                    strassenschadenSchemaArray.forEach((a) => {
                        if ( a.u && isKeyStrassenschaden(a.k) ){
                            const value: keyof StrassenschadenSchemaTyp = a.k;                        
                            setAttribute( prevAttribute => { return {...prevAttribute, [value]:props.feature?.get(value)}});
                        }
                    });
                }

				if ( props.feature?.get('datum_heute')){
					setlog && console.log("baumSchadenDrawer:React.FC updateSchaden", props.feature?.get('datum_heute'));
					const b = props.feature?.get('datum_heute');
					if ( typeof b === 'string' ){
						//setBehoben(dayjs(b.slice(6,8)+'.'+b.slice(4,6)+'.'+b.slice(0,4),'DD.MM.YYYY'));
						setBegangen(dayjs(b,'YYYYMMDD'));
					}
				}
				else{
					setBegangen(null);
				}				                

				if ( props.feature?.get('behoben_am')){
					setlog && console.log("baumSchadenDrawer:React.FC updateSchaden", props.feature?.get('behoben_am'));
					const b = props.feature?.get('behoben_am');
					if ( typeof b === 'string' ){
						//setBehoben(dayjs(b.slice(6,8)+'.'+b.slice(4,6)+'.'+b.slice(0,4),'DD.MM.YYYY'));
						setBehoben(dayjs(b,'YYYYMMDD'));
					}
				}
				else{
					setBehoben(null);
				}
            
                const geometry = props.feature.getGeometry();
                const extent = geometry!.getExtent();
                const extentB = buffer(extent, 50);
                map?.getView().fit(extentB, {
                    duration: 2000
                });                                        
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.feature]);    

    const dboSave = () =>{
        setlog && console.log("StrassenschadenDrawer:React.FC dboSave");

        if ( isAuthenticated ){

            strassenschadenSchemaArray.forEach((a) => {
                if ( a.u && isKeyStrassenschaden(a.k) ){
                    props.feature?.set(a.k,attribute[a.k]);
                }
            });
     
            cleanUp();             
            props.closeDrawer(true,'save');
        }
        else {
            cleanUp();
            props.closeDrawer(true,'');
        }
    };

    const closeDrawer = () =>{
        setlog && console.log("StrassenschadenDrawer:React.FC closeDrawer");

        changed ? 
            modal.confirm({
                title: 'Die veränderten Daten wurden noch nicht gespeichert!',
                content: 'Möchten Sie den Dialog wirklich schließen?',
                okText: "...go!",
                okType: "danger",
                cancelText: "Nein",                
                onOk: () => closeDrawerReally(),
                onCancel: () => {},
            }) :  closeDrawerReally();
    };

    const cleanUp = () =>{
        setlog && console.log("StrassenschadenDrawer:React.FC cleanUp");
        setChanged(false);
        setDocumente([]);
        setIndexDokument(-1);
    };

    const closeDrawerReally = () =>{
        setlog && console.log("StrassenschadenDrawer:React.FC closeDrawer");
        cleanUp();
        props.closeDrawer(true,'');
    };

    const dboDeleteReally = () =>{
        setlog && console.log("StrassenschadenDrawer:React.FC dboDeleteReally");
        if ( isAuthenticated ){
            setlog && console.log("StrassenschadenDrawer:React.FC dboDeleteReally",attribute);
            cleanUp();
            props.closeDrawer(true,'delete');
        }
        else {
            cleanUp();
            props.closeDrawer(true,'');
        }            
    };    

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        //setlog && console.log("StrassenschadenDrawer:React.FC omStart");
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };    

    const changeString = (key:string, value:string) =>{
        //setlog && console.log("StrassenschadenDrawer:React.FC changeString", value);
        if ( isAuthenticated ){
            setChanged(true);
            setAttribute( currentState => { return {...currentState, [key]:value}});
        }
    };

	const changeNumber = (key:string, value:number| null) =>{
        setlog && console.log("StrassenschadenDrawer:React.FC changeString", value);
        if ( isAuthenticated ){
            setChanged(true);        
            setAttribute( currentState => { return {...currentState, [key]:value}});
        }
    };	
    
    const changeBoolean = (key:string, value:boolean) =>{
        //setlog && console.log("StrassenschadenDrawer:React.FC changeString", value);
        if ( isAuthenticated ){
            setChanged(true);
            setAttribute( currentState => { return {...currentState, [key]:value}});
        }
    };	

	const changeFeature = (page: number, pageSize: number) => {
        setlog && console.log("StrassenschadenDrawer:React.FC changeFeature", page, pageSize);
        props.setFeaturesPos(page);
    };

    const changeBegangen = (dateString: string)  =>{
        setlog && console.log("baumSchadenDrawer:React.FC changeBegangen", dateString);
		setChanged(true);

		if ( dateString ){
			const newDate = dateString.slice(6,10)+dateString.slice(3,5)+dateString.slice(0,2);		
			setAttribute( currentState => { return {...currentState, datum_heute:newDate}});		
			setBegangen(dayjs(newDate,'YYYYMMDD'));
		}
		else{
			setAttribute( currentState => { return {...currentState, datum_heute:''}});
			setBehoben(null);
		}
    };
    
    const changeBehoben = (dateString: string)  =>{
        setlog && console.log("baumSchadenDrawer:React.FC changeBehoben", dateString);
		setChanged(true);

		if ( dateString ){
			const newDate = dateString.slice(6,10)+dateString.slice(3,5)+dateString.slice(0,2);		
			setAttribute( currentState => { return {...currentState, behoben_am:newDate}});		
			setBehoben(dayjs(newDate,'YYYYMMDD'));
		}
		else{
			setAttribute( currentState => { return {...currentState, behoben_am:''}});
			setBehoben(null);
		}
    };
    const openAuftrag = async () =>{
        if ( attribute.auftrag_pfad ) {
            const url = "./strassenschaden_dokumente/" + attribute.auftrag_pfad;
            window.open(url);
        }
        else {
            await createAuftrag();
        }
    };    

    const createAuftrag = async () =>{
        setlog && console.log("baumSchadenDrawer:React.FC createAuftrag");

        const heute = new Date().toISOString().substring(0, 10);
        const auftrag = heute + '_' + props.feature?.get('index') + '_auftrag.pdf';
        const doc = new jsPDF();
        
        //const image:HTMLImageElement = new window.Image();
        //image.src = env.strassenschaden!.logo;
        //doc.addImage(image, "PNG", 13, 6, 20, 20);
        doc.addImage(env.strassenschaden!.logo, "PNG", 13, 6, 20, 20);

        doc.setFontSize(14);
        doc.text("Eigenbetrieb Betriebshof", 40, 16);
        //doc.save(auftrag);
        doc.setFontSize(11);
        doc.text("FD Tiefbau - Stadt Maintal", 40, 22);			


        let i:number;
        let j:number;
        let h:number;
        let c:number;

        let s1a, s1w, s2a, s2w, s3a, s3w:number;
        let text:string;

        doc.rect(13 ,30, 185, 38);

        s1a = 13 + 2;
        s1w = s1a + 32;
        s2a = 110;
        s2w = s2a + 10;
        s3a = 145;
        s3w = s3a + 18;

        i = 35;
        c = 5;
        text = attribute.stadtteil;
        doc.text("Stadtteil:", s1a, i);
        if ( text ){
            doc.text('Maintal - ' + text, s1w, i);
            switch (text) {
                case 'Bischofsheim':
                    text = '60401-001-502';
                break;
                case 'Dörnigheim':
                    text = '60401-001-501';
                break;
                case 'Hochstadt':
                    text = '60401-001-503';
                break;
                case 'Wachenbuchen':
                    text = '60401-001-504';
                break;
                default:
                    text = '60401-001-';
            }
            doc.setFontSize(20);
            doc.text(text, 150, 24);
            doc.setFontSize(11);
        }
        text = attribute.zkl.toString();
        doc.text("ZKL:", s2a, i);
        if ( text ){
            doc.text(text, s2w, i);
        }
        text = attribute.datum_heute;
        doc.text("Datum:", s3a, i);
        if ( text ){
            doc.text(text.slice(6,8)+'.'+text.slice(4,6)+'.'+text.slice(0,4), s3w, i);
        }									
        i += c;
        text = attribute.strasse;
        doc.text("Straße:", s1a, i);
        if ( text ){
            doc.text(text, s1w, i);
        }
        
        text = props.feature?.get('index').toString();
        doc.text("Index:", s3a, i);
        doc.text(text, s3w, i);

        i += c;
        text = attribute.lage;
        doc.text("Lage / Nr.:", s1a, i);
        if ( text ){
            doc.text(text, s1w, i);
        }
        i += c;
        text = attribute.schaden;
        doc.text("Schaden:", s1a, i);
        if ( text ){
            doc.text(text, s1w, i);
        }
        i += c;
        text = attribute.groesse;
        doc.text("Schadensgröße:", s1a, i);
        if ( text ){
            doc.text(text, s1w, i);
        }						

        i += c;
        text = attribute.massnahme;
        doc.text("Maßnahme:", s1a, i);
        if ( text ){
            doc.text(text, s1w, i);
        }				

        i += c;
        text = attribute.bemerkung;
        doc.text("Bemerkung:", s1a, i);
        if ( text ){
            doc.text(text, s1w, i);
        }	

        i += 5;
        h = 60;
        
        try {
            await dboAddImagesPdf(doc,i,h);
        } catch (error) {
            console.error('Fehler beim Einfügen der Bilder:', error);
        }
        
        doc.rect(13 ,i + h + 2 , 185, (9 + 4)*8 + 4 + 30);

        i = i + h + 2 + 3;
        j = i + 8;
        
        for (let t=0; t < 9; t++) {
            doc.line(13, j, 198, j);
            j = j + 8;
        }

        j = j - 8;
        doc.line(60, i, 60, j);
        doc.line(86, i, 86, j);
        doc.line(105, i, 105, j);
        doc.line(125, i, 125, j);
        doc.line(159, i, 159, j);
        doc.line(178, i, 178, j);

        i = i + 6;
        doc.text('Name', 13 + 2, i);
        doc.text('Datum', 60 + 2, i);
        doc.text('Stunden', 86 + 2, i);
        doc.text('Leist.-Art', 105 + 2, i);
        doc.text('Pkw/Maschinen', 125 + 2, i);
        doc.text('Stunden', 159 + 2, i);
        doc.text('Leist.-Art', 178 + 2, i);

        i = j + 3;
        j = i + 8;
        for (let t=0; t < 4; t++) {
            doc.line(13, j, 198, j);
            j = j + 8;
        }

        j = j - 8;
        doc.line(35, i, 35, j);
        doc.line(105, i, 105, j);
        doc.line(153, i, 153, j);
        
        i = i + 6;
        doc.text('Menge', 13 + 2, i);
        doc.text('Bezeichnung', 35 + 2, i);
        doc.text('Firma/Handlager', 105 + 2, i);
        doc.text('Lieferschein Nr.', 153 + 2, i);
        
        i = j + 4;
        doc.text('Kleinteilpauschale', 13 + 2, i);
        doc.text('ja', 57, i);
        doc.text('nein', 70, i);
        doc.text('Arbeiten die zusätzlich zum Arbeitsauftrag angefallen sind:', 90, i);

        i = 276;
        doc.text('Unterschrift Mitarbeiter*in', 13 + 2, i);
        doc.text('Datum', 80, i);
        doc.text('Unterschrift Auftraggeber*in', 130, i);

        i = 289;
        doc.line(13, i, 198, i);
        
        i += 3;
        text = user!.name!;
        if ( text ){
            doc.setFontSize(8);
            doc.text('Erstellt von ' + text, 130, i);
        }
        let mailTo:string; 
        let href:string; 
 

        mailTo = user!.email!;
        href = props.url + "&search=strassenschaden," +props.feature?.get('index');

        setAttribute( currentState => { return {...currentState, auftrag_pfad:auftrag}});
        setChanged(true);

        modal.confirm({
            title: 'Der Auftrag wurde erstellt!',
            content: 'Wollen Sie den Auftrag per email versenden?',
            okText: "...go!",
            cancelText: "Nein",                
            onOk: () => {uploadDokumentAndMail(props.feature!,getAccessTokenSilently,auftrag, doc.output('blob'),mailTo,href);},
            onCancel: () => {uploadDokumentAndMail(props.feature!,getAccessTokenSilently,auftrag, doc.output('blob'),'','');},
        })
    };
    const dboAddImagesPdf = async (doc:jsPDF, i:number, h:number) => {
            
        let url;
        let b;
        let j = 13;

        for (let t=0; t < dokumente.length; t++) {             					
            url = "./strassenschaden_dokumente/" + dokumente[t].datei;            
            const data = await loadImage(
                url,
                { orientation: true, meta: true, canvas: true, maxHeight: h*10}
            )
            if ( data ){
                b = h * data.originalWidth! / data.originalHeight!;
                if ( j + b < 200 ){
                    if (data.image instanceof HTMLImageElement || data.image instanceof HTMLCanvasElement) {
                        doc.addImage(data.image, "JPEG", j, i, b, h);
                    }
                }
                j = j + b + 2;                    
            }
        };
    };

    const updateStrassen = (data:any) =>{
        setlog && console.log("strassenschadenDrawer:React.FC updateStrassen");        
        const o: { strasse:string, gemaname:string, gemename: string}[] = [];
        const p: { gemaname:string, gemename: string}[] = [];
        if (data.length > 0) {
            const n:number = data.length-1;
            for (let i = 0; i <= n; i++) {
                o.push({
                    strasse: data[i].strasse,
                    gemaname: data[i].gemaname,
                    gemename: data[i].gemename
                });
                if ( !p.some(t => t.gemename === data[i].gemename) ) {
                     p.push({
                        gemaname: data[i].gemaname,
                        gemename: data[i].gemename
                    });
                }
            };				
        }
        setStrassen(o);
        setStadtteil(p);
        setlog && console.log("strassenschadenDrawer:React.FC updateStrassen", o);
                 
    };

    const getStrassen = () =>{
        setlog && console.log("strassenschadenDrawer:React.FC getStrassen");
        isAuthenticated && strassen.length === 0 && downloadAllAttributePG('strassen','strassenschaden','strasse, gemaname, gemename',getAccessTokenSilently,updateStrassen); 
    };

    const changeStrasse = (_value: number, option:any) => {
		setlog && console.log("strassenschadenDrawer:React.FC changeStrasse", option);
        if ( isAuthenticated ){
            setChanged(true);

            if ( attribute.stadtteil ){
                setAttribute( currentState => { return {...currentState, 'strasse':option.value}});
            }
            else {
                setAttribute( currentState => { return {...currentState, 'strasse':option.value, 'stadtteil':option.gemename}});
            }
        }
	};	

    const changeStadtteil = (_value: number, option:any) => {
		setlog && console.log("strassenschadenDrawer:React.FC changeStadtteil", option);
        if ( isAuthenticated ){
            setChanged(true);
            option ? setAttribute( currentState => { return {...currentState, 'stadtteil':option.value}}) : setAttribute( currentState => { return {...currentState, 'stadtteil':null}});
        }
	};	

    const title = (
        <Row gutter={[8,8]} >
            <Col span={12}>
                <div style={{ fontSize: '1.2em' }}>
                    <a id="nmob_mailto" href={"mailto:" + (isAuthenticated ? user?.email : ( env.strassenschaden!.mail ? 'strassenschaden-' + env.mailto : ''))  + "?Subject=Strassenschaden: " +props.feature?.get('index')+ "&body=Hier der Link zum Objekt " + props.url + "%26search=strassenschaden," +props.feature?.get('index')}>
                        {"Strassenschaden "+props.feature?.get('index')}
                    </a>
                </div>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination simple size='small' current={props.featuresPos} pageSize={1} total={props.featuresCount} onChange={changeFeature}/>
            </Col>
        </Row>
    ); 
    
    const body = (
        <Form layout="vertical">
            <Row gutter={[8,8]} >					
                <Col span={24}>
                    <Form.Item key={'stadtteil'} style={{ marginBottom: 4 }} label={'Stadtteil'}>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder={'Bitte wählen Sie einen Stadtteil aus'}
                            value={attribute['stadtteil']}
                            optionFilterProp="children"
                            onFocus={getStrassen}
                            onChange={changeStadtteil}
                            allowClear={true}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                            options={stadtteil.map((stadtteil) => ( {label: stadtteil.gemename , value: stadtteil.gemename}))}
                        />
                    </Form.Item>                                       
                    <Form.Item key={'strasse'} style={{ marginBottom: 4 }} label={'Straße'}>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder={'Bitte wählen Sie eine Strasse aus'}
                            value={attribute['strasse']}
                            optionFilterProp="children"
                            onFocus={getStrassen}
                            onChange={changeStrasse}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                            options={strassen.filter(({ gemename }) => (!attribute['stadtteil'] || gemename === attribute['stadtteil']))
                                .reduce((acc,  obj ) => {
                                    const existingItem = acc.find(item => Object.values(item)[0] === obj.strasse);
                                    if (!existingItem) {
                                        const newObj = { label: obj.strasse, gemename: obj.gemename, value: obj.strasse };
                                        acc.push(newObj as never);
                                    }															
                                    return acc;
                                }, [])
                            }
                        />
                    </Form.Item>                    
                    {
                        strassenschadenSchemaArray.map((a,i) => (
                            (a.s && (
                                (a.t === 'boolean' &&
                                    <Checkbox key={'input'+i} name={a.k} checked={attribute[a.k]} onChange={e => changeBoolean(a.k,e.target.checked)} style={{ marginTop: 6, marginBottom: 4 }}>
                                        {a.k.charAt(0).toUpperCase() + a.k.slice(1)}
                                    </Checkbox>
                                ) || (a.t === "number" &&
                                    <Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.k.charAt(0).toUpperCase() + a.k.slice(1)}>
                                        <InputNumber key={'input'+i} name={a.k} value={attribute[a.k]} style={{ width: '100%' }} onChange={(value: number | null) => changeNumber(a.k,value)} />
                                    </Form.Item>										
                                ) || (
                                    <Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.k.charAt(0).toUpperCase() + a.k.slice(1)}>
                                        <Input key={'input'+i} name={a.k} value={attribute[a.k]} onChange={(e: ChangeEvent<HTMLInputElement>) => changeString(a.k,e.currentTarget.value)} />
                                    </Form.Item>
                                )
                            ))
                        ))
                    }

                    <Form.Item key={'begangen'} style={{ marginBottom: 4 }} label='Begangen'>
						<DatePicker value={begangen ? begangen : null} format={['DD.MM.YYYY']} onChange={(_, dateString) => isAuthenticated && changeBegangen(dateString as string /*!API*/)} locale={locale} style={{ width: '100%' }} />					
					</Form.Item>    

                    <Form.Item  label={<Button style={{ paddingTop: 0, paddingBottom: 0 }} onClick={openAuftrag} >Auftrag</Button>}>
                        <Input value={attribute.auftrag_pfad} onChange={(e: ChangeEvent<HTMLInputElement>) => changeString('auftrag_pfad',e.currentTarget.value)} placeholder="Bitte verwenden Sie den Auftragsbutton zur Erstellung!" allowClear={true} />
                    </Form.Item>                                    

                    <Form.Item style={{ marginBottom: 4 }} label={"Bilder (" + dokumente.length  + ")"}>
                        {dokumente.length === 0 ? (
                            <Image
                                preview = {false}
                                key = 'image_default'
                                src = "./strassenschaden_dokumente/default.jpg"
                            />
                        ) : (
                            <Image.PreviewGroup >
                                <Carousel autoplay={carousel} effect="fade" infinite={false} afterChange={setDokument} style={{width: '90%'}}>
                                    { dokumente.map((element) => (
                                        <Image
                                            key = {'image'+element.index}
                                            src = { "./strassenschaden_dokumente/" + element.datei}
                                            fallback = "./strassenschaden_dokumente/default.jpg"
                                        />
                                    ))}
                                </Carousel>
                            </Image.PreviewGroup>
                        )}                                               
                    </Form.Item>      

                    { isAuthenticated && (
                        <Space>
                            <ImgCrop {...propsImgCrop}>
                                <Upload {...propsUpload}>
                                    <Button icon={<UploadOutlined />} />             
                                </Upload>
                            </ImgCrop>


                            <Popconfirm
                                onPopupClick={() => setCarousel(true)} 
                                onConfirm={deleteDokument}
                                title={"Wollen Sie das Bild wirklich löschen?"} 
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                okText="...go!"
                                okType="danger"
                                cancelText="Nein"
                            >
                                <Button icon={<DeleteOutlined />} onClick={() => setCarousel(false)}  />
                            </Popconfirm>						
                        </Space>
                    )}

                    <Form.Item key={'behoben'} style={{ marginBottom: 4 }} label='Behoben'>
							<DatePicker value={behoben ? behoben : null} format={['DD.MM.YYYY']} onChange={(_, dateString) => isAuthenticated && changeBehoben(dateString as string /*!API*/)} locale={locale} style={{ width: '100%' }} />					
					</Form.Item>                                
                </Col>
            </Row>
        </Form>            
    );
        
    if ( !settings.mobile ){
        return (
            <div> {contextHolder} 
                <Drawer
                    title={title}
                    className="ggw-strassenschaden"
                    placement="right"
                    open={props.feature?.get('label')==='strassenschaden'}
                    onClose={closeDrawer}
                    mask={false}
                    footer={
                        isAuthenticated && (
                            <Row gutter={[8,8]} >
                                <Col span={12}>
                                    <Button onClick={dboSave} disabled={!changed} type="primary" block>Speichern</Button>	
                                </Col>
                                <Col span={12}>
                                    <Popconfirm 
                                        onConfirm={dboDeleteReally}
                                        title={"Wollen Sie den Strassenschaden wirklich löschen?"} 
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        okText="...go!"
                                        okType="danger"
                                        cancelText="Nein"
                                    >
                                        <Button type="link" block>Löschen</Button>
                                    </Popconfirm>						
                                </Col>
                            </Row>
                        )
                    }                                
                >
                    {body}
                </Drawer>
            </div>
        );
    }
    else {
        return (
            <div> {contextHolder} 
                <Modal
                    title={ <div
                        style={{
                            width: '100%',
                            cursor: 'move',
                        }}                                    
                        onMouseOver={() => {disabled && setDisabled(false);}}
                        onMouseOut={() => {setDisabled(true);}}
                        onTouchStart={() => {touched ? setDisabled(true) : setTouched(false);}}
                        onTouchEnd={() => {disabled ? setTouched(true) : setDisabled(false);}}
                        onTouchCancel={() => {!disabled && setDisabled(true);}}                    
                        >
                            {title}
                        </div>
                    }
                    open={props.feature?.get('label')==='strassenschaden'}
                    onCancel={closeDrawer}
                    footer={[                  
                        isAuthenticated && (
                            <Row key='f_r' gutter={[8,8]} >
                                <Col key='f_c1' span={12}>
                                    <Button key='f_save' onClick={dboSave} type="primary" block>Speichern</Button>	
                                </Col>
                                <Col key='f_c2' span={12}>
                                    <Popconfirm
                                        key='popconfirm' 
                                        onConfirm={dboDeleteReally}
                                        title={"Wollen Sie den Strassenschaden wirklich löschen?"} 
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        okText="...go!"
                                        okType="danger"
                                        cancelText="Nein"
                                    >
                                        <Button key='f_delete' type="link" block>Löschen</Button>
                                    </Popconfirm>						
                                </Col>
                            </Row>
                        )
                    ]}
                    maskClosable={false}
                    modalRender={(modal) => (
                        <Draggable
                            disabled={disabled}
                            bounds={bounds}
                            onStart={(event, uiData) => onStart(event, uiData)}
                        >
                            <div ref={draggleRef}>{modal}</div>
                        </Draggable>
                    )}                   
                >
                    {body}
                </Modal>
            </div>
        );
    }        
};

export default StrassenschadenDrawer;
//export const MemoizedstrassenschadenDrawer = React.memo(StrassenschadenDrawer);