import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import OlVectorLayer from 'ol/layer/Vector';
import OlVectorSource  from 'ol/source/Vector';

import 'antd/dist/reset.css';

import { FormOutlined  } from '@ant-design/icons';

import Feature, { FeatureLike } from 'ol/Feature';
import type {Type as OlType}  from 'ol/geom/Geometry';
import Polygon from 'ol/geom/Polygon';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString'
import { Draw as OlDraw, Modify as OlModify, Snap as OlSnap } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import {Circle as CircleStyle, Fill, RegularShape, Stroke, Style, Text} from 'ol/style';
import {getArea, getLength} from 'ol/sphere';

import { useAuth0 } from '@auth0/auth0-react';

import SimpleButton from "./UI/SimpleButton"
import MapContext from "../Map/MapContext";
import {useSettings} from '../Components/SettingsContext';
import { newPG, geometriePG } from '../Postgres/pg';
import { Geometry  } from "ol/geom";

import { envProps, pgAttribut } from '../Components/EnvProps';
import { env } from '../Components/Env';

import {
    Button, 
	Card,
    Checkbox,
	Col, 
	Drawer, 
	Form,
    Modal  ,
	Radio,
	Row } from 'antd';
//import { Type } from "ol/centerconstraint";

interface measureProps {
    setClick: React.Dispatch<React.SetStateAction<boolean>>; 
    newFeature: (feature:Feature,tabelle:string) => void;
    style: React.CSSProperties;
};

const Measure:React.FC<measureProps> = (props) => {  
    const setlog = false;
    setlog && console.log('Measure:React.FC');
    
    const map = useContext(MapContext);
	const vectorLayerRef = useRef<OlVectorLayer<Feature<Geometry>> | null>(null);
    const vectorSourceRef = useRef<OlVectorSource | null>(null);	

    const {settings} = useSettings();
    const [measureOpen, setMeasureOpen] = useState<boolean>(false);
    const {
        isAuthenticated,
        getAccessTokenSilently
    } = useAuth0();    
    
    //const [source, setSource] = useState<OlVectorSource|undefined>();
    //const vectorRef = useRef<OlVectorLayer<OlVectorSource<Feature<Geometry>>> | null>(null);
    const [vector, setVector] = useState<OlVectorLayer<Feature<Geometry>>|undefined>();
    
    const draw = useRef<OlDraw | null>(null);
    const snap = useRef<OlSnap | null>(null);
    const modify = useRef<OlModify | null>(null);
    const modifyMove = useRef<OlModify | null>(null);
    
    const [measureType, setMeasureType] = useState<OlType>('LineString');
    const [showSegments, setShowSegments] = useState<boolean>(true);
    const [digi, setDigi] = useState<boolean>(false);
    const [move, setMove] = useState<boolean>(false);
    const [label, setLabel] = useState<string>('');
   
    const [clearPrevious, setClearPrevious] = useState<boolean>(false);
    const [clearAll, setClearAll] = useState<boolean>(false);
    const [stayVisible, setStayVisible] = useState<boolean>(false);
    const [snapActive, setSnapActive] = useState<boolean>(true);
    
    const [isDigiOpen, setIsDigiOpen] = useState(false);

    //const [, forceUpdate] = useReducer(x => x + 1, 0);
    
    const startMeasure = () =>{       
        setlog && console.log('startMeasure',!measureOpen);

        if ( measureOpen && map ){
            if ( draw.current) {
                setlog && console.log("Measure:React.FC startMeasure - Aufräumen draw");
                draw.current.un('drawstart', drawstartFunction);
                draw.current.un('drawend', drawendFunction);
                map.removeInteraction(draw.current);
                draw.current = null;
            }
            if ( snap.current) {
                setlog && console.log("Measure:React.FC startMeasure - Aufräumen snap");
                map.removeInteraction(snap.current);
                snap.current = null;
            }                  
            if ( modify.current) {
                setlog && console.log("Measure:React.FC startMeasure - Aufräumen snap");
                map.removeInteraction(modify.current);
                modify.current = null;
            }                              
        }

        setMeasureOpen(!measureOpen);
        setMove(false);

    }; 

    const digiOk = () => {
        setlog && console.log("Measure:React.FC digiOK");

        map?.getAllLayers().forEach((l) => {
            if ( l.get('name') === settings.digi ){
                setlog && console.log("Measure:React.FC digiOK", settings.digi);

                if (l.getSource() instanceof OlVectorSource) {
                    const targetSource:OlVectorSource = l.getSource() as OlVectorSource;
                    setlog && console.log("Measure:React.FC digiOK", targetSource);

                    const ff = vectorSourceRef.current?.getFeatures();
                    if ( ff ) {
                        const f:Feature|undefined = ff[ff.length-1];
                        targetSource.addFeature(f!);
                        props.newFeature(f!,settings.digi);
                        f?.setProperties({index: -1});
                        f?.setProperties({label: l.get('label')});
                        
                        if ( env.hasOwnProperty(label) ) {
                            const featureEnv:any = env[label as keyof envProps];
                            if ( featureEnv ){
                                const attributeListe:Array<pgAttribut> = featureEnv?.attribute; 
                                attributeListe.forEach((a) => {
                                    a.hasOwnProperty('d') ? f?.setProperties({[a.k]:a.d}) : f?.setProperties({[a.k]:null});                                    
                                });
                            }
                        }

                        newPG(f!,getAccessTokenSilently);
                        vectorSourceRef.current?.removeFeature(f);
                    }
                }
            }
        });
        setIsDigiOpen(false);
    };
    
    const digiCancel = () => {
        setlog && console.log("Measure:React.FC digiCancel");

        const ff = vectorSourceRef.current?.getFeatures();
        if ( ff ) {
            const f:Feature|undefined = ff[ff.length-1];
            vectorSourceRef.current?.removeFeature(f);
        }
        setIsDigiOpen(false);
    };
    
    const formatLength = useCallback((line:any) => {
        const length = getLength(line);
        let output;
        if (length > 100) {
          output = Math.round((length / 1000) * 100) / 100 + ' km';
        } else {
          output = Math.round(length * 100) / 100 + ' m';
        }
        return output;
    },[]);
      
    const formatArea = useCallback((polygon:any) => {
        const area = getArea(polygon);
        let output;
        if (area > 10000) {
          output = Math.round((area / 1000000) * 100) / 100 + ' km\xB2';
        } else {
          output = Math.round(area * 100) / 100 + ' m\xB2';
        }
        return output;
    },[]);

    const modifyStyle = new Style({
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          fill: new Fill({
            color: 'rgba(255, 0, 0, 0.9)',
          }),
        }),
        text: new Text({
          text: 'Verziehe mich!',
          font: '12px Calibri,sans-serif',
          fill: new Fill({
            color: '#ffa96a',
          }),
          backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          padding: [2, 2, 2, 2],
          textAlign: 'left',
          offsetX: 15,
        }),
    });

    const labelStyle = new Style({
        text: new Text({
          font: '14px Calibri,sans-serif',
          fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
          }),
          backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          padding: [3, 3, 3, 3],
          textBaseline: 'bottom',
          offsetY: -15,
        }),
        image: new RegularShape({
          radius: 8,
          points: 3,
          angle: Math.PI,
          displacement: [0, 10],
          fill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
        }),
    });
      
    const style = new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 0.5)',
          lineDash: [10, 10],
          width: 5,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
        }),
    });

    const segmentStyle = new Style({
        text: new Text({
          font: '12px Calibri,sans-serif',
          fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
          }),
          backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
          }),
          padding: [2, 2, 2, 2],
          textBaseline: 'bottom',
          offsetY: -12,
        }),
        image: new RegularShape({
          radius: 6,
          points: 3,
          angle: Math.PI,
          displacement: [0, 8],
          fill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
          }),
        }),
    });
      
    const segmentStyles = [segmentStyle];        

    const styleFunction = (feature:Feature, segments:boolean) => {
        //setlog && console.log("Measure:styleFunction: " + feature);
        //setlog && console.log("Measure:styleFunction: " + segments);
        //setlog && console.log("Measure:styleFunction: " + drawType);

        const styles = [style];

        const geometry:Geometry|undefined = feature.getGeometry();
        
        let point, label, line;

        if ( geometry ){
            const type:string = geometry.getType();
            //setlog && console.log("Measure:styleFunction: ", type);
            
            if (type === 'Polygon') {
                point = (geometry as Polygon).getInteriorPoint();
                label = formatArea(geometry);
                line = new LineString( (geometry as Polygon).getCoordinates()[0]);
            } else if (type === 'LineString') {
                point = new Point( (geometry as LineString).getLastCoordinate());
                label = formatLength(geometry);
                line = geometry;
            }

            if ( segments && line ){
                let count = 0;
                (line as LineString).forEachSegment(function (a, b) {
                  const segment = new LineString([a, b]);
                  const label = formatLength(segment);
                  if (segmentStyles.length - 1 < count) {
                    segmentStyles.push(segmentStyle.clone());
                  }
                  const segmentPoint = new Point(segment.getCoordinateAt(0.5));
                  segmentStyles[count].setGeometry(segmentPoint);
                  segmentStyles[count].getText()?.setText(label);                  
                  styles.push(segmentStyles[count]);
                  count++;
                });                
            }

            if ( point ){
                labelStyle.setGeometry(point);
                labelStyle.getText()?.setText(label);
                styles.push(labelStyle);  
            }
        }
        return styles;
    };           

    useEffect(() => {
        setlog && console.log("Measure:React.FC useEffect.[map]");

        const vectorSource = new OlVectorSource();
        const vectorLayer = new OlVectorLayer({
            source: vectorSource,
            style: (feature:FeatureLike) => styleFunction(feature as Feature, showSegments),        
        });    
        
        vectorLayer.set('name', 'Messen');
        vectorLayer.set('label', 'measure');

		vectorLayerRef.current = vectorLayer;
        vectorSourceRef.current = vectorSource;                            
        
        if ( map ){
            setlog && console.log("Measure:React.FC useEffect.[map] - addLayer")
            map.addLayer(vectorLayer);
            vectorLayer.setVisible(false);
         
            map?.getAllLayers().forEach((l) => {
                if ( l.get('label') ){
                    if ( l.get('name') === settings.digi ){
                        setLabel(l.get('label'));
                    }
                }
            });                           
        }

        return () => {
            setlog && console.log("Measure:React.FC useEffect.[map] Aufräumen");
            map?.removeLayer(vectorLayerRef.current!);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[map]);   
    
    useEffect(() => {
        if ( map && vectorSourceRef.current && measureOpen){
            setlog && console.log("Measure:React.FC useEffect.[measureOpen,measureType,settings.digi,showSegments,digi] ");

            vectorLayerRef.current && vectorLayerRef.current.setStyle((feature:FeatureLike) => styleFunction(feature as Feature, showSegments));

            const initDraw = new OlDraw({
                type: measureType,
                source: vectorSourceRef.current,
                style: (feature:FeatureLike) => styleFunction(feature as Feature, showSegments),        
            });              

            if (draw.current) {
                draw.current.un('drawstart', drawstartFunction);
                draw.current.un('drawend', drawendFunction);
                map.removeInteraction(draw.current);
            }            
           
            initDraw.on('drawstart', drawstartFunction);
            initDraw.on('drawend', drawendFunction);  
            map.addInteraction(initDraw);
            initDraw.setActive(true);       
            draw.current = initDraw;

            if (snap.current) {
                map.removeInteraction(snap.current);
            }           

            if ( snapActive ) {
                const initSnap = new OlSnap({
                    source: vectorSourceRef.current,
                    pixelTolerance: 50, 
                });

                map.addInteraction(initSnap);
                initSnap.setActive(true);
                snap.current = initSnap;
                
                if ( !digi ){
                    setlog && console.log("Measure:React.FC useEffect.[measureOpen,measureType,settings.digi,showSegments,digi] - modify");
                    const initModify = new OlModify({
                        source: vectorSourceRef.current, 
                        style: modifyStyle
                    });
                    if (modify.current) {
                        map.removeInteraction(modify.current);
                    }                       
                    map.addInteraction(initModify);
                    initModify.setActive(true);
                    modify.current = initModify;
                }                     
            }
        }
        
        return () => {
            if (map ) {
                if ( draw.current) {
                    setlog && console.log("Measure:React.FC useEffect.[measureOpen,measureType,settings.digi,showSegments,digi] - Aufräumen draw");
                    draw.current.un('drawstart', drawstartFunction);
                    draw.current.un('drawend', drawendFunction);
                    map.removeInteraction(draw.current);
                    draw.current = null;
                }
                if ( snap.current) {
                    setlog && console.log("Measure:React.FC useEffect.[measureOpen,measureType,settings.digi,showSegments,digi] - Aufräumen snap");
                    map.removeInteraction(snap.current);
                    snap.current = null;
                }                  
                if ( modify.current) {
                    setlog && console.log("Measure:React.FC useEffect.[measureOpen,measureType,settings.digi,showSegments,digi] - Aufräumen snap");
                    map.removeInteraction(modify.current);
                    modify.current = null;
                }                  
            }
        }                   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measureOpen,measureType,settings.digi,showSegments,snapActive,clearPrevious,digi]);    
    
    useEffect(() => {
        setlog && console.log("Measure:React.FC useEffect.[measureOpen] "+ measureOpen);
        if ( measureOpen ){
            vectorLayerRef.current!.setVisible(true); 
        } else {
            if ( clearAll ) {
                vectorSourceRef.current!.clear();
            }
            if ( !stayVisible ){
                vectorLayerRef.current!.setVisible(false);
            }            
        }
        props.setClick(() => (!measureOpen));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measureOpen]);
   
    useEffect(() => {
        setlog && console.log("Measure:React.FC useEffect.[settings.digi] "+ settings.digi);
        map?.getAllLayers().forEach((l) => {
            if ( l.get('label') ){
                if ( l.get('name') === settings.digi ){
                    setlog && console.log('Measure:React.FC useEffect.[settings.digi] ',l.get('name'));
                    setLabel(l.get('label'));
                    setMeasureType(l.get('type'));
                }
            }
        });       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.digi]);
    

    useEffect(() => {
        setlog && console.log('Measure:React.FC useEffect.[digi]', digi);
   
        if ( digi ){
            if ( clearPrevious ){
                if ( vectorSourceRef.current ){
                    vectorSourceRef.current.clear();
                }
            }
            map?.getAllLayers().forEach((l) => {
                if ( l.get('name') === settings.digi ){
                    setlog && console.log('Measure:React.FC changeDigi: ',l.get('name'));
                    setMeasureType(l.get('type'));
                }
            });
            move && setMove(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps               
    }, [digi]);

    useEffect(() => {
        setlog && console.log('Measure:React.FC useEffect.[move]', move);
  
        if ( move ){
   
            map?.getAllLayers().forEach((l) => {
                if ( l.get('name') === settings.digi ){
                    setlog && console.log('Measure: changeMove: ',l.get('name'), l.get('label'));

                    if (l.getSource() instanceof OlVectorSource) {
                        setMeasureType(l.get('type'));

                        const vectorSource = l.getSource() as OlVectorSource;
                        const vectorLayer = new OlVectorLayer({
                            source: vectorSource,
                            style: (feature:FeatureLike) => styleFunction(feature as Feature, showSegments),        
                        });  
                        
                        vectorLayer.set('name', '_Overlay');
                        vectorLayer.set('label', '');
                        vectorLayer.set('digi', false);
                        vectorLayer.set('select', false);                        
                        
                        //setSource(vectorSource);
                        setVector(vectorLayer);
                        

                        if (modifyMove.current) {
                            modifyMove.current.un('modifyend', modifyendFunction);
                            modifyMove.current.setActive(false);
                            map.removeInteraction(modifyMove.current);
                        }            
                        
                        const initModifyMove = new OlModify({
                            source: vectorSource, 
                            style: modifyStyle
                        });
                                                
                        map?.addInteraction(initModifyMove!);                    
                        initModifyMove.on('modifyend', modifyendFunction);
                        modifyMove.current = initModifyMove;                                                    
                    }
                }
                digi && setDigi(false);
            });       
        }
        else {
            vector && map?.removeLayer(vector);
            //setSource(undefined);
            setVector(undefined);

            if ( modifyMove.current) {
                modifyMove.current.un('modifyend', modifyendFunction);       
                modifyMove.current.setActive(false);
                map?.removeInteraction(modifyMove.current); 
            }           
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps               
    }, [move]);

    const drawstartFunction = (evt: DrawEvent) => {
        setlog && console.log("Measure:React.FC drawstartFunction", label,digi);
        if ( !move ){
            //setlog && console.log("Measure:React.FC drawstartFunction clearPrevious", clearPrevious);
            if (clearPrevious) {
                map?.getAllLayers().forEach((l) => {
                   if ( l.get('label') === 'measure' ){
                        //setlog && console.log("Measure:React.FC drawstartFunction measure");        
                        if (l.getSource() instanceof OlVectorSource) {
                            const targetSource:OlVectorSource = l.getSource() as OlVectorSource;
                            const ff = targetSource?.getFeatures();
                            if ( ff ) {
                                const f:Feature|undefined = ff[ff.length-1];
                                targetSource?.removeFeature(f);
                            }
                        }
                    }
                });                    
            }
        }
    };  

    const drawendFunction = (evt: DrawEvent) => {
        //setlog && console.log("Measure:React.FC drawendFunction", label,digi);
        if ( digi ){ 
            setlog && console.log("Measure:React.FC drawendFunction", label,digi);
            setIsDigiOpen(digi);
        }
    };
    
    const modifyendFunction = (event: ModifyEvent) => {
        const features = event.features.getArray();
        features.forEach((feature) => {
            setlog && console.log("Measure:React.FC modifyendFunction ",label,  event.mapBrowserEvent.coordinate);
            feature?.setProperties({label: label});
            geometriePG(feature!,getAccessTokenSilently);                    
        });
    };        

    const changeMeasureType = (type:OlType) => {
        setDigi(false);
        setMove(false);
        setMeasureType(type);
    };

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    return (
        <div>            
            <SimpleButton
                type="default"
                style={props.style} 
                shape="circle"
                onClick={startMeasure}
                tooltip="Messen"
                tooltipPlacement="right"
                icon={<FormOutlined />}
            />
            <Drawer
                title={"Messen"}
                className="ggw-measure"
                placement="right"
                open={measureOpen}
                onClose={startMeasure}
                mask={false}		 
            >
                <Form layout="vertical">
                    <Row gutter={[8,16]}>
                        <Col span={24}>
                            <Card title="Geometrie">
                                <Radio.Group onChange={(e) => {changeMeasureType(e.target.value)}} value={measureType}>
                                    <Radio style={radioStyle} value={'Point'} disabled={!isAuthenticated}>Punkt</Radio>
                                    <Radio style={radioStyle} value={'LineString'}>Linie</Radio>
                                    <Radio style={radioStyle} value={'Polygon'}>Polygon</Radio>
                                </Radio.Group>
                            </Card>
                        </Col>
                        { isAuthenticated && (
                            <Col span={24}>
                                <Card title="Digitalisieren">
                                   <Col span={24}>
                                        <Checkbox onChange={(e) => {setDigi(e.target.checked)}} disabled={settings.digi.length===0} checked={digi}>{settings.digi} erstellen</Checkbox>
                                    </Col>                                                                 
                                </Card>
                            </Col>                                    
                        ) } 
                       { isAuthenticated && (
                            <Col span={24}>
                                <Card title="Verziehen">
                                   <Col span={24}>
                                        <Checkbox onChange={(e) => {setMove(e.target.checked)}} disabled={settings.digi.length===0} checked={move}>{settings.digi} verziehen</Checkbox>
                                    </Col>                                                                 
                                </Card>
                            </Col>                                    
                        ) }                                                
						<Col span={24}>                            
                            <Card title="Optionen">
                                <Row gutter={[8,8]}>
                                    <Col span={24}>
                                        <Checkbox onChange={(e) => { setShowSegments(e.target.checked) }} checked={showSegments}>Teillängenbeschriftung</Checkbox>
                                    </Col>                                
                                    <Col span={24}>
                                        <Checkbox onChange={(e) => { setSnapActive(e.target.checked) }} checked={snapActive}>Einrastfunktion</Checkbox>
                                    </Col>                                    
                                    <Col span={24}>
                                        <Checkbox onChange={(e) => { setClearPrevious(e.target.checked) }} checked={clearPrevious}>Letzte Messung löschen</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox onChange={(e) => { setClearAll(e.target.checked) }} checked={clearAll}>Beim Beenden alle Messungen löschen</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox onChange={(e) => { setStayVisible(e.target.checked);e.target.checked && setClearAll(false); }} checked={stayVisible}>Nach dem Beenden bleiben die Messungen sichtbar</Checkbox>
                                    </Col>                                    
                                </Row>                                
                            </Card>

                        </Col>
						<Col span={24}>                            
                            <Button type="link" onClick={startMeasure} block>...go!</Button>
                        </Col>
                    </Row> 
                </Form>            
            </Drawer>
            <Modal 
                title={settings.digi + ' erstellen?'} 
                open={isDigiOpen}
                closable={false} 
                onOk={digiOk} 
                onCancel={digiCancel} 
                okText='Erstellen' 
                cancelText='Abbrechen'>
            </Modal>
        </div>
    );
};

export {Measure};
