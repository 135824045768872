import React, { useEffect, useReducer, useRef, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import Div100vh from 'react-div-100vh'

import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlSourceVector  from 'ol/source/Vector';

import { createStringXY as OlcreateStringXY } from 'ol/coordinate';

import OlScaleLine from 'ol/control/ScaleLine';
import OlMousePosition from 'ol/control/MousePosition';
import { defaults as OldefaultControls, Attribution as OlAttribution } from 'ol/control';
import OlMapBrowserEvent from 'ol/MapBrowserEvent';
import {
    defaults as defaultInteractions,
    DragRotateAndZoom as OlDragRotateAndZoom,
  } from 'ol/interaction';

import OlLayer from 'ol/layer/Layer';
//import OlTileLayer from 'ol/layer/Tile';
import OlTileWMSSource from 'ol/source/TileWMS';
import OlFeature , { FeatureLike } from 'ol/Feature';
import OlFeatureRenderer from 'ol/render/Feature';
import OlGeometry from 'ol/geom/Geometry';
import OlGeoJSON from 'ol/format/GeoJSON';
//import WKT from 'ol/format/WKT';

import OSMLayer  from '../Layers/OSM';

import DOP  from '../Components/DOP';
import DOPLayer from '../Layers/DOP';

import HthDrawer  from '../Components/Hth';
import HthLayer from '../Layers/Hth';
import DefiDrawer  from '../Components/Defi';
import DefiLayer from '../Layers/Defi';
import BankDrawer  from '../Components/Bank';
import BankLayer from '../Layers/Bank';
import SchuleDrawer  from '../Components/Schule';
import SchuleLayer from '../Layers/Schule';
import SchulwegDrawer  from '../Components/Schulweg';
import SchulwegLayer from '../Layers/Schulweg';
import TswtDrawer from '../Components/DBO/Tswt';
import TswtLayer from '../Layers/Tswt';
import StrassenschadenDrawer  from '../Components/DBO/Strassenschaden';
import StrassenschadenLayer from '../Layers/Strassenschaden';
import NMobDrawer  from '../Components/DBO/NMob';
import NMobLayer from '../Layers/NMob';
import BaumDrawer  from '../Components/Gruen/Baum';
import BaumLayer from '../Layers/Baum';
import BPlanDrawer from '../Components/BPlan';
import BPlanLayer from '../Layers/BPlan';
import AlkisDrawer  from '../Components/ALKIS';
import AlkisLayer from '../Layers/Alkis';
//import { selectLayer } from '../Layers/Select';
import BorisLayer  from '../Layers/Boris';


//import { envProps } from '../Components/EnvProps';
import { env /*, getAttribute */} from '../Components/Env';
import { Settings } from '../Components/Settings';
import { Legende } from '../Components/Legende';
import { Search } from '../Components/Search';
import { Home } from '../Components/Home';
import { Logout } from '../Components/Logout';
import { Print } from '../Components/Print';
import { Measure } from '../Components/Measure';
import { Geolocation } from '../Components/Geolocation';

//Proj4
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';

import './Map.css';
import 'ol/ol.css';

import {
    ConfigProvider,
//    notification,
    theme 
} from 'antd';

import MapContext from './MapContext';
import {useSettings} from '../Components/SettingsContext';

import { useAuth0 } from '@auth0/auth0-react';

import { savePG, deletePG, getWFSwithFilter } from '../Postgres/pg';

proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
register(proj4);

const Map: React.FC = () => {
    const setlog = false;
    setlog && console.log("Map:React.FC");
    const mapElement = useRef<HTMLDivElement | null>(null);
    const [url, setUrl] = useState('');
    //const mapRef = useRef<OlMap>();

    const [map, setMap] = useState<OlMap>();
    const [feature, setFeature] = useState<OlFeature | null>(null);
    const [features, setFeatures] = useState<OlFeature[]>([]);
    const [removeFeature,setRemoveFeature] = useState<OlFeature | null>(null);
    const [searchParams] = useSearchParams();
    const {settings} = useSettings();
    const [loading, setLoading] = useState<number | null>(null);

    const [featuresPos, setFeaturesPos] = useState<number>(0);
    const [featuresCount, setFeaturesCount] = useState<number>(0);

    const [click, setClick] = useState<boolean>(true);

    let pos= 60;   

    const [defi, setDefi] = useState<boolean>(false);
    const [bank, setBank] = useState<boolean>(false);
    const [schule, setSchule] = useState<boolean>(false);
    const [schulweg, setSchulweg] = useState<boolean>(false);
    const [tswt, setTswt] = useState<boolean>(false);
    const [strassenschaden, setStrassenschaden] = useState<boolean>(false);
    const [nmob, setNMob] = useState<boolean>(false);
    const [baum, setBaum] = useState<boolean>(false);
    const [hth, setHth] = useState<boolean>(false);
    const [bplan, setBPlan] = useState<boolean>(false);
    const [alkis, setAlkis] = useState<boolean>(false);
    const [osm, setOSM] = useState<boolean>(false);
    const [boris, setBoris] = useState<boolean>(false);

    const [ dopAvailable, setDopAvailable ] = useState<Boolean>(false);
    //mapRef.current = map;
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const {
        getAccessTokenSilently,
        isAuthenticated
    } = useAuth0();     

    //setlog && console.log("Map:projection:");
    //setlog && console.log(settings.projection);

    useEffect(() => {
        setlog && console.log("Map:React.FC useEffect.[] Intitialisierung");

        const view = new OlView({
            projection: 'EPSG:25832',
            center: env.center,
            zoom: env.zoom
        });

        const attribution = new OlAttribution({
            tipLabel: 'Nutungsbedigungen',
            collapsible: false
        });

        const scaleLine = new OlScaleLine({ bar: true, text: true, minWidth: 125 });

        const mapT = new OlMap({
            target: mapElement.current!!,
            view: view,
            layers: [],
            controls: OldefaultControls({ attribution: false }).extend([
                attribution,
                scaleLine,
                new OlMousePosition({
                    coordinateFormat: OlcreateStringXY(settings.projection === 'EPSG:4326' ? 15 : 2),
                    projection: settings.projection
                })
            ]),
            interactions: defaultInteractions().extend([
                new OlDragRotateAndZoom(),
            ]),
        });

        mapT.on('pointermove', (event) => {
            const pixel = mapT.getEventPixel(event.originalEvent);
            const hit = mapT.hasFeatureAtPixel(pixel);
            (mapT.getTarget() as HTMLElement).style.cursor = hit ? 'pointer' : '';
        });

        const location = window.location.toString();
        //setlog && console.log("Map:React.FC useEffect.[]", location);

        const urlParts = location.split('?');
        if (urlParts.length < 2) {
            setUrl(location);
        }
        else{
            const queryParams = urlParts[1].split('&');
            const filteredParams = queryParams.filter( param => (param.startsWith('layers=') || param.startsWith('login=')));
            //console.log("Map:React.FC useEffect.[]", filteredParams);
            const baseUrl = urlParts[0];
            const newQueryString = filteredParams.join('%26');
            //console.log("Map:React.FC useEffect.[]", newQueryString);       
            setUrl(baseUrl + '?' + newQueryString);
            //setlog && console.log("Map:React.FC useEffect.[]", baseUrl + '?' + newQueryString);
        }      

        const layers = searchParams.get('layers');
        setlog && console.log('Map:layers = ',layers);

        var layersA: string[] = env.defaultLayers;
        var layerSet: Set<string> | null = null;

        if (layers) {
            layersA = layersA.concat(layers.split(","));
            layerSet = new Set<string>(layersA);
            layerSet.forEach(function (l) {
                switch (l) {
                    case 'alkis':
                        if (env.layers.indexOf('alkis') > -1) {
                            setAlkis(true); 
                        };
                        break;
                    case 'boris':
                        if (env.layers.indexOf('boris') > -1) { 
                            //mapT.addLayer(borisLayer);
                            setBoris(true);
                        };
                        break;            
                    case 'bplan':
                        //(env.layers.indexOf('bplan') > -1) && ( env.bplan ) && ( env.bplan.public || isAuthenticated ) && 
                        if (env.layers.indexOf('bplan') > -1) { 
                            if ( env.bplan ){
                                if ( env.bplan.public || isAuthenticated ){
                                    setLoading((l) => l ? l + 1 : 1);
                                    setBPlan(true);
                                }
                            }
                        }
                        break;
                    case 'defi':
                        //(env.layers.indexOf('defi') > -1) && ( env.defi ) && ( env.defi.public || isAuthenticated ) && 
                        if (env.layers.indexOf('defi') > -1) { 
                            if ( env.defi ){
                                if ( env.defi.public || isAuthenticated ){
                                    setLoading((l) => l ? l + 1 : 1);                        
                                    setDefi(true);
                                }
                            }
                        }                                    
                        break;
                    case 'bank':
                        //(env.layers.indexOf('bank') > -1) && ( env.bank ) && ( env.bank.public || isAuthenticated ) && 
                        if (env.layers.indexOf('bank') > -1) { 
                            if ( env.bank ){
                                if ( env.bank.public || isAuthenticated ){
                                    setLoading((l) => l ? l + 1 : 1);                        
                                    setBank(true);
                                }
                            }
                        }                                    
                        break;                        
                    case 'schule':
                        //(env.layers.indexOf('schule') > -1) && ( env.schule ) && ( env.schule.public || isAuthenticated ) && 
                        if (env.layers.indexOf('schule') > -1) { 
                            if ( env.schule ){
                                if ( env.schule.public || isAuthenticated ){
                                    setLoading((l) => l ? l + 1 : 1);                        
                                    setSchule(true);
                                }
                            }
                        }                                    
                        break;                        
                    case 'schulweg':
                        //(env.layers.indexOf('schulweg') > -1) && ( env.schulweg ) && ( env.schulweg.public || isAuthenticated ) && 
                        if (env.layers.indexOf('schulweg') > -1) { 
                            if ( env.schulweg ){
                                if ( env.schulweg.public || isAuthenticated ){
                                    setLoading((l) => l ? l + 1 : 1);                        
                                    setSchulweg(true);
                                }
                            }
                        }                                    
                    break;                                 
                    case 'tswt':
                        if (env.layers.indexOf('tswt') > -1) { 
                            if ( env.tswt ){
                                if ( env.tswt.public || isAuthenticated ){
                                    setTswt(true);
                                    setLoading((l) => l ? l + 1 : 1);
                                }
                            }
                        };
                        break; 
                    case 'strscha':
                        if (env.layers.indexOf('strassenschaden') > -1) { 
                            if ( env.strassenschaden ){
                                if ( env.strassenschaden.public || isAuthenticated ){
                                    setStrassenschaden(true);
                                    setLoading((l) => l ? l + 1 : 1);
                                }
                            }
                        };
                        break;                         
                    case 'nmob':
                        if (env.layers.indexOf('nmob') > -1) { 
                            if ( env.nmob ){
                                if ( env.nmob.public || isAuthenticated ){
                                    setLoading((l) => l ? l + 1 : 1);
                                    setNMob(true);
                                }
                            }
                        };
                        break;                         
                    case 'baum':
                        if (env.layers.indexOf('baum') > -1) { 
                            if ( env.baum ){
                                if ( env.baum.public || isAuthenticated ){
                                    setLoading((l) => l ? l + 1 : 1);
                                    setBaum(true);
                                }
                            }
                        };
                        break;                                                                       
                    case 'dop':
                        if (env.layers.indexOf('dop') > -1) { 
                            //mapT.addLayer(dopLayer);
                            setDopAvailable(true);
                        };
                        break;
                    case 'hth':
                        //(env.layers.indexOf('hth') > -1) && ( env.hth ) && ( env.hth.public || isAuthenticated ) && 
                        if (env.layers.indexOf('hth') > -1) { 
                            if ( env.hth ){
                                if ( env.hth.public || isAuthenticated ){  
                                    setLoading((l) => l ? l + 1 : 1);                      
                                    setHth(true);
                                }
                            }
                        };                                    
                                                
                        break;
                    case 'osm':
                        if (env.layers.indexOf('osm') > -1) { 
                            setOSM(true);
                            //mapT.addLayer(osmLayer);
                        };
                        break;
                    default:
                        break;
                };
            });
        }
        else {
            setOSM(true);
            //mapT.addLayer(osmLayer);
        }

        //selectLayer.set('name', '_Select');
        //mapT.addLayer(selectLayer);

        //setlog && console.log("setMap");
        setMap(mapT);         

        //setlog && console.log('Map:React.FC useEffect.[] - Ende');
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    useEffect(() => {
        setlog && console.log("Map:React.FC useEffect.[] loading ",loading);
        if ( loading === 0 ){
            const search = searchParams.get('search');
            setlog && console.log('Map:search = ',search);
            if ( search ) {
                const s = search.split(",");
                const layer = s[0] as string;
                if (env.layers.indexOf(layer) > -1) {
                    // Hier müsste noch ein wenig Sicherheit rein!                    
                    //setlog && console.log('Map:search = ',layer, index);
                    
                    map?.getAllLayers().forEach((l) => {
                        //setlog && console.log('Map:search = ',l);
                        if ( l.get('label') ){
                            if ( l.get('label') === layer ){
                                //setlog && console.log('Map:search = ',l);
                                if (l.getSource() instanceof OlSourceVector) {
                                    const targetSource:OlSourceVector = l.getSource() as OlSourceVector;
                                    setlog && console.log("SearchDrawer:React.FC source", targetSource);
                                    if ( s.length > 1 ){
                                        let featuresFound:OlFeature[] = [];
                                        targetSource.forEachFeature((feature:OlFeature) => {
                                            setlog && console.log("SearchDrawer:React.FC feature", feature);
                                            const properties = feature.getProperties();
                                            //setlog && console.log("SearchDrawer:React.FC properties", properties);                                        
                                            if ( s.includes(properties.index.toString()) ) {
                                                setlog && console.log("SearchDrawer:React.FC properties", properties);
                                                if ( feature ) {
                                                    feature?.set('label', layer);
                                                    featuresFound.push(feature);
                                                }
                                            }
                                        });
                                        findFeatures(featuresFound);
                                     }
                                }
                                else {
                                    if ( l.get('label') === 'alkis' ){
                                        let find:string = '';
                                        if ( s[1] ) {
                                            find += 'zaehler='+s[1];
                                        }
                                        if ( s[2] ) {
                                            if (find.length > 0){
                                                find += ' AND ';
                                            }
                                            find += 'nenner='+s[2];
                                        }                                            
                                        if ( s[3] ) {
                                            if (find.length > 0){
                                                find += ' AND ';
                                            }
                                            find += 'flur='+s[3];
                                        }                
                                        if ( s[4] ) {
                                            if (find.length > 0){
                                                find += ' AND ';
                                            }
                                            find += 'gemarkung='+s[4];
                                        }                     
                                                                    
                                        if (find.length > 0){
                                            setlog && console.log("SearchDrawer:React.FC properties", find);
                                            getWFSwithFilter(
                                                env.alkis.url+env.alkis.wfs,
                                                env.alkis.schema+env.alkis.filter,
                                                env.alkis.layer,
                                                find,
                                                findFeatures);
                                        }
                                    }                 
                                }
                            }
                        }
                    });                    
                }
            }                
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [loading]);    

    const updateSize = useCallback(() => {
        setTimeout(() => {
            setlog && console.log('Map:React.FC useCallback:updateSize.[map]');
            map?.updateSize();
            map?.once('change:size',updateSize);       
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[map]);

    const clickEvent = useCallback(async (evt: OlMapBrowserEvent<UIEvent>) => {
        setlog && console.log('Map:React.FC useCallback:clickEvent ', evt);
        
        let o: OlFeature[] = [];
        
        evt.map!.forEachFeatureAtPixel(evt.pixel, (f: OlFeature<OlGeometry> | OlFeatureRenderer, layer: OlLayer) => {
            setlog && console.log(f);
            if (f instanceof OlFeature<OlGeometry>) {
                if ( layer ){
                    f.set('label', layer.get('label'));
                    o.push(f);
                }
            }
            else {
                setlog && console.log(f);
            }
        },{hitTolerance:1});

        if (alkis) {
            map?.getAllLayers().forEach(async (l) => {
                setlog && console.log('Map:React.FC useCallback:clickEvent fetch ',l.get('label'));
                if ( l.get('label') ){
                    if ( l.get('label') === 'alkis' && l.get('select') ){
                        const s = l.getSource()! as OlTileWMSSource;
                        const url = s.getFeatureInfoUrl(
                            evt.coordinate,
                            evt.map.getView().getResolution()!,
                            evt.map.getView().getProjection()!,
                            {
                                'INFO_FORMAT' : 'application/json',
                                'FEATURE_COUNT' : 1, 
                                'WIDTH': 500, 
                                'HEIGHT': 500
                            }
                        );	
                        setlog && console.log('Map:React.FC useCallback:clickEvent fetch ',url);
                        if ( url ){            
                            await fetch(url).then(response => response.json())
                                .then((data) => {
                                    
                                    if ( data.numberReturned > 0 ){
                                        //const f:OlFeature =  new GeoJSON().readFeature(data.features[0]) as OlFeature; // // cast wegen 8.2.0
                                        const f:FeatureLike | OlFeatureRenderer[]  = new OlGeoJSON().readFeature(data.features[0]);
                                        if (f instanceof OlFeature) {
                                            f.set('label', 'alkis');
                                            setFeatures((pfs) => ([...pfs,f]));
                                        }
                                        else {
                                            setlog && console.log('Fehler!!!!!!!!!!!!!! hätte OLFeature sein sollen !!!!!!!!!!!!!!');
                                        }
                                    } 
                                                        
                                })
                                .catch((error) => {console.error('Fehler:', error);
                            });
                        }
                    }
                }
            });
        }
        setFeatures(o);    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alkis,click]);

    useEffect(() => {
        setlog && console.log("Map:React.FC useEffect.[features]", features.length);
        feature?.set("select",false);
        if ( features.length > 0) {
            features[0].set("select",true);
            setFeaturesCount(features.length);
            setFeaturesPos(1);
            setFeature(features[0]);
        }
        else {
            setFeature(null);
            setFeaturesCount(0);
            setFeaturesPos(0);            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [features]);

    useEffect(() => {
        setlog && console.log("Map:React.FC useEffect.[featuresPos]", featuresPos);
        if ( featuresPos > 0) {
            feature?.set("select",false);
            features[featuresPos-1].set("select",true);
            setFeature(features[featuresPos-1]);
        }
        else{
            feature?.set("select",false);
            setFeature(null);
            setFeaturesCount(0);
            setFeaturesPos(0);            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featuresPos]);

    useEffect(() => {
        setlog && console.log("Map:React.FC useEffect.[map, updateSize]");
        map?.once('change:size',updateSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map,updateSize]);

    useEffect(() => {
        setlog && console.log("Map:React.FC useEffect.[click]",click);
        if ( map ){
            if (click) {
                setlog && console.log("Map:React.FC useEffect.[click] on");
                map.on('click', clickEvent);
                
            } else {
                setlog && console.log("Map:React.FC useEffect.[click] off");
                map.un('click', clickEvent);
            }
        }

        return () => {
            if (map) {
                map.un('click', clickEvent);
            } 
        }       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map,click]);   

    const findFeatures = (featuresFound:OlFeature[]) => {
        setlog && console.log('Map:React.FC findFeatures: ',featuresFound);
        if ( featuresFound.length > 0 ){
            const feature = featuresFound[0];
            feature.set("select",true);

            setFeaturesCount(featuresFound.length);
            setFeaturesPos(1);
            setFeatures(featuresFound);  
        }  
    } 

    const newFeature = (feature:OlFeature,tabelle:string) => {
        //geht noch nicht!
        setlog && console.log('newFeature: ',feature);
        switch (tabelle) {
            case 'schule':
            case 'schulweg':
            case 'hth':
            case 'bank':
            case 'defi':
                break;
            case 'tswt':
            case 'nmob':
                //tswtLayer.getSource()?.addFeature(feature!);
                setFeature(feature);
                break;                            
            case 'bplan':
                break;
        }
    }                            

    const removeFeatureFunc = (feature:OlFeature,tabelle:string) => {
        switch (tabelle) {
            case 'schule':
            case 'schulweg':
            case 'hth':
            case 'bank':
            case 'defi':
                break;
            case 'tswt':
                setRemoveFeature(feature!);
                break;
            case 'nmob':
                setRemoveFeature(feature!);
                break;
            case 'baum':
                setRemoveFeature(feature!);
                break;                                            
            case 'bplan':
                break;
        }
    }                            

    const closeDrawer = (_unclick:Boolean=true,action:string='') => {
        setlog && console.log('closeDrawer: ' + feature?.get('label') + ' ' + action);

        if ( isAuthenticated){
            switch (action){
                case 'save':
                    savePG(feature!,getAccessTokenSilently);
                break;
                case 'delete':
                    deletePG(feature!,getAccessTokenSilently,removeFeatureFunc);
                break;                
            }
        }

        feature?.set("select",false);
        setFeature(null);
        setFeatures([]);
        setFeaturesCount(0);
        setFeaturesPos(0);              
    };

    return (
        <MapContext.Provider value={map}>
            <ConfigProvider
                theme={{
                    algorithm: theme.compactAlgorithm,    
                    components:  {
                        Drawer: {
                            colorText: '#00b96b', //Überschrift
                            colorBgElevated: '#f0f0f0eb', //Hintergrundfarbe + Transparenz
                        },
                        Carousel: {
                            dotHeight: 5,
                        },
                        Modal: {                        
                            titleColor: '#00b96b', //Überschrift
                            //titleFontSize: 20, //Titelgröße
                            headerBg: 'transparent', //Header Transparenz                            
                            colorBgElevated: '#f0f0f0d3', //Hintergrundfarbe + Transparenz                            
                        },                        
                        Input: {
                            colorText: '#ffa96a', //Textfarbe Eingabe
                            colorBgContainer: '#ffffff', //Hintergrund Eingabe
                        },
                        InputNumber: {
                            colorText: '#ffa96a', //Textfarbe Eingabe
                            colorBgContainer: '#ffffff', //Hintergrund Eingabe
                        },
                        DatePicker: {
                            colorText: '#67c1f5', //Textfarbe Eingabe
                            colorBgContainer: '#ffffff', //Hintergrund Eingabe
                        },                                                              
                        Select: {
                            colorText: '#67c1f5', //Textfarbe Eingabe
                            colorBgContainer: '#ffffff', //Hintergrund Eingabe
                        },  
                        Pagination: {
                            colorText: '#f542ce', //Textfarbe Eingabe
                            colorBgContainer: '#d4d4d4', //Hintergrund Eingabe
                        },                                      
                    },
                    token: {
                        colorPrimary: '#ffa96a', //Hintergrund Farbe Button:hoover
                    },                    
                }}
            >
                
                <Div100vh className="map" ref={mapElement}>
                
                    { osm && <OSMLayer/> }
                    { dopAvailable && <DOPLayer/> }
                    { boris && <BorisLayer/> }    

                    { alkis && (<div><AlkisLayer/><AlkisDrawer feature={feature} featuresPos={featuresPos} featuresCount={featuresCount} setFeaturesPos={setFeaturesPos} closeDrawer={closeDrawer} url={url}/></div>) }

                    { defi && (<div><DefiLayer setLoading={setLoading} /><DefiDrawer feature={feature} featuresPos={featuresPos} featuresCount={featuresCount} setFeaturesPos={setFeaturesPos} closeDrawer={closeDrawer} url={url}/></div>) }
                    { bank && (<div><BankLayer setLoading={setLoading} /><BankDrawer feature={feature} featuresPos={featuresPos} featuresCount={featuresCount} setFeaturesPos={setFeaturesPos} closeDrawer={closeDrawer} url={url}/></div>) }
                    { hth && (<div><HthLayer setLoading={setLoading} /><HthDrawer feature={feature} featuresPos={featuresPos} featuresCount={featuresCount} setFeaturesPos={setFeaturesPos} closeDrawer={closeDrawer} url={url}/></div>) }
                    { bplan && (<div><BPlanLayer setLoading={setLoading}/><BPlanDrawer feature={feature} featuresPos={featuresPos} featuresCount={featuresCount} setFeaturesPos={setFeaturesPos} closeDrawer={closeDrawer} url={url}/></div>) }
                    { schule && (<div><SchuleLayer setLoading={setLoading} /><SchuleDrawer feature={feature} featuresPos={featuresPos} featuresCount={featuresCount} setFeaturesPos={setFeaturesPos} closeDrawer={closeDrawer} url={url}/></div>) }
                    { schulweg && (<div><SchulwegLayer setLoading={setLoading} /><SchulwegDrawer feature={feature} featuresPos={featuresPos} featuresCount={featuresCount} setFeaturesPos={setFeaturesPos} closeDrawer={closeDrawer} url={url}/></div>) }

                    { tswt && (<div><TswtLayer setLoading={setLoading} removeFeature={removeFeature}/><TswtDrawer feature={feature} featuresPos={featuresPos} featuresCount={featuresCount} setFeaturesPos={setFeaturesPos} closeDrawer={closeDrawer} setClick={setClick} url={url}/></div>) }
                    { nmob && (<div><NMobLayer setLoading={setLoading} removeFeature={removeFeature}/><NMobDrawer feature={feature} featuresPos={featuresPos} featuresCount={featuresCount} setFeaturesPos={setFeaturesPos} closeDrawer={closeDrawer} setClick={setClick} url={url}/></div>) }
                    { strassenschaden && (<div><StrassenschadenLayer setLoading={setLoading} removeFeature={removeFeature}/><StrassenschadenDrawer feature={feature} featuresPos={featuresPos} featuresCount={featuresCount} setFeaturesPos={setFeaturesPos} closeDrawer={closeDrawer} setClick={setClick} url={url}/></div>) }
                    { baum && (<div><BaumLayer setLoading={setLoading} removeFeature={removeFeature}/><BaumDrawer feature={feature} featuresPos={featuresPos} featuresCount={featuresCount} setFeaturesPos={setFeaturesPos} closeDrawer={closeDrawer} setClick={setClick} url={url}/></div>) }
                                        
                </Div100vh>

                
                <Settings style={{ position: 'fixed', top: `${pos += 40}px`, left: '11px' }} />
                <Home style={{ position: 'fixed', top: `${pos += 40}px`, left: '11px' }} />
                <Print setClick={setClick} style={{ position: 'fixed', top: `${pos += 40}px`, left: '11px' }}/>
                <Geolocation style={{ position: 'fixed', top: `${pos += 40}px`, left: '11px' }}/>
                { dopAvailable && <DOP style={{ position: 'fixed', top: `${pos += 40}px`, left: '11px' }} updateMap={forceUpdate}/>}
                <Measure setClick={setClick} newFeature={newFeature} style={{ position: 'fixed', top: `${pos += 40}px`, left: '11px' }}/>
                { isAuthenticated && <Logout style={{ position: 'fixed', top: `${pos += 40}px`, left: '11px' }} /> }
                <Search style={{ position: 'fixed', top: `${pos += 40}px`, left: '11px' }} findFeatures={findFeatures} />
                <Legende style={{ position: 'fixed', top: `${pos += 40}px`, left: '11px' }} />
                
                
            </ConfigProvider>
        </MapContext.Provider>
    );
}

export default Map;