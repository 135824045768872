import React, { useEffect } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import config from "./Auth0/auth_config.json";
import App from './App';
import {SettingsProvider} from './Components/SettingsContext';


interface auth0AppProps {
    email: string;
}  


const Auth0App: React.FC<auth0AppProps> = (props) => {
    const setlog = false;    
    setlog && console.log("Auth0App:React.FC ", props.email);
/*    
    
    const [cookies, setCookie] = useCookies(['auth0']);
    import { useCookies } from 'react-cookie';
    //führt im Production Build zu einer Endlosschleife
    useEffect(() => {
        console.log ('Auth0App: React.FC useEffect[cookies,setCookie]', cookies );
        const auth0Cookie = cookies['auth0'];
  
        if (auth0Cookie && !auth0Cookie.samesite) {
          setCookie('auth0', {
            ...auth0Cookie,
            sameSite: 'none',
          }, {
            secure: true,
          });
        }
    }, [cookies, setCookie]);
//
*/

    const providerConfig = {
        domain: config.domain,
        clientId: config.clientId,
        useRefreshTokens: true,
        //useRefreshTokensFallback: false,
        legacySameSiteCookie: false, //reduziert um 2 sameSite Warnungen
        cookieDomain: '.gisgoweb.de', 
        //cookieDomain: 'localhost',
        
        authorizationParams: {            
            redirect_uri: window.location.href,
            login_hint: props.email,
            ...(config.audience ? { audience: config.audience } : null)
        }
    };

    useEffect(() => {
        setlog && console.log ('Auth0App: React.FC useEffect[props.email]]', props.email );
        providerConfig.authorizationParams.login_hint = props.email;
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [props.email]);

    return (
        <Auth0Provider {...providerConfig}>
            <SettingsProvider>
                <App />
            </SettingsProvider>
        </Auth0Provider>
    );
}

export default Auth0App;