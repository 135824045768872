import React, {useContext, useEffect, useRef, useState} from "react";
import 'antd/dist/reset.css';

import MapContext from "../Map/MapContext";
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

import Feature from 'ol/Feature';
import { buffer } from 'ol/extent';

import {
    Col,
    Form,
    Input,
    Modal,
    Row,
    Pagination, 
	Drawer 
} from 'antd';

import { env } from './Env';
import {useSettings} from './SettingsContext';

import { useAuth0 } from '@auth0/auth0-react';
  
interface schuleDrawerProps {
    url: string;
    closeDrawer: () => void;
    featuresPos: number;
    featuresCount: number;
    setFeaturesPos: React.Dispatch<React.SetStateAction<number>>;    
    feature: Feature | null;
}

const SchuleDrawer:React.FC<schuleDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("SchuleDrawer:React.FC");
    const map = useContext(MapContext);

    const {settings} = useSettings();
    const [disabled, setDisabled] = useState(true);
    const [touched, setTouched] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null); 

    const {
        isAuthenticated,
        user
    } = useAuth0();      

    useEffect(() => {
        if (props.feature) {
            if (props.feature.get('label') === 'schule') {
                setlog && console.log("SchuleDrawer:React.FC useEffect.[props.feature]", props.feature);
                const geometry = props.feature.getGeometry();
                const extent = geometry!.getExtent();
                const extentB = buffer(extent, 50);
                map?.getView().fit(extentB, {
                    duration: 2000
                });                                                        
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.feature]);    

    const closeDrawer = () =>{
        setlog && console.log("closeDrawer schuleDrawer")
        props.closeDrawer();
    };

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        setlog && console.log("Start");
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };    
    
	const changeFeature = (page: number, pageSize: number) => {
        setlog && console.log("SchuleDrawer:React.FC changeFeature", page, pageSize);
        props.setFeaturesPos(page);
    };
    
    const title = (
        <Row gutter={[8,8]} >
            <Col span={12}>
                <div style={{ fontSize: '1.2em' }}>
                    <a id="schule_mailto" href={"mailto:" + (isAuthenticated ? user?.email : ( env.schule!.mail ? 'schule-' + env.mailto : ''))  + "?Subject=Schulebrillator: " +props.feature?.get('index')+ "&body=Hier der Link zum Objekt " + props.url + "%26search=schule," +props.feature?.get('index')}>
                        {"Schule "+props.feature?.get('index')}
                    </a>
                </div>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination simple size='small' current={props.featuresPos} pageSize={1} total={props.featuresCount} onChange={changeFeature}/>
            </Col>
        </Row>
    ); 
    
    const body = (
        <Form layout="vertical">
                <Row gutter={[8,8]} >					
                <Col span={24}>
                    <Form.Item style={{ marginBottom: 4 }} label="Schule">
                        <Input name='schule' value={props.feature?.get('schule')} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>                    
    );

    if ( !settings.mobile ){
        return ( 
            <Drawer
                title={title}
                className="ggw-schule"
                placement="right"
                open={props.feature?.get('label')==='schule'}
                onClose={closeDrawer}
                mask={false}		
            >
                {body}
            </Drawer>
        );
    }
    else {
        return ( 
            <Modal
                title={ <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}                                    
                    onMouseOver={() => {disabled && setDisabled(false);}}
                    onMouseOut={() => {setDisabled(true);}}
                    onTouchStart={() => {touched ? setDisabled(true) : setTouched(false);}}
                    onTouchEnd={() => {disabled ? setTouched(true) : setDisabled(false);}}
                    onTouchCancel={() => {!disabled && setDisabled(true);}}                    
                    >
                        {title}
                    </div>
                }
                open={props.feature?.get('label')==='schule'}
                onCancel={closeDrawer}
                footer={[]}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}                   
            >
                {body}
            </Modal>
        );
    }        
};

export default SchuleDrawer;